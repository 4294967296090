import React, {useState, useEffect} from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "../Loading/Loading";
import {convertDateTime, floorNumber, numberWithCommas, typeOfStatusOrder} from "../../utils/Price";
import { toFixedDigit} from "../../utils/helperFunction";
import {useDispatch, useSelector} from "react-redux";
import {getDataOrdersHistory} from "../../redux/actions/historyLoginActions";


const HistoryOrders = ({filterString}) => {

    const historyOrders = useSelector(state => state.historyOrders)
    const metaOrders = useSelector(state => state.metaOrders)

    const dispatch = useDispatch()
    const [lastPage] = useState(metaOrders.last_page)
    const [hasMore, setHasMore] = useState(true)

    const [getFilterString, setFilterString] = useState(filterString)


    const handleNextData = (page) => {
        if (page <= lastPage) {
            dispatch(getDataOrdersHistory(`${filterString}${filterString.length>0?'&':'?'}page=${page}`))
        } else {
            setHasMore(false)
        }

    }
    const checkColor = inputString => {
        switch (inputString) {
            case 'در حال ارسال':
                return 'valueTableEntires valueSend'
            case 'در حال اجرا':
                return 'valueTableEntires valueCehck'
            case 'پذیرفته شده':
                return 'valueTableEntires valueDon'
            default:
                return 'valueTableEntires valueSend'

        }
    }

    useEffect(() => {
        setFilterString(filterString)
    }, [filterString]);

    return (
        <InfiniteScroll
            hasMore={hasMore}
            pageStart={0}
            loader={<Loading/>}
            loadMore={() => handleNextData(metaOrders.current_page + 1)}
        >
            <table dir='rtl' className='table table-borderless'>
                <thead>
                <tr>
                    <td className='titleTableEntires text-center'>ارز</td>
                    <td className='titleTableEntires text-center'>عملیات</td>
                    <td className='titleTableEntires text-center'>قیمت</td>
                    <td className='titleTableEntires text-center'>مقدار</td>
                    <td className='titleTableEntires text-center'>مجموع</td>
                    <td className='titleTableEntires text-center'>وضعیت</td>
                    <td className='titleTableEntires text-center'>زمان</td>
                </tr>
                </thead>
                <tbody>
                {historyOrders.length > 0 ?
                    historyOrders.map((item, index) => (
                        <tr>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.market.coin.label}</span></td>
                            <td className='text-center'><span
                                className={item.type === 'BUY' ? 'colorGreen valueTableEntires' : 'colorRed valueTableEntires'}>{item.type === 'BUY' ? 'خرید' : 'فروش'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{numberWithCommas(toFixedDigit(floorNumber(item.price)))}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.amount}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{numberWithCommas(toFixedDigit(floorNumber(item.total)))}</span>
                            </td>

                            <td className='text-center'><span
                                className={checkColor(typeOfStatusOrder(item.status))}>{typeOfStatusOrder(item.status)}</span>
                            </td>
                            <td className='text-center dateTimeInFAQ'><span
                                className='valueTableEntires'>{convertDateTime(item.created_at)}</span>
                            </td>
                        </tr>
                    ))
                    :
                    <p>اطلاعاتی موجود نیست</p>}

                </tbody>
            </table>
        </InfiniteScroll>
    )
}
export default HistoryOrders
