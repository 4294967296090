import {getCountOrders, getCountUsers, getOrdersVolume} from "../../services/featuresService";

export const setInfo = () => {
    return async (dispatch) => {

        let userCount = ''
        let countOrders = ''
        let orderVolume = ''

        const {data} = await getCountUsers()
        userCount = data.data.count

        const {data: getD} = await getCountOrders()
        countOrders = getD.data.count

        const {data:getVolume} = await getOrdersVolume()
        orderVolume = getVolume.data.volume

        const objFeatures = {
            userCount,
            countOrders,
            orderVolume
        }
        await dispatch({type: 'SET_FEATURES', payload: {...objFeatures}})
    }
}