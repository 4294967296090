import React from 'react';

import imgFast from './../../assets/img/features/fastFeature.svg'
import imgSafe from './../../assets/img/features/safeFeature.svg'
import imgSupport from './../../assets/img/features/supportFeature.svg'
import imgPrice from './../../assets/img/features/priceFeature.svg'

import './features.css'

const Features = () => {
    return (
        <section className='features'>
            <div className="insideFeatures">
                <div className="row w-100">
                    <div className="featureCard col-md-3 col-sm-6 col-xs-12">
                        <img src={imgSafe} alt="" />
                        <p className='titleFeatureCard'>انجام معاملات در بستری امن</p>
                    </div>
                    <div className="featureCard col-md-3 col-sm-6 col-xs-12">
                        <img src={imgPrice} alt="" />
                        <p className='titleFeatureCard'>تضمین بهترین قیمت معاملات</p>
                    </div>
                    <div className="featureCard col-md-3 col-sm-6 col-xs-12">
                        <img src={imgFast} alt="" />
                        <p className='titleFeatureCard'>واریز و انتقال سریع وجوه</p>
                    </div>
                    <div className="featureCard col-md-3 col-sm-6 col-xs-12">
                        <img src={imgSupport} alt="" />
                        <p className='titleFeatureCard'>پشتیبانی 24 ساعته</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;
