import {getMarketCoins} from "../../services/marketService";

export const initMarketCoins = () => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getMarketCoins()
            const coins = data.data;
            const arry = []
            coins.map(coin => {
                const objCoin = {
                    id: coin.id,
                    coin_id: coin.coin_id,
                    name: coin.coin.label,
                    coin: coin.coin.code,
                    img: coin.coin.logo,
                    // buyingPrice:coinRead.buyingPrice,
                    // sellingPrice:coin.sellingPrice
                    buyingPrice: (coin.buyingPrice ? coin.buyingPrice : 0),
                    sellingPrice: (coin.sellingPrice ? coin.sellingPrice : 0)
                }
                arry.push(objCoin)
            })
            await dispatch({type: 'INIT_MARKET_COINS', payload: [...arry]})
        } catch (ex) {
            console.log(ex)
        }
    }
}

export const setCoin = id => {
    return async (dispatch, getState) => {
        if(getState().marketCoins.length === 0){
            await dispatch(initMarketCoins())
        }
        const coins = [...getState().marketCoins]
        const coin = coins.find(co => co.id === id)
        await dispatch({type: 'SET_COIN', payload: coin})
    }
}
export const setCoinWallet = id => {
    return async (dispatch, getState) => {
        const coins = [...getState().marketCoins]
        const coin = coins.find(co => co.coin_id === id)
        await dispatch({type: 'SET_COIN', payload: coin})
    }
}