import React, { useEffect} from 'react';
import {Switch, Route, NavLink, useRouteMatch, withRouter} from 'react-router-dom'
import Dashbord from './../Dashbord/Dashbord';
import History from './../History/History';
import Notification from './../Notification/Notification';
import axios from 'axios';
// svg inActive
import dashbord from './../../assets/img/sideBar/dashboard.svg'
import dashbordActive from './../../assets/img/sideBar/dashboardActive.svg'
import userInfo from './../../assets/img/sideBar/userInfo.svg'
import userInfoActive from './../../assets/img/sideBar/userInfoActive.svg'
import historyy from './../../assets/img/sideBar/history.svg'
import historyActive from './../../assets/img/sideBar/historyActive.svg'
import invite from './../../assets/img/sideBar/invite.svg'
import inviteActive from './../../assets/img/sideBar/inviteActive.svg'
import notification from './../../assets/img/sideBar/notification.svg'
import notificationActive from './../../assets/img/sideBar/notificationActive.svg'
import logoutImage from './../../assets/img/logout.svg'
import walletActive from './../../assets/img/sideBar/walletActive.svg'
import walletInactive from './../../assets/img/sideBar/walletInactive.svg'

// End svg inActive

import './account.scss'
import Profile from './../Profile/Profile';
import Invite from './../Invite/Invite';
import {useDispatch, useSelector} from 'react-redux';
import {userLogout} from '../../redux/actions/loginActions';

import {logoutUser} from './../../services/userService';
import {toast} from 'react-toastify';
import {setUserInfo} from './../../redux/actions/userActions';
import {getInviteDataRedux} from './../../redux/actions/inviteDataActions';
import {getAccounts, getActiveDebit} from "../../redux/actions/bankAccountsAction";
import {setOrders} from "../../redux/actions/ordersAction";
import {setEntired} from "../../redux/actions/entriesActions";
import ViewWallets from "../ViewWallets/ViewWallets";
import {setWallet} from "../../redux/actions/walletAction";
import {useLocation} from "react-router";
import {initMarketCoins} from "../../redux/actions/marketCoinsActions";
import {getPersonalInfoLogin} from "../../services/personService";


const Account = ({history}) => {
    const location = useLocation()
    const {pathname} = location
    const {url, path} = useRouteMatch()
    const dispatch = useDispatch()
    const user = useSelector(state=>state.user)

    const checkAddressIs = addressName => {
        if(pathname.indexOf(addressName) > -1) {
            return true
        }else{
            return false
        }
    }

    const handleLogout = async () => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await logoutUser()
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                dispatch(userLogout())
                localStorage.removeItem('token')
                history.replace('/')
            }

        } catch (ex) {
            console.log(ex.message)
        }

    }



    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        dispatch(setUserInfo())
        dispatch(getInviteDataRedux())
        dispatch(setOrders())
        dispatch(getAccounts())
        dispatch(setEntired())
        dispatch(setWallet())
        dispatch(initMarketCoins())
        dispatch(getActiveDebit())
    }, [])

    return (
        <section className='account'>
            <div className='insideAccount'>

                <div className='leftSide'>
                    <Switch>
                        <Route exact path={path} component={Dashbord}/>
                        <Route path={`${path}/history`} component={History}/>
                        <Route path={`${path}/notification`} component={Notification}/>
                        <Route path={`${path}/profile`} component={Profile}/>
                        <Route path={`${path}/invite`} component={Invite}/>
                        <Route path={`${path}/view-wallets`} component={ViewWallets}/>
                    </Switch>
                </div>
                <div className='rightSide'>
                    <div className='sideMenuAccount'>

                        <NavLink exact to={`${url}`} activeClassName='menuItemSideActive' className='menuItemSide'>
                            <p className='hideNavLink' >داشبورد</p>
                            <img alt='' src={pathname=== '/account' ? dashbordActive : dashbord}  className='imgSideBar'/>
                        </NavLink>

                        <NavLink to={`${url}/profile`} activeClassName='menuItemSideActive' className='menuItemSide'>
                            <p className='hideNavLink'>حساب کاربری</p>
                            <img alt='' src={checkAddressIs('profile') ? userInfoActive : userInfo} className='imgSideBar'/>
                        </NavLink>

                        <NavLink to={`${url}/history`} activeClassName='menuItemSideActive' className='menuItemSide'>
                            <p className='hideNavLink'>تاریخچه</p>
                            <img alt='' src={checkAddressIs('history') ? historyActive : historyy} className='imgSideBar'/>
                        </NavLink>

                        <NavLink to={`${url}/invite`} activeClassName='menuItemSideActive' className='menuItemSide' >
                            <p className='hideNavLink'>دعوت دوستان</p>
                           <img alt='' src={checkAddressIs('invite') ? inviteActive : invite} className='imgSideBar'/>
                        </NavLink>

                        <NavLink to={`${url}/notification`} activeClassName='menuItemSideActive' className='menuItemSide'>
                            <p className='hideNavLink'>اعلانات</p>
                           <img alt='' src={checkAddressIs('notification') ? notificationActive : notification} className='imgSideBar'/>
                        </NavLink>

                        <NavLink to={`${url}/view-wallets`} activeClassName='menuItemSideActive' className='menuItemSide' >
                            <p className='hideNavLink'>کیف پول</p>
                            <img alt='' src={checkAddressIs('view-wallets') ? walletInactive : walletActive} className='imgSideBar'/>
                        </NavLink>

                        <div  className='menuItemSide' onClick={() => handleLogout()}>
                            <p className='hideNavLink'>خروج</p>
                            <img src={logoutImage} className='imgSideBar' alt=""/>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default withRouter(Account);