import moment from "jalali-moment";
import newMoment from 'moment-jalaali'
// moment.locale("fa", fa);
// moment.locale('fa', { useGregorianParser: true });
export function numberWithCommas(x) {
    let str2 = x ? x : 0
    return str2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithDash(x) {
    let str2 = x ? x : 0
    return str2.toString().replace(/\B(?=(\d{4})+(?!\d))/g, "-");
}

export function convertDateTime(dateAndTime) {
   // const result = newMoment(dateAndTime, 'YYYY-M-D HH:mm:ss').locale('fa').format('jYYYY/jM/jD HH:mm:ss') // 1392/6/31 23:59:59
   // const result = moment(dateAndTime, 'YYYY-M-D HH:mm:ss').locale('fa').format('jYYYY/jM/jD HH:mm:ss') // 1392/6/31 23:59:59

    const arr = dateAndTime.split('T')
    const date = moment(arr[0], 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')
    const time = arr[1].slice(0, 5)
    // return result
    return `${date} - ${time}`
}

export function typeOfStatusOrder(name) {
    switch (name) {
        case 'ACCEPTED':
            return 'پذیرفته شده'
        case 'PENDING':
            return 'در حال اجرا'
        case 'REJECTED':
            return 'رد شده'
        case 'REJECTED_BY_USER':
            return 'رد شده'
        case 'EXPIRED':
            return 'منقضی شده'
        case 'DEACTIVATED':
            return 'غیر فعال'
        default:
            return name
    }
}

export function findByModeOrder(index) {
    switch (index) {
        case 0:
            return ''
        case 1:
            return '?type=BUY'
        case 2:
            return '?type=SELL'
    }
}


export function typeOfLoggin(type) {
    switch (type) {
        case 'SMS':
            return 'پیام کوتاه'
        case 'GOOGLE':
            return 'شناسایی دو عاملی گوگل'
        case 'EMAIL':
            return 'ایمیل'
    }
}

export function floorNumber(num) {
    let price = Number.parseFloat(num);
    if (price >= 1) {
        return price.toFixed(2);
    }
    return parseFloat(price.toPrecision(4));
}

export const reverseArray = array => {
    let arrayResult = array.reverse()
    arrayResult.length = 20
    arrayResult.reverse()
    return arrayResult
}

export const getMethod = method => {
    switch (method) {
        case'EMAIL':
            return 'ایمیل'
        case 'SMS':
            return 'پیامک'
        case 'GOOGLE':
            return 'گوکل'
        default:
            return 'نمی دونم'
    }
}