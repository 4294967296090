import { getNetworksCoin } from "../../services/networkService";

export const initNetworks = (coin_id) => {
    return async(dispatch, getState) => {
        try {
            const { data } = await getNetworksCoin(coin_id)
            await dispatch({ type: 'INIT_ALL_NETWORKS', payload: [...data.data] })
            await dispatch(getNetworks())
        } catch (ex) {
            console.log(ex)
        }

    }
}

export const getNetworks = () => {
    return async(dispatch, getState) => {
        const nets = [...getState().networkCoin]
        let listNet = []
        let id = 0
        nets.map(item => {
            const obj = {
                id,
                value: item.id,
                title: item.name,
                fee:item.withdraw_fee,
                max:item.withdraw_max,
                min:item.withdraw_min
            }
            listNet.push(obj)
            id++
        })
        await dispatch({ type: 'GET_NETWORKS', payload: [...listNet] })
    }
}