import React from 'react';
import './buttomGoogle.css'

import googleLogo from './../../assets/img/googleLogo.svg'

const ButtomGoogle = ({title, handleClick}) => {
    return (
        <div className='containerBtnGoogle'>
            <button onClick={handleClick} className='btnGoogle'>{title}</button>
            <img alt='' src={googleLogo} className='googleLogo'/>
        </div>
    );
}

export default ButtomGoogle;