import {getOrdersFromDB} from "../../services/ordersService";

export const setOrders = () => {
    return async (dispatch) => {
        const {data} = await getOrdersFromDB()
        await dispatch({type: 'INIT_ORDERS', payload: [...data.data]})

    }
}

export const setOrdersTop10 = () => {
    return async (dispatch) => {
        const {data} = await getOrdersFromDB('?size=7')
        await dispatch({type: 'INIT_ORDERS', payload: [...data.data]})

    }
}