import React, { useEffect, useState } from 'react';

import axios from "axios";
import DatePicker from 'react-datepicker2'
import moment from 'moment-jalaali'
import ProfileItem from '../../components/ProfileItem/ProfileItem';
import InsideAuthen from '../../components/InsideAuthen/InsideAuthen';
import InsidePersonalInforation from './../../components/InsidePersonalInforation/InsidePersonalInforation';
import InsideBankInformation from './../../components/InsideBankInformation/InsideBankInformation';
import { Formik } from 'formik'
import * as Yup from 'yup'
import './profile.scss'
import './InsidePersonalInforation.scss'
import { getPersonalInfoLogin, setPersonalInfo } from './../../services/personService';
import { useDispatch, useSelector } from 'react-redux';
import { sendCodeFinoTech } from './../../services/finoTechService';
import { toast } from 'react-toastify';
import { setUserInfo } from './../../redux/actions/userActions';
import FinoTechDialog from './../../dialogs/FinoTech/FinoTechDialog';
import Loading from './../../components/Loading/Loading';
import InputItemPersonalInfo from './../../components/InputItemPersonalInfo/InputItemPersonalInfo';
import CustomInput from './../../components/CustomInput/CustomInput';
import ErrorMessage from './../../components/ErrorMessage/ErrorMessage';
import CustomTextArea from './../../components/CustomTextArea/CustomTextArea';
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import { Spinner } from 'reactstrap';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('پرکردن این فیلد الزامی است.'),
    lastname: Yup.string().required('پرکردن این فیلد الزامی است.'),
    national_code: Yup.string().required('پرکردن این فیلد الزامی است.').length(10, 'کد ملی باید 10 کاراکتر باشد'),
    birthday: Yup.string().required('پرکردن این فیلد الزامی است.').min(1),
    address: Yup.string().required('پرکردن این فیلد الزامی است.'),
    mobile: Yup.string().required('پرکردن این فیلد الزامی است.').length(11, ''),
    phone: Yup.string().required('پرکردن این فیلد الزامی است.')
})
Yup.addMethod(Yup.string, 'national_code', function () {
    return this.test('validate-national_code', '${path} معتبر نمی باشد', (national_code) => {
        var L = national_code.length;

        if (L < 8 || parseInt(national_code, 10) === 0) return false;
        national_code = ('0000' + national_code).substr(L + 4 - 10);
        if (parseInt(national_code.substr(3, 6), 10) === 0) return false;
        var c = parseInt(national_code.substr(9, 1), 10);
        var s = 0;
        for (var i = 0; i < 9; i++)
            s += parseInt(national_code.substr(i, 1), 10) * (10 - i);
        s = s % 11;
        return (s < 2 && c === s) || (s >= 2 && c === (11 - s));
    });
})

const Profile = () => {



    const user = useSelector(state => state.user)
    const { profile } = user
    const [isDisable, setIsDisable] = useState(false)
    const [finoTechDialog, setFinoTechDialog] = useState(false)

    const dispatch = useDispatch()
    const getUserData = async () => {
        setIsDisable(true)
        const { data } = await getPersonalInfoLogin()
        if (data.type === 'success') {
            dispatch({ type: 'SET_USER', payload: data.data })
            setIsDisable(false)
        }
    }

    const openFinoTech = async () => {
        try {
            console.log('openFIni is run')
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const { data } = await sendCodeFinoTech()
            console.log(data)
            if (data.type === 'success') {
                console.log("success fino")
                setIsDisable(false)
                setFinoTechDialog(true)
            }
        } catch (ex) {
            console.log(ex)
            setIsDisable(false)
            toast.error(`${ex.data.message}`, { position: 'bottom-right', closeOnClick: true })
        }
    }

    const closeFinoTech = () => setFinoTechDialog(false)

    const handleEditPersonalInformation = async (values) => {
        console.log(values)
        setIsDisable(true)
        try {
            setIsDisable(true)
            const { data } = await setPersonalInfo(values)
            if (data.type === 'success') {
                toast.success(`${data.message}`, { position: 'top-right', closeOnClick: true })
                dispatch(setUserInfo())
                if (user.status === 'SECONDARY_AUTH_DONE' || user.status === 'RESEND_OTP') {
                    console.log("go for fino tech")
                    openFinoTech()
                } else {
                    setIsDisable(false)
                }
            }
        } catch (ex) {
            if (ex.data.errors.accounts) {
                toast.error(`${ex.data.errors.accounts[0]}`, { position: 'bottom-right', closeOnClick: true })
            }
            if (ex.data.errors.documents) {
                toast.error(`${ex.data.errors.documents[0]}`, { position: 'bottom-right', closeOnClick: true })
            } else {
                toast.error(`${ex.data.message}`, { position: 'bottom-right', closeOnClick: true })
            }
            setIsDisable(false)
            console.log(ex);
        }


    }

    const userPrimary = {
        name: '',
        lastname: '',
        national_code: '',
        birthday: '',
        address: '',
        mobile: '',
        phone: ''

    }

    useEffect(() => {
        document.title = 'پنل کاربری || حساب کاربری'
        getUserData()
    }, [])

    return (
        <>
            <Formik
                // initialValues={user.status === 'PRIMARY_AUTH_DONE' || user.status === 'REJECTED' ? userPrimary : {
                initialValues={profile === null ? userPrimary : {
                    name: (profile ? profile.name : ''),
                    lastname: (profile ? profile.lastname : ''),
                    national_code: (profile ? profile.national_code : ''),
                    // birthday: moment((profile ? profile.birthday : ''), 'YYYY/MM/DD'),
                    birthday: (profile ? profile.birthday : ''),
                    address: (profile ? profile.address : ''),
                    mobile: (profile ? profile.mobile : ''),
                    phone: (profile ? profile.phone : '')
                }}
                enableReinitialize
                onSubmit={values => {
                    // handleEditPersonalInformation({...values, birthday: values.birthday.format('YYYY/MM/DD')})
                    handleEditPersonalInformation(values)
                }}
                validationSchema={validationSchema}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, errors, setFieldTouched, touched }) => (
                    <div className='pageProfile'>
                        <ProfileItem title='اطلاعات شخصی'>

                            {isDisable ? <Loading fullScrean={true} /> :
                                // <Formik
                                //     // initialValues={user.status === 'PRIMARY_AUTH_DONE' || user.status === 'REJECTED' ? userPrimary : {
                                //     initialValues={profile === null ? userPrimary : {
                                //         name: (profile ? profile.name : ''),
                                //         lastname: (profile ? profile.lastname : ''),
                                //         national_code: (profile ? profile.national_code : ''),
                                //         // birthday: moment((profile ? profile.birthday : ''), 'YYYY/MM/DD'),
                                //         birthday: (profile ? profile.birthday : ''),
                                //         address: (profile ? profile.address : ''),
                                //         mobile: (profile ? profile.mobile : ''),
                                //         phone: (profile ? profile.phone : '')
                                //     }}
                                //     enableReinitialize
                                //     onSubmit={values => {
                                //         // handleEditPersonalInformation({...values, birthday: values.birthday.format('YYYY/MM/DD')})
                                //         handleEditPersonalInformation(values)
                                //     }}
                                //     validationSchema={validationSchema}
                                // >
                                //     {({ handleChange, handleSubmit, setFieldValue, values, errors, setFieldTouched, touched }) => (
                                <>
                                    <div className='containerInputsPersonalInfo'>


                                        <InputItemPersonalInfo>
                                            <CustomInput
                                                disabled={user.status === 'ACCEPTED' || user.status === 'OTP_DONE' || user.status === 'CONFIRMED_IDENTITY' || user.status === 'SECONDARY_AUTH_DONE' || user.status === 'RESEND_OTP'}
                                                placeholder='نام'
                                                value={values.name} handleChange={handleChange('name')} marginBottom='18px'
                                                handleBlur={() => setFieldTouched('name')} />
                                            <ErrorMessage error={errors.name} visible={touched.name} />
                                        </InputItemPersonalInfo>

                                        <InputItemPersonalInfo>
                                            <CustomInput
                                                disabled={user.status === 'ACCEPTED' || user.status === 'OTP_DONE' || user.status === 'CONFIRMED_IDENTITY' || user.status === 'SECONDARY_AUTH_DONE' || user.status === 'RESEND_OTP'}
                                                placeholder='نام خانوادگی'
                                                value={values.lastname} handleChange={handleChange('lastname')}
                                                marginBottom='18px'
                                                handleBlur={() => setFieldTouched('lastname')} />
                                            <ErrorMessage error={errors.lastname} visible={touched.lastname} />
                                        </InputItemPersonalInfo>

                                        {/*{user.status === 'PRIMARY_AUTH_DONE' || user.status === 'REJECTED' ?*/}
                                        {profile === null ?
                                            <InputItemPersonalInfo>
                                                <div className='containerDataPicker'>
                                                    <DatePicker
                                                        className='datattt'
                                                        placeholder='تاریخ تولد'
                                                        isGregorian={false}
                                                        timePicker={false}
                                                        showTodayButton={false}
                                                        disabled={user.status === 'ACCEPTED' || user.status === 'OTP_DONE' || user.status === 'CONFIRMED_IDENTITY' || user.status === 'SECONDARY_AUTH_DONE' || user.status === 'RESEND_OTP'}
                                                        onChange={value => setFieldValue('birthday', value)}
                                                        onBlur={() => setFieldTouched('birthday')}
                                                    />
                                                </div>
                                                <ErrorMessage error={errors.birthday} visible={touched.birthday} />
                                            </InputItemPersonalInfo> :
                                            <InputItemPersonalInfo>
                                                <div className='containerDataPicker'>
                                                    <DatePicker
                                                        className='datattt'
                                                        placeholder='تاریخ تولد'
                                                        isGregorian={false}
                                                        timePicker={false}
                                                        showTodayButton={false}
                                                        value={moment(profile ? profile.birthday : '')}
                                                        disabled={(user ? (user.status === 'ACCEPTED') : false) || (user ? user.status === 'OTP_DONE' : false) || (user ? user.status === 'CONFIRMED_IDENTITY' : false) || (user ? user.status === 'SECONDARY_AUTH_DONE' : false) || (user ? user.status === 'RESEND_OTP' : false)}
                                                        // disabled={user.status === 'ACCEPTED' || user.status === 'OTP_DONE' || user.status === 'CONFIRMED_IDENTITY' || user.status === 'SECONDARY_AUTH_DONE'||user.status ==='RESEND_OTP'}
                                                        onChange={value => setFieldValue('birthday', value)}
                                                        onBlur={() => setFieldTouched('birthday')}
                                                    />
                                                </div>
                                                <ErrorMessage error={errors.birthday} visible={touched.birthday} />
                                            </InputItemPersonalInfo>
                                        }
                                        <InputItemPersonalInfo>
                                            <CustomInput placeholder='کد ملی' textAlignLeft={true}
                                                disabled={user.status === 'ACCEPTED' || user.status === 'OTP_DONE' || user.status === 'CONFIRMED_IDENTITY' || user.status === 'SECONDARY_AUTH_DONE' || user.status === 'RESEND_OTP'}
                                                value={values.national_code}
                                                handleChange={handleChange('national_code')}
                                                marginBottom='18px'
                                                handleBlur={() => setFieldTouched('national_code')} />
                                            <ErrorMessage error={errors.national_code} visible={touched.national_code} />
                                        </InputItemPersonalInfo>

                                        <InputItemPersonalInfo>
                                            <CustomInput placeholder='موبایل' textAlignLeft={true}
                                                value={values.mobile} handleChange={handleChange('mobile')}
                                                marginBottom='18px'
                                                handleBlur={() => setFieldTouched('mobile')} />
                                            <ErrorMessage error={errors.mobile} visible={touched.mobile} />

                                        </InputItemPersonalInfo>

                                        <InputItemPersonalInfo>
                                            <CustomInput placeholder='شماره ثابت' textAlignLeft={true}
                                                value={values.phone} handleChange={handleChange('phone')}
                                                marginBottom='18px'
                                                handleBlur={() => setFieldTouched('phone')} />
                                            <ErrorMessage error={errors.phone} visible={touched.phone} />

                                        </InputItemPersonalInfo>

                                        <CustomTextArea placeholder='آدرس'
                                            value={values.address} handleChange={handleChange('address')}
                                            marginBottom='18px'
                                            handleBlur={() => setFieldTouched('address')} />
                                        <ErrorMessage error={errors.address} visible={touched.address} />

                                    </div>
                                    {/* <div className="row d-flex justify-content-center align-items-center">
                                        <div className='btnSubmitEditProfile'>
                                            {user.status !== 'PRIMARY_AUTH_DONE' ?
                                                <ButtonForm funcClick={handleSubmit} marginBottom={16}>
                                                    {isDisable === false ? 'ثبت' :
                                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                            variant="secondary" />
                                                    }
                                                </ButtonForm> : null}
                                            {user.status === 'PRIMARY_AUTH_DONE' ?
                                                <ButtonForm funcClick={handleSubmit} marginBottom={16}>
                                                    {isDisable === false ? 'ثبت پروفایل' :
                                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                            variant="secondary" />
                                                    }
                                                </ButtonForm> : null}
                                        </div>
                                    </div> */}
                                    <FinoTechDialog closeDialog={closeFinoTech} showDialog={finoTechDialog}
                                        mobile={profile ? profile.mobile : 0}
                                        national_code={profile ? profile.national_code : 0} />
                                </>
                                //     )}
                                // </Formik>
                            }

                            {/* <InsidePersonalInforation /> */}
                        </ProfileItem>

                        <ProfileItem title='اطلاعات بانکی'>
                            <InsideBankInformation />
                        </ProfileItem>

                        <ProfileItem title='احراز هویت'>
                            <InsideAuthen />
                        </ProfileItem>
                        <div className="row d-flex justify-content-center align-items-center w-100">
                            <div className='btnSubmitEditProfile'>
                                {user.status !== 'PRIMARY_AUTH_DONE' ?
                                    <ButtonForm funcClick={handleSubmit} marginBottom={16}>
                                        {isDisable === false ? 'ثبت' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                variant="secondary" />
                                        }
                                    </ButtonForm> : null}
                                {user.status === 'PRIMARY_AUTH_DONE' ?
                                    <ButtonForm funcClick={handleSubmit} marginBottom={16}>
                                        {isDisable === false ? 'ثبت پروفایل' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                variant="secondary" />
                                        }
                                    </ButtonForm> : null}
                            </div>
                        </div>
                    </div >
                )}
            </Formik>
        </>
    );
}

export default Profile;