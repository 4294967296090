export const entriesReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_ENTIRE':
            return  [...action.payload]
        default: return state
    }

}
//
// export const entriesReducer = (state = [
//     { id: 0, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: true },
//     { id: 1, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: false },
//     { id: 2, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: true },
//     { id: 3, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: true },
//     { id: 4, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: false },
//     { id: 5, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: true },
//     { id: 6, timeDate: '۰/۰۳/۱۹-۱۱:۳۴', ip: '89.46.103.250', deviceType: 'دسکتاپ', browser: 'Chrome 91.0.4472.77', status: true }
// ], action) => {
//     switch (action.type) {
//         default: return state
//     }
//
// }