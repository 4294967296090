export const persianNameDevices = device => {
    switch (device) {
        case 'DESKTOP':
            return 'دسکتاپ'
        case 'PHONE':
            return 'موبایل'
        case 'TABLET':
            return 'تبلت'
        case 'ROBOT':
            return 'روبات'
        default:
            return device
    }
}
export const toFixedDigit = number => {
    return Number.parseFloat(number).toFixed(0)
}
export const getAccountStatus = status => {
    switch (status) {
        case 'REJECTED':
            return 'رد شده'
        case 'PENDING':
            return 'در حال بررسی'
        case 'ACCEPTED':
            return 'تایید شده'
        default:
            return 'رد شده'
    }
}

export const getStatusTransactionCrypto = state => {
    switch (state) {
        case 'PENDING':
            return 'در حال بررسی'
        case 'DEACTIVATED':
            return 'غیر فعال'
        case 'ACCEPTED':
            return 'پذیرفته شده'
        case 'REJECTED':
            return 'رد شده'
        case 'ACTIVATED':
            return
        case 'CONFLICT':
            return 'تناقض'
        case 'SENT':
            return 'ارسال شد'
        case 'EXPIRED':
            return 'منقضی شده'
        case 'FAILED':
            return 'رد شده'
        default:
            return state
    }
}