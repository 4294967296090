import React, {useState,useEffect} from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "../Loading/Loading";
import {convertDateTime} from "../../utils/Price";
import {getStatusTransactionCrypto, persianNameDevices} from "../../utils/helperFunction";
import {useDispatch, useSelector} from "react-redux";
import {getDataLoginHistory, getDataTrnsactionCryptoHistory} from "../../redux/actions/historyLoginActions";
import {getStateTransaction, getTransactionsCrypto} from "../../services/transactionService";
import {toast} from "react-toastify";
import axios from "axios";

const HistoryTransactionCrypto = ({filterString}) => {

    const historyTransactionCrypto = useSelector(state => state.historyTransactionCrypto)
    const metaTransactionCrypto = useSelector(state => state.metaTransactionCrypto)

    const dispatch = useDispatch()
    const [lastPage] = useState(metaTransactionCrypto.last_page)
    const [hasMore, setHasMore] = useState(true)
    const [isDisable, setIsDisable] = useState(false)
    const[getFilterString,setFilterString]=useState(filterString)


    const handleNextData = (page) => {

        if (page <= lastPage) {
            dispatch(getDataTrnsactionCryptoHistory(`${filterString}&page=${page}`))
        }else{
            setHasMore(false)
        }

    }
    const transactionState = async id => {
        try {
            setIsDisable(true)
            const {data} = await getStateTransaction(id)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                // getDataTransactionCrypto()
                setIsDisable(false)
            } else {
                toast.error(`${data.message}`, {position: 'bottom-right', closeOnClick: true})
                setIsDisable(false)
            }
        } catch (ex) {
            console.log(ex)
            setIsDisable(false)

            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
        }
    }

    useEffect(() => {
        setFilterString(filterString)
    }, [filterString]);

    return (
        <InfiniteScroll
            hasMore={hasMore}
            pageStart={0}
            loader={<Loading/>}
            loadMore={() => handleNextData(metaTransactionCrypto.current_page + 1)}
        >

            {isDisable?<Loading/>:<table dir='rtl' className='table table-borderless'>
                <thead>
                <tr>
                    <td className='titleTableEntires text-center'>ارز</td>
                    <td className='titleTableEntires text-center'>عملیات</td>
                    <td className='titleTableEntires text-center'>مقدار</td>
                    <td className='titleTableEntires text-center'>وضعیت</td>
                    <td className='titleTableEntires text-center'>شماره پیگیری</td>
                    <td className='titleTableEntires text-center'>بررسی</td>
                </tr>
                </thead>
                <tbody>
                {historyTransactionCrypto.length > 0 ?
                    historyTransactionCrypto.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.coin.label}</span>
                            </td>
                            <td className='text-center'><span
                                className={item.type === 'DEPOSIT' ? 'colorGreen valueTableEntires' : 'colorRed valueTableEntires'}>{item.type === 'DEPOSIT' ? 'واریز' : 'برداشت'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.amount}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{getStatusTransactionCrypto(item.status)}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.payment.tx_id ? item.payment.tx_id : '_'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.status === 'PENDING' && item.type === "DEPOSIT" ?
                                <button onClick={() => transactionState(item.id)}
                                        className='btn btn-sm btn-success'>بررسی
                                    واریز</button> : '_'}</span>
                            </td>

                        </tr>
                    ))
                    :
                    <p>اطلاعاتی موجود نیست</p>}

                </tbody>
            </table>}
        </InfiniteScroll>
    )
}
export default HistoryTransactionCrypto
