import React,{useEffect , useRef} from 'react';
import './buttonForm.css'
import {useSelector} from "react-redux";

const ButtonForm = ({ title, funcClick, marginBottom, isDis, type = 'button', children, focus = false }) => {
    const btnRef = useRef();
    const sixDigit = useSelector(state=>state.sixDigit)

    useEffect(()=>{
        if(sixDigit.length > 5){
            // btnRef.current.focus()
        }
    },[sixDigit])
    return (
        <button ref={btnRef} type={type} disabled={isDis} onClick={funcClick} style={{ marginBottom }} className='buttonForm'>{children}</button>
    );
}

export default ButtonForm;