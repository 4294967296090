import React, {useState, useEffect} from 'react';
import {DialogOverlay, DialogContent} from '@reach/dialog';

import './changePasswordDialog.scss'
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import {Formik} from "formik";
import * as Yup from 'yup'
import InputDigit6 from './../../components/InputDigit6/InputDigit6';
import ChangePasswordDi from "../../components/ChangePasswordDialog/ChangePasswordDi";
import {setOptCode, settingOtp} from "../../services/settingService";
import {toast} from "react-toastify";
import {getGoogle2af} from "../../services/google2afService";
import {setDigit} from "../../redux/actions/digitActions";
import {useDispatch, useSelector} from "react-redux";
import {loginConfirm} from "../../services/userService";
import axios from "axios";
import {changeRegisterMode} from "../../redux/actions/registerModeAction";
import {userLogin} from "../../redux/actions/loginActions";
import {getInviteDataRedux} from "../../redux/actions/inviteDataActions";
import {setUserInfo} from "../../redux/actions/userActions";
import {Spinner} from "reactstrap";
import InputForms from "../../components/InputForms/InputForms";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import CustomInput from "../../components/CustomInput/CustomInput";


const validationSchema = Yup.object().shape({
    code: Yup.string().required('پر کردن این فیلد الزامی می باشد').length(6, 'باید شیش کاراکتر باشد.')
})

const ChangePasswordDialog = ({showDialog, closeDialog, handleGoogle, methode}) => {

    const user = useSelector(state => state.user)
    const [twoOperation, setTwoOperation] = useState(false)
    const [getSMS, setSMS] = useState(false)
    const [getSettingValue, setSettingValue] = useState('')
    const [showInput, setshowInput] = useState(false)
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const [isLoadingEmail, setIsLoadingEmail] = useState(false)
    const [isLoadingSMS, setIsLoadingSMS] = useState(false)
    const [getSecond, setSecond] = useState(59)
    const [getMinout, setMinout] = useState(1)
    const [setting, setSetting] = useState(methode)
    // user.settings[0].setting_value
    const stopTime = () => {
        let sec = 59;
        let min = 1;
        const timer = setInterval(() => {
            sec--;
            setSecond(sec)
            if (sec < 1) {
                sec = 59
                min = min - 1
                if (min < 0) {
                    setshowInput(false)
                    clearInterval(timer)
                } else {
                    setMinout(min)
                }
            }

        }, 1000)
    }

    const handleSettingOtp = async (val) => {
        setSettingValue(val)
        setSetting(val)
        if (val === 'SMS') {
            setSMS(true)
        } else {
            setSMS(false)
        }
        const obj = {
            setting_key: val
        }
        try {
            stopTime()
            if (val !== 'SMS') {
                setIsLoadingEmail(true)
            } else {
                setIsLoadingSMS(true)
            }
            const {data} = await settingOtp(obj)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: 'true'})
                setshowInput(true)
                setIsLoadingEmail(false)
                setIsLoadingSMS(false)
                dispatch(setUserInfo())


            }
        } catch (ex) {
            console.log(ex)
            setIsLoadingEmail(false)
            setIsLoadingSMS(false)
            setshowInput(false)
        }

    }


    const handleCodeConfirm = async (values) => {
        console.log(values)
        setLoading(true)
        try {
            const {data} = await setOptCode(values)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setLoading(false)
                setshowInput(false)
                dispatch(setUserInfo())
                // clearInterval(timer)
                closeDialog()
            }
        } catch (ex) {
            // console.log(ex)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            setLoading(false)
        }
    }

    const getUserData = async () => {
        try {
            await dispatch(setUserInfo())
        } catch (ex) {
            console.log(ex)
        }
    }

    useEffect(() => {
        setSetting(methode)
        setSetting(user.settings ? user.settings[0].setting_value : '')
        getUserData()
    }, [])
    useEffect(() => {
        setshowInput(false)
        return () => {
            setshowInput(false)
            setSetting(user.settings ? user.settings[0].setting_value : '')
        }
    }, [showDialog])

    return (
        <DialogOverlay isOpen={showDialog} onDismiss={closeDialog} style={{background: "hsla(0,100%,100%,0.9)"}}>
            <DialogContent className='containerNewQuestion bgchangeDialog '>
                <div className='insideChangeDialog'>
                    <div className='titleSectionChangePassword'>
                        <p onClick={() => setTwoOperation(true)}
                           className={twoOperation ? 'text-centerrrrr activeTab' : 'text-centerrrrr deactiveTab'}>شناسایی
                            دو عاملی</p>
                        <p onClick={() => setTwoOperation(false)}
                           className={!twoOperation ? 'floatToRight activeTab' : 'floatToRight deactiveTab'}>تغییر رمز
                            عبور</p>
                    </div>
                    {twoOperation ?
                        <>
                            <div className='containerEmailSMS'>
                                <button onClick={() => handleSettingOtp('SMS')}
                                        className={setting === 'SMS' ? 'btnModalChange activeBtnModalChange' : 'btnModalChange deactiveBtnModalChange'}>
                                    {isLoadingSMS === false ? 'پیامک' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper'
                                                 animation="border" variant="secondary"/>
                                    }
                                </button>
                                <button disabled={isLoadingEmail} onClick={() => handleSettingOtp('EMAIL')}
                                        className={setting === 'EMAIL' ? 'btnModalChange activeBtnModalChange' : 'btnModalChange deactiveBtnModalChange'}>
                                    {isLoadingEmail === false ? 'ایمیل' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper'
                                                 animation="border" variant="secondary"/>
                                    }
                                </button>
                            </div>
                            <button
                                className={setting === 'GOOGLE' ? 'btnModalChange activeBtnModalChange w-100' : 'btnModalChange deactiveBtnModalChange w-100'}
                                onClick={() => handleGoogle()}> Google Authenticor
                            </button>
                            {/*<p className='pin6'>کد شیش رقمی ارسال شده به ایمیل خود را وارد کنید.</p>*/}
                            {showInput ?
                                <Formik initialValues={{code: '', setting_value: getSettingValue, setting_key: 'OTP'}}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => handleCodeConfirm(values)}
                                >
                                    {({handleChange, handleSubmit, values, setFieldTouched, touched, errors}) => (
                                        <>
                                            <p className='pTime mt-0'><span>{getMinout}</span>:<span>{getSecond}</span>
                                            </p>
                                            <CustomInput inputType='tel' value={values.code}
                                                         handleBlur={() => setFieldTouched('code')} marginBottom='18px'
                                                         textAlignLeft={true}
                                                         placeholder='کد شش رقمی ارسال شده'
                                                         handleChange={handleChange('code')}/>
                                            <ErrorMessage error={errors.code} visible={touched.code}/>
                                            <ButtonForm isDis={isLoading} funcClick={handleSubmit}
                                            >
                                                {isLoading === false ? 'ثبت' :
                                                    <Spinner size={'sm'} className='invoice-list-wrapper'
                                                             animation="border" variant="secondary"/>
                                                }
                                            </ButtonForm>

                                        </>
                                    )}
                                </Formik>
                                : null}
                        </>
                        : <ChangePasswordDi closeDialog={closeDialog}/>
                    }
                </div>

            </DialogContent>
        </DialogOverlay>
    );
}

export default ChangePasswordDialog;