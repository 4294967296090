import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {withRouter} from 'react-router-dom'
import {getPersonalInfoLogin} from "../services/personService";
import {useDispatch, useSelector} from "react-redux";
import {userLogin, userLogout} from "../redux/actions/loginActions";
import ReactLoading from 'react-loading';
import axios from "axios";


const ScrollToTop = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const {pathname} = location
    const dispatch = useDispatch()
    const login = useSelector(state => state.login)

    const checkLogin = async () => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getPersonalInfoLogin()
            if (data.type === 'success') {
                dispatch({ type: 'SET_USER', payload: data.data })
                dispatch(userLogin())
                setIsLoading(false)
            }

        } catch (ex) {
            dispatch(userLogout())
            props.history.replace('/')
            setIsLoading(false)
        }
}

    const checkLogin2=async ()=>{
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getPersonalInfoLogin()
            if (data.type === 'success') {
                dispatch({ type: 'SET_USER', payload: data.data })
                dispatch(userLogin())
                dispatch(userLogin())
                setIsLoading(false)
            }

        } catch (ex) {
           setIsLoading(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!login) {
            if (pathname.indexOf('account') > -1) {
                if (localStorage.getItem('token')) {
                    // setIsLoading(true)
                    checkLogin()
                } else {
                    dispatch(userLogout())
                    setIsLoading(false)
                    props.history.replace('/')
                }
            } else {
                if (localStorage.getItem("token")) {
                    checkLogin2()
                }
            }

        }
    }, [pathname]);

    return <>
        {props.children}
        {isLoading &&
            <div className='spinner-container'>
                <ReactLoading
                    type="spin"
                    color="#ffffff"
                    width="100px"
                    height="100px"
                    className="spinner"
                />
                <p>در حال بارپیگیری اطلاعات</p>
            </div>}
    </>
};

export default withRouter(ScrollToTop);