import {getCoinsMarket} from "../../services/marketService";

export const setCoinsForOrders = () => {
    return async (dispatch) => {
        const {data} = await getCoinsMarket()
        await dispatch({type: 'INIT_COINS_ORDER', payload: [...data.data]})

    }
}

export const setCoinsFilterOrders = () => {
    return async (dispatch, getState) => {
        let idNum = 0
        const coins = [...getState().coinsForOrders]
        const filterArray = []
        const firstIndex = {
            id: idNum,
            title: 'تمام ارزها',
            value: 'all'
        }
        filterArray.push(firstIndex)
        idNum++;
        coins.map(item => {
            const obj = {
                id: item.id,
                title: item.coin.label,
                value: item.coin.code
            }
            filterArray.push(obj)
            idNum++
        })
        await dispatch({type: 'INIT_COINS_FILTER_ORDERS', payload: [...filterArray]})
    }
}