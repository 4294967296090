import config from './config.json'
import http from './httpService'

export const changeNewPassword = obj=>{
    console.log(obj)
    return http.post(`${config.coinbaseapi}/settings/password`,obj)
}

export const settingOtp =val=>{
    return http.post(`${config.coinbaseapi}/settings/otp`,val)
}
export const setOptCode = obj =>{
    return http.post(`${config.coinbaseapi}/settings`,obj)
}