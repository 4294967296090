import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { reducer } from './../reducers/reducers';
import { setUserInfo } from './../actions/userActions';
import { setOptions } from "../actions/optionsActions";
import { initMarketCoins } from "../actions/marketCoinsActions";
import { setReadData } from "../actions/realDataAction";

export const store = createStore(
    reducer,
    compose(applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)

// store.dispatch(setUserInfo())
store.dispatch(setOptions())
store.dispatch(initMarketCoins())