import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';

import imgInsta from './../../assets/img/socialMedia/instagram.svg'
import imgFacebook from './../../assets/img/socialMedia/facebook.svg'
import imgTelegram from './../../assets/img/socialMedia/telegram.svg'
import imgTwiter from './../../assets/img/socialMedia/twiter.svg'
import imgLocation from './../../assets/img/location.svg'
import imgEmail from './../../assets/img/email.svg'
import imgTelephone from './../../assets/img/telephone.svg'
import logoFooter from './../../assets/img/ArzTrading_logo-02.svg'

import './footer.scss'

import {getOptions} from "../../services/optionsService";

const Footer = () => {

    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [linkTelegram,setLinkTelegram]=useState('')
    const [linkFaceBook,setLinkFaceBokk]=useState('')
    const [linkInstagram,setLinkInstagram]=useState('')
    const [linkTwiter,setLinkTwiter]=useState('')

    const loadData = async () => {
        try {
            const {data} = await getOptions()
            if (data.type === 'success') {
                const phoneInfo = data.data.filter(item => item.option_key === 'phone')
                setPhone(phoneInfo[0].option_value)
                const addressInfo = data.data.filter(item => item.option_key === 'address')
                setAddress(addressInfo[0].option_value)
                const emailInfo = data.data.filter(item => item.option_key === 'email')
                setEmail(emailInfo[0].option_value)
                const telegramlInfo = data.data.filter(item => item.option_key === 'telegram')
                setLinkTelegram(telegramlInfo[0].option_value)
                const instagramInfo = data.data.filter(item => item.option_key === 'instagram')
                setLinkInstagram(instagramInfo[0].option_value)
                const facebookInfo = data.data.filter(item => item.option_key === 'facebook')
                setLinkFaceBokk(facebookInfo[0].option_value)
                const twitterInfo = data.data.filter(item => item.option_key === 'twitter')
                setLinkTwiter([0].option_value)
            }
        } catch (ex) {
            console.log(ex)
        }
    }
    useEffect(() => {
            loadData()
        }, []);

    return (
        <footer className='mainFooter'>
            <div className="footer">
                <div className="insideFooter">

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="footer2">
                                <div className="insideFooter2">
                                    <div>
                                        <p className='titleFooter leftInMobile'>دسترسی سریع</p>
                                        <NavLink className='footerLink leftInMobile' to='/markets'>رمز ارزها</NavLink>
                                        <NavLink className='footerLink leftInMobile' to='/howtouse'>راهنمای استفاده</NavLink>
                                        <NavLink className='footerLink leftInMobile' to='/faq'>سوالات متداول</NavLink>
                                    </div>
                                    <div>
                                        <p className='titleFooter'>راهنما</p>
                                        <NavLink className='footerLink' to='/guid'>مطالب آموزشی</NavLink>
                                        <NavLink className='footerLink' to='/privacypolicy'>قوانین و مقررارت</NavLink>
                                        <NavLink className='footerLink' to='/aboutus'>درباره ما</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-xs-12'>
                                    <p className='titleFooter'>تماس با ما</p>
                                    <div className='eleContactUs'>
                                        <p className='text-right'>{address}</p>
                                        <img alt="" src={imgLocation} className='imgLocation'/>
                                    </div>
                                    <div className='eleContactUs'>
                                        <p className='text-right'>{phone}</p>
                                        <img src={imgTelephone} alt=""/>
                                    </div>
                                    <div className='eleContactUs'>
                                        <p className='text-right'>{email}</p>
                                        <img src={imgEmail} alt=""/>
                                    </div>
                                </div>


                                <div className='col-md-6 col-xs-12 rightwo'>
                                    <p className='titleFooter'>راهنما</p>
                                    <NavLink className='footerLink' to='/guid'>مطالب آموزشی</NavLink>
                                    <NavLink className='footerLink' to='/role'>قوانین و مقررارت</NavLink>
                                    <NavLink className='footerLink' to='/aboutus'>درباره ما</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 rightTop">
                            <div className="row">
                                <div className='col-md-6 col-xs-12 hideInMobile'>
                                    <p className='titleFooter'>دسترسی سریع</p>
                                    <NavLink className='footerLink' to='/markets'>رمز ارزها</NavLink>
                                    <NavLink className='footerLink' to='/howtouse'>راهنمای استفاده</NavLink>
                                    <NavLink className='footerLink' to='/faq'>سوالات متداول</NavLink>
                                </div>

                                <div dir='rtl' className='col-md-6 col-xs-12'>
                                    <img alt="" src={logoFooter} className='logoFooter'/>
                                    <p className='pLorem justify'>
                                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از
                                        طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                                        لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود
                                        ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده،
                                        شناخت فراوان جامعه و متخصصان را می طلبد
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outSideFooter'>
                <div className='containerIconSocial'>
                    <a href={`http://www.instagram.com/${linkInstagram}`}>
                        <img alt="" src={imgInsta} className='socialImage'/>
                    </a>
                    <a href={`https://www.messenger.com/t/${linkFaceBook}`}>
                        <img alt="" src={imgFacebook} className='socialImage'/>
                    </a>
                    <a href={`https://telegram.me/${linkTelegram}`}>
                        <img alt="" src={imgTelegram} className='socialImage'/>
                    </a>
                    <a href={`https://twitter.com/intent/${linkTwiter}`}>
                        <img alt="" src={imgTwiter} className='socialImage'/>
                    </a>

                </div>
                <p className='pInOutsideFooter'>تمامی حقوق مادی و معنوی این وب سایت متعلق به شرکت نرم افزاری ویستا
                    (جوانفکران داده گستر) میباشد.</p>
            </div>
        </footer>
    );
}

export default Footer;