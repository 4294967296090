import React, {useEffect, useState} from "react";
import {convertDateTime} from "../../utils/Price";
import {persianNameDevices} from "../../utils/helperFunction";
import InfiniteScroll from 'react-infinite-scroller';

import {useDispatch, useSelector} from "react-redux";
import './history.scss'
import Loading from "../Loading/Loading";
import {getDataLoginHistory} from "../../redux/actions/historyLoginActions";

const HistoryLogin = () => {

    const historyLogin = useSelector(state => state.historyLogin)
    const metaLogin = useSelector(state => state.metaLogin)
    const dispatch = useDispatch()
    const [lastPage] = useState(metaLogin.last_page)
    const [hasMore, setHasMore] = useState(true)

    const handleNextData = (page) => {

        if (page <= lastPage) {
            dispatch(getDataLoginHistory(page))
        }else{
            setHasMore(false)
        }

    }

    return (
        <InfiniteScroll
            hasMore={hasMore}
            pageStart={0}
            loader={<Loading/>}
            loadMore={() => handleNextData(metaLogin.current_page + 1)}>

            <table dir='rtl' className='table table-borderless'>
                <thead>
                <tr>
                    <td className='titleTableEntires text-center'>زمان</td>
                    <td className='titleTableEntires text-center'>آی پی</td>
                    <td className='titleTableEntires text-center'>
                        <span className='noWrap'>نوع دستگاه</span>
                    </td>
                    <td className='titleTableEntires text-center'>مرورگر</td>
                    <td className='titleTableEntires text-center'>وضعیت</td>
                </tr>
                </thead>
                <tbody>
                {historyLogin.length > 0 ?
                    historyLogin.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center dateTimeInFAQ'><span
                                className='valueTableEntires'>{item ? convertDateTime(item.created_at) : '-'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item ? item.ip : '-'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item ? persianNameDevices(item.device) : '-'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item ? item.browser : '-'}</span>
                            </td>
                            <td className='text-center'><span
                                className={item ? (item.status === 'SUCCESS' ? 'valueTableEntires statusTrue' : "valueTableEntires statusFalse") : ''}>{item ? (item.status === 'SUCCESS' ? 'موفق' : 'ناموفق') : '-'}</span>
                            </td>
                        </tr>
                    ))
                    :
                    <p>اطلاعاتی موجود نیست</p>}


                </tbody>
            </table>
        </InfiniteScroll>

    )
}
export default HistoryLogin