export const transactionsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_TRANSACTION':
            return [...action.payload]
        default:
            return state
    }
}

export const transactionsCryptoReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_TRANSACTION-CRYPTO':
            return [...action.payload]
        default:
            return state
    }
}

