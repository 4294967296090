import React, {useEffect} from 'react';
import {floorNumber, numberWithCommas} from './../../utils/Price';
import './info.scss'
import {useDispatch, useSelector} from "react-redux";
import {setInfo} from "../../redux/actions/featuresctions";

const Info = ({countUser = 162927, countExchange = 817927, volumeExchange = 8362297827, marginBottom}) => {

    const dispatch = useDispatch()
    const info = useSelector(state => state.info)

    useEffect(() => {
        dispatch(setInfo())
    }, [])

    return (
        <section style={{marginBottom}} className='info'>
            <div className="insideInfo">

                <div className="text-center infoCard">
                    <p className='counterInfo'>{info.countOrders ? numberWithCommas(info.countOrders) : 0}</p>
                    <p className='titleInfo'>تعداد معاملات</p>
                </div>
                <div className="text-center infoCard">
                    <p className='counterInfo orderVolume'>{info.orderVolume ? numberWithCommas(floorNumber(info.orderVolume)) : 0}</p>
                    <p className='titleInfo'>حجم معاملات (تومان)</p>
                </div>
                <div className="text-center infoCard">
                    <p className='counterInfo'>{info.userCount ? numberWithCommas(info.userCount) : 0}</p>
                    <p className='titleInfo'>تعداد کاربران</p>
                </div>
            </div>
        </section>
    );
}

export default Info;