import React, { useRef, useEffect } from 'react';
import './subMenus.scss'

const SubMenus = ({ list, showMe, setMe, setID }) => {

    const refMenu = useRef()

    useEffect(() => {
        const checkMenu = e => {
            if (showMe && refMenu.current && !refMenu.current.contains(e.target)) {
                setMe(false)
            }
        }
        document.addEventListener('click', checkMenu)
        return () => document.removeEventListener('click', checkMenu)
    }, [showMe])

    return (<div ref={refMenu} className='subMenus'>
        {list.map(item => (
            <div className='divSubMenu' onClick={() => setID(item.id)}>
                <p>{item.title}</p>
            </div>
        ))}
    </div>);
}

export default SubMenus;