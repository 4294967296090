import React from 'react';
import {withRouter} from 'react-router-dom'

import './boxBtnLoginRegister.css'
import {changeRegisterMode} from "../../redux/actions/registerModeAction";
import {useDispatch} from "react-redux";

const BoxBtnLoginRegister = (props) => {
    const {pathname} = props.location
    const dispatch = useDispatch()
    return (
        <div className='btnBoxNavbar'>
            <button className={pathname.indexOf('login') < 0 ? 'activeBtnNav' : 'btnNav'} onClick={() => {
                dispatch(changeRegisterMode(true))
                props.history.replace('/register');
            }}>ثبت نام
            </button>
            <button className={pathname.indexOf('login') > 0 ? 'activeBtnNav' : 'btnNav'} onClick={() => {
                props.history.replace('/login')
            }}>ورود
            </button>
        </div>
    );
}

export default withRouter(BoxBtnLoginRegister);