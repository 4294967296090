import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import ButtonForm from '../../components/ButtomForm/ButtomForm';
import * as Yup from 'yup'
import axios from 'axios';
import {setDigit} from './../../redux/actions/digitActions';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {registerConfirm, resendCodeRegister, resendCodeLogin} from '../../services/userService';
import {changeRegisterMode} from './../../redux/actions/registerModeAction';
import {userLogin} from './../../redux/actions/loginActions';

import './login.css'
import './registerOPT.css'
import {loginConfirm} from './../../services/userService';
import {getPersonalInfoLogin} from '../../services/personService';
import {setUserInfo} from '../../redux/actions/userActions';
import {getInviteDataRedux} from "../../redux/actions/inviteDataActions";
import {getMethod} from "../../utils/Price";
import {Spinner} from "reactstrap";
import {getActiveDebit} from "../../redux/actions/bankAccountsAction";
import ShowTimer from "../../components/ShowTimer/ShowTimer";

const LoginConfirm = ({loginMode, history, registerMode, userEmail , method}) => {

    const dispatch = useDispatch()
    const [sendAgain, setSendAgain] = useState(false)
    const [email, setEmail] = useState(userEmail)
    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])
    const [isDisable, setIsDisable] = useState(false)

    let codeNumber = ''
    const numOfFields = 6;

    const handleSendAgain = async () => {
        try {
            setIsDisable(true)
            const {data} = await resendCodeLogin({email})
            console.log(data)
            if (data.type === 'success') {
                setIsDisable(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setSendAgain(false)
            }
        } catch (ex) {
            console.log(ex);
            setIsDisable(false)
        }
    }

    const handleCodeConfirm = async () => {

        const code = numberrr.join('')
        const user = {
            email, code
        }
        if (code.length > 5) {
            setIsDisable(true)
            try {
                const {data} = await loginConfirm(user)
                if (data.type === 'success') {
                    toast.success(`خوش آمدید`, {position: 'top-right', closeOnClick: true})
                    localStorage.setItem("token", data.data.token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
                    dispatch(setUserInfo())
                    dispatch(changeRegisterMode(false))
                    dispatch(userLogin())
                    dispatch(getInviteDataRedux())
                    // dispatch(getActiveDebit())
                    setIsDisable(false)
                    history.replace('/account')
                }
            } catch (ex) {
                toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                setIsDisable(false)
                // console.log(ex.data.message);

            }
        } else {
            toast.error('لطفا کد را کامل وارد کنید', {position: 'bottom-right', closeOnClick: true})
        }

    }

    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        codeNumber = temp
        dispatch(setDigit(numberrr.join().replaceAll(',', '')))
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < numOfFields) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
                return
            }
        }

    }


    return (
        <div className='registerOPT'>
            <div className='containerRegisterOPT'>
                <p className='titleInForm'>تایید کد  </p>
                <div className='insideForm'>
                    <p className='pInRegisterOPT'>کد شش رقمی ارسال شده به {getMethod(method)} را وارد کنید</p>
                    <div className='inputDigit6'>
                        <div className='containerInputCodeOPT'>
                            {numberrr.map((_, index) =>
                                <input key={index} maxLength="1" onChange={(e) => handleTextChange(e, index)}
                                       name={`ssn-${index + 1}`} className='inputCodeOPt' type="number"/>
                            )
                            }
                        </div>
                    </div>
                    {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                    {sendAgain ?
                        <ButtonForm funcClick={handleSendAgain} marginBottom={16}>
                            {isDisable === false ? 'ارسال مجدد کد' :
                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                         variant="secondary"/>
                            }
                        </ButtonForm> : null}
                    {!sendAgain ?<ButtonForm funcClick={handleCodeConfirm} marginBottom={37}>
                        {isDisable === false ? 'ورود' :
                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                     variant="secondary"/>
                        }
                    </ButtonForm>:null}
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginConfirm);