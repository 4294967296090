export const marketCoinsReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_MARKET_COINS':
            return [...action.payload]
        default:
            return state
    }
}

export const coinReducer = (state={},action)=>{
    switch (action.type){
        case 'SET_COIN':
            return {...action.payload}
        case 'CLEAR_COIN':
            return {}
        default:
            return state
    }
}