import config from './config.json'
import http from './httpService'

export const depositOnline = (obj) => {
    return http.post(`${config.coinbaseapi}/transactions/type/deposit`, obj)
}

export const postWithdraw = data => {
    return http.post(`${config.coinbaseapi}/transactions/type/withdraw`, data)
}

export const optWithdraw = () => {
    return http.post(`${config.coinbaseapi}/transactions/otp`)
}

export const getDepositAddress = code => {
    return http.get(`${config.coinbaseapi}/networks/${code}}/address`)
}
export const depositCrypto = obj => {
    return http.post(`${config.coinbaseapi}/transactions/type/deposit`, obj)
}
export const otpForWithdrawCrypto = () => {
    return http.post(`${config.coinbaseapi}/transactions/otp`)
}
export const withdrawCrypto = obj => {
    return http.post(`${config.coinbaseapi}/transactions/type/withdraw`, obj)
}