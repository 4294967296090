import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import TopNavbar from '../../components/Nav/TopNavbar';
import { withRouter } from 'react-router-dom'

const MainLayout = (props) => {

    const { pathname } = props.location
    const [getPathName, setPathName] = useState(pathname)

    useEffect(() => {
        setPathName(pathname)
    }, [pathname])
    return (
        <>
            <TopNavbar />
            {props.children}
            {getPathName.indexOf('register') > -1 || getPathName.indexOf('login') > -1 ? null : <Footer />}
        </>
    );
}

export default withRouter(MainLayout);