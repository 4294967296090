import React, { useEffect, useRef, useState } from 'react';
import eyeImage from './../../assets/img/eye.svg'
import { numberWithCommas } from './../../utils/Price'
import './inputForms.scss'

const InputForms = ({ placeholder, typeInput, value = '', handleBlured, changeValue, marginBottom, isPassword = false, price = false, ltr = false }) => {


    const refInput = useRef()
    const refInputData = useRef()
    const [getValue, setValue] = useState('')
    const [getFocusInput, setFocusInput] = useState(false)
    const [mousein, setMouseIn] = useState(false)

    useEffect(() => {
        if (getFocusInput) {
            refInput.current.classList.add('activeInput')
        } else {
            refInput.current.classList.remove('activeInput')
        }
    }, [getFocusInput])

    // useEffect(() => {
    //     const logFocuse = () => {
    //         if (mousein) {
    //             setFocusInput(true)
    //         }
    //     }
    //     const handleOnBlur = () => {
            // handleBlured()
            // setFocusInput(false)
        // }
        // refInputData.current.addEventListener('focus', logFocuse)
        // refInputData.current.addEventListener('focusout', handleOnBlur)
        // return () => {
        //     refInputData.current.removeEventListener('focusout', handleOnBlur)
        //     refInputData.current.removeEventListener('focus', logFocuse)

        // }
    // })

    return (
        <div ref={refInput} className='containerInput' onMouseMove={() => setMouseIn(true)} onMouseOut={() => setMouseIn(false)} style={{ marginBottom }}>
            {isPassword ? <img src={eyeImage} className='eyeInputPassword' /> : null}
            <input type={typeInput} className={value.length > 0 ? 'myInput mb0 ltrInput' : 'myInput mb0'}
                onChange={changeValue}
                ref={refInputData}
                placeholder={placeholder}
                value={value ? value : null}
                // onBlur={handleBlured}
                onBlur={() => { setFocusInput(false) }}
                onFocus={() => setFocusInput(true)}
                // value={price ? numberWithCommas(getValue) : getValue}
                style={{ textAlign: isPassword ? 'left' : 'right' }} />
            {value !== '' && ltr ? <span className='spanHolder'>{placeholder}</span> : null}
        </div>
    );
}

export default InputForms;