import bankImage from './../../assets/img/bankSaman.jpg'

export const cardsReducer = (state = [
    { id: 0, IR: 1234567891234567, cardNumber: 6219861042890211, img: bankImage, bankName: 'سامان' },
    { id: 1, IR: 1234567891234567, cardNumber: 6219861042890212, img: bankImage, bankName: 'ملی' },
    { id: 2, IR: 1234567891234567, cardNumber: 6219861042890213, img: bankImage, bankName: 'تجارت' },
], action) => {
    switch (action.type) {
        case 'ADD_CARD':
            return action.payload
        case 'REMOVE_CARD':
            return action.payload
        default:
            return state
    }
}