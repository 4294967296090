import React, { useState } from 'react';
import arrow from './arrow.svg'
import './componentMenu.scss'
import SubMenus from './SubMenus/SubMenus';

const ComponentMenu = ({ placeholder, list, title, setID, showSpan, enableSpan = false, marginBottom}) => {

    const [showMenu, setShowMenu] = useState(false)


    return (
        <div style={{marginBottom}} className='componentMenu'>
            <div onClick={() => setShowMenu(!showMenu)} className='insideComponentMenu'>
                <img src={arrow} alt="" />
                <p>{placeholder}</p>
                {showSpan > -1 ? <span className={enableSpan?'spanMenu':'spanMenu hideSpanMenu'}>{title}</span> : null}
                {showMenu ? <SubMenus setID={setID} list={list} showMe={showMenu} setMe={setShowMenu} /> : null}
            </div>
        </div>
    );
}

export default ComponentMenu;
