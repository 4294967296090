import {getHistoryLoggin} from "../../services/personService";
import axios from "axios";

export const setEntired = () => {
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        const {data} = await getHistoryLoggin()
        await dispatch({type: 'INIT_ENTIRE', payload: data.data})
    }
}

export const setEntiredTop10 = () => {
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        const {data} = await getHistoryLoggin('?size=7')
        await dispatch({type: 'INIT_ENTIRE', payload: data.data})
    }
}