import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import ButtonForm from '../../components/ButtomForm/ButtomForm';
import axios from 'axios';
import {setDigit} from './../../redux/actions/digitActions';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {registerConfirm, resendCodeRegister} from '../../services/userService';
import {changeRegisterMode} from './../../redux/actions/registerModeAction';

import {Spinner} from "reactstrap";
import ShowTimer from "../../components/ShowTimer/ShowTimer";


const RegisterConfirm = ({loginMode, history, registerMode, userEmail = 'mehdifarshbaf92@gmail.com'}) => {

    const dispatch = useDispatch()

    const [sendAgain, setSendAgain] = useState(false)
    const [loadingSendAgain, setLoadingSendAgain] = useState(false)
    const [email, setEmail] = useState(userEmail)
    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])
    const [isDisable, setIsDisable] = useState(false)

    let codeNumber = ''
    const numOfFields = 6;
    // let numberrr = ['','', '', '','' ,'']

    const handleSendAgain = async () => {

        const objEmail = {
            email
        }

        try {
            setLoadingSendAgain(true)
            const {data} = await resendCodeRegister(objEmail)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setSendAgain(false)
                setLoadingSendAgain(false)
            }
            if (data.type === 'error') {
                toast.error(`${data.message}`, {position: 'bottom-right', closeOnClick: true})
                setLoadingSendAgain(false)
            }
        } catch (ex) {
            console.log(ex);
            setLoadingSendAgain(false)
        }
    }
    const handleCodeConfirm = async () => {

        const code = numberrr.join('')
        const user = {
            email, code
        }
        if (code.length > 5) {
            setIsDisable(true)
            try {
                const {data} = await registerConfirm(user)
                if (data.type === 'success') {
                    toast.success(`خوش آمدید`, {position: 'top-right', closeOnClick: true})
                    localStorage.setItem("token", data.data.token.plainTextToken)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

                    history.replace('/login')
                    // dispatch(changeRegisterMode(false))
                    // dispatch(userLogin())
                }
            } catch (ex) {
                toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                setIsDisable(false)

            }
        } else {
            setIsDisable(false)

            toast.error('لطفا کد را کامل وارد کنید', {position: 'bottom-right', closeOnClick: true})
        }

    }

    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        // console.log(temp.join(''));
        codeNumber = temp
        dispatch(setDigit(numberrr.join().replaceAll(',', '')))
        // Check if they hit the max character length
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < numOfFields) {
                // Get the next input field
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );


                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
                return
            }
        }

    }

    useEffect(() => {

        return () => dispatch(changeRegisterMode(true))
    }, []);

    return (
        <div className='registerOPT'>
            <div className='containerRegisterOPT'>
                <p className='titleInForm'>تایید آدرس ایمیل</p>
                <div className='insideForm'>
                    <p className='pInRegisterOPT'>کد شیش رقمی ارسال شده به ارسال ایمیل را وارد کنید</p>
                    <div className='inputDigit6'>
                        <div className='containerInputCodeOPT'>
                            {numberrr.map((_, index) =>
                                <input key={index} maxLength="1" pattern="\d*"
                                       onChange={(e) => handleTextChange(e, index)}
                                       name={`ssn-${index + 1}`} className='inputCodeOPt' type="number"/>
                            )
                            }
                        </div>
                    </div>
                    {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                    {sendAgain ?
                        <ButtonForm isDis={loadingSendAgain} funcClick={handleSendAgain} marginBottom={16}>
                            {loadingSendAgain === false ? 'ارسال مجدد کد' :
                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                         variant="secondary"/>
                            }
                        </ButtonForm> : null}
                    {!sendAgain ?
                        <ButtonForm funcClick={handleCodeConfirm} marginBottom={37}>
                            {isDisable === false ? 'ثبت' :
                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                         variant="secondary"/>
                            }
                        </ButtonForm>
                        : null}
                </div>
            </div>
        </div>
    );
}

export default withRouter(RegisterConfirm);