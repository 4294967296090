import React, {useState} from 'react';
import ButtonForm from '../../components/ButtomForm/ButtomForm';
import {withRouter} from 'react-router-dom'
import {Formik} from 'formik';
import * as Yup from 'yup'
import './forgotPassword.css'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {forgotPassword} from "../../services/userService";
import {toast} from "react-toastify";
import ForgotPasswordOPT from "../ForgotPasswordOPT/ForgotPasswordOPT";
import {Spinner} from 'reactstrap';
import CustomInput from "../../components/CustomInput/CustomInput";


const validationSchema = Yup.object().shape({
    email: Yup.string().required('پر کردن این فیلد الزامی می باشد.').email('ایمیل وارد شده معتبر نمی باشد.')
})

const ForgotPassword = (props) => {

    const [isDisable, setIsDisable] = useState(false)
    // const [doneEmail, setDoneEmail] = useState(false)
    const [doneEmail, setDoneEmail] = useState(false)

    const handleForgotPassword = async (values) => {
        setIsDisable(true)
        try {
            const {data} = await forgotPassword(values)
            if (data.type === 'success') {
                setIsDisable(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setDoneEmail(true)
            }
        } catch (ex) {
            setIsDisable(false)
            toast.error(`${ex.data.errors.email[0]}`, {position: 'bottom-right', closeOnClick: true})
        }
    }

    return (
        <>
            <Formik
                initialValues={{email: ''}}
                validationSchema={validationSchema}
                onSubmit={(values) => handleForgotPassword(values)}
            >
                {({handleChange, handleSubmit, setFieldTouched, touched, values, errors}) => (
                    <>
                        {doneEmail ?
                            <ForgotPasswordOPT userEmail={values} goForgotPassword={() => setDoneEmail(false)}/> :
                            <div className='forgotPassword'>
                                <div className='containerForgotPassword'>
                                    <p className='titleInForm'>فراموشی رمز عبور</p>
                                    <div className='insideForm'>
                                        <p className='pInForgotPassword reg14'>آدرس ایمیل متصل به حساب کاربری خود را
                                            وارد
                                            نمایید.</p>
                                        <CustomInput inputType='email' placeholder='آدرس ایمیل' marginBottom='18px'
                                                     textAlignLeft={true} handleChange={handleChange('email')}
                                                     handleBlur={() => setFieldTouched('email')}
                                                     value={values.email}/>
                                        <ErrorMessage error={errors.email} visible={touched.email}/>
                                        <ButtonForm isDis={isDisable} funcClick={handleSubmit} marginBottom='16px'>
                                            {isDisable === false ? 'ثبت' :
                                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                         variant="secondary"/>
                                            }
                                        </ButtonForm>
                                    </div>
                                </div>
                            </div>

                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default withRouter(ForgotPassword)