import React, { useEffect, useRef, useState } from 'react';
import arrow from './Vector.svg'
import './faqItem.scss'
import { setViewNotification } from '../../services/notificationsService';
import moment from 'jalali-moment'
import {convertDateTime} from "../../utils/Price";


const FAQItem = ({ answer, question, dateTime, isRead, id }) => {

    const refFAQ = useRef()
    const refPAnswer = useRef()
    const refPQuestion = useRef()
    const sectionQuetionRef = useRef()

    const [showAnswer, setShowAnswer] = useState(false)

    const handleShowAnswer = async () => {
        if (isRead != true) {
            try {
                // const { data } = await setViewNotification(id)
                // console.log(data);
            } catch (ex) {
                console.log(ex);
            }
        }
        setShowAnswer(!showAnswer)
    }
    // close when click outside
    useEffect(() => {
        const checkOpen = (e) => {
            if (showAnswer && refFAQ.current && !refFAQ.current.contains(e.target)) {
                setShowAnswer(false)
            }
        }
        document.addEventListener('click', checkOpen)
        return () => document.removeEventListener('click', checkOpen)
    }, [showAnswer])

    useEffect(() => {
        // console.log(refPQuestion.current.getBoundingClientRect().height);
        const defaultHeight = (refPQuestion.current.getBoundingClientRect().height + 30)
        // console.log(defaultHeight);
        sectionQuetionRef.current.style.height = defaultHeight + 'px'
        const newHeight = (refPAnswer.current.getBoundingClientRect().height + 18 + defaultHeight) + 'px'
        if (showAnswer) {
            refFAQ.current.style.height = newHeight
            refPAnswer.current.style.opacity = '1'
        } else {
            refFAQ.current.style.height = defaultHeight + 'px'
            refPAnswer.current.style.opacity = '0'
        }
    }, [showAnswer])
    useEffect(()=>{
        if(isRead){
            sectionQuetionRef.current.classList.add('isReadFAQ')
        }else{
            sectionQuetionRef.current.classList.remove('isReadFAQ')
        }
    },[isRead])

    useEffect(()=>{
        // const arr=dateTime.split('T')
        // const date = moment(arr[0], 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')
        // const time = arr[1].slice(0,5)
        // console.log(`${date} ${time}`)
        // console.log(moment(arr[0], 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD'))

        // console.log(moment(arr[0]))
    },[])

    return (
        <div ref={refFAQ} className={showAnswer ? 'faqItem activeFAQ' : 'faqItem'}>
            <div ref={sectionQuetionRef} className={showAnswer ? 'sectionQuestion activeQuestion' : 'sectionQuestion'}
                onClick={() => handleShowAnswer()}
            >
                <img src={arrow} className={showAnswer ? 'rotateImage' : null} />
                {dateTime ? <p className='dateTimeInFAQ'>{convertDateTime(dateTime)}</p> : null}
                {/*{dateTime ? <p>{moment(dateTime)}</p> : null}*/}
                <p ref={refPQuestion}>{question}</p>
            </div>
            <section className='sectionAnswer'>
                <p ref={refPAnswer}>{answer}</p>
                {/* <p>{dateTime.toLocaleString('fa-IR', { timeZone: 'UTC' })}</p> */}
            </section>
        </div>
    );
}

export default FAQItem;