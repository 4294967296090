import React, {useEffect} from 'react';

import passwordImage from './../../assets/img/dashboard/password.svg'
import walletImage from './../../assets/img/dashboard/wallet.svg'
import friendsdImage from './../../assets/img/dashboard/friends.svg'
import imgDanger from './../../assets/img/dangerIcon.svg'
import './dashboard.scss'
import {floorNumber, numberWithCommas, typeOfLoggin} from './../../utils/Price';
import ContainerTables from '../../components/ContainerTables/ContainerTables';
import ChangePasswordDialog from '../../dialogs/ChangePasswordDialog/ChangePasswordDialog';
import {useState} from 'react';
import DepositDialog from './../../dialogs/DepositDialog/DepositDialog';
import WithdrawDialog from '../../dialogs/WithdrawDialog/WithdrawDialog';
import {NavLink, withRouter} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux';
import {logoutUser} from './../../services/userService';
import GoogleAuthen from "../../dialogs/GoogleAuthen/GoogleAuthen";
import {getGoogle2af} from "../../services/google2afService";
import {toast} from "react-toastify";
import {setUserInfo} from "../../redux/actions/userActions";
import {getActiveDebit} from "../../redux/actions/bankAccountsAction";

const Dashbord = ({history}) => {

    const user = useSelector(state => state.user)
    const inviteData = useSelector(state => state.inviteData)
    const acceptedAccounts = useSelector(state => state.acceptedAccounts)
    const dispatch = useDispatch()


    const [changePasswordDialog, setChangePasswordDialog] = useState(false)
    const openChangepasswordDialog = () => {
        dispatch(setUserInfo())
        setChangePasswordDialog(true)
    }
    const closeChangepasswordDialog = () => setChangePasswordDialog(false)

    const [showDialogDeposit, setDialogDeposit] = useState(false)
    const openDepositDialog = () => {
        if (acceptedAccounts.length > 0) {
            setDialogDeposit(true)
        } else {
            toast.error('هنوز کارت بانکی تایید شده ای برای شما وجود ندارد.', {position: 'bottom-right', closeOnClick: true})
        }
    }
    const closeDepositDialog = () => setDialogDeposit(false)

    const [showDialogWithdraw, setDialogWithdraw] = useState(false)
    const openWithdrawDialog = () => {

        if (acceptedAccounts.length > 0) {
            setDialogWithdraw(true)
        } else {
            toast.error('هنوز کارت بانکی تایید شده ندارید', {position: 'bottom-right', closeOnClick: true})
        }
    }
    const closeWithdrawDialog = () => setDialogWithdraw(false)

    const [showGoogleAuthen, setGoogleAuthen] = useState(false)
    const openGoogleAuthen = () => setGoogleAuthen(true)
    const closeGoogleAuthen = () => setGoogleAuthen(false)

    const [getSecretKey, setSecretKey] = useState('')
    const [getQR, setGR] = useState('')

    const handleShowGoogleAuthen = async () => {
        closeChangepasswordDialog()
        try {
            const {data} = await getGoogle2af()
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                const {secret_key, qr_image} = data.data
                // console.log(secret_key)
                setGR(qr_image)
                setSecretKey(secret_key)
                openGoogleAuthen()
            }
            console.log(data)
        } catch (ex) {
            // console.log(ex)
        }
    }


    useEffect(() => {
        document.title = 'پنل کاربری || داشبورد'
        // dispatch(setUserInfo())
        dispatch(getActiveDebit())
        // console.log(user)
    }, [])

    const getStatus = str => {
        switch (str) {
            case 'SECONDARY_AUTH_DONE':
                return 'تکمیل اطلاعات کاربر'
            case 'OTP_DONE':
            case 'CONFIRMED_IDENTITY':
                return 'در انتظار تایید'
            default:
                return ''
        }
    }
    return (
        <div className='dashboard'>
            <DepositDialog showDialog={showDialogDeposit} closeDialog={closeDepositDialog}/>
            <WithdrawDialog showDialog={showDialogWithdraw} closeDialog={closeWithdrawDialog}/>
            {/*<ChangePasswordDialog methode={user.settings ? user.settings[0].setting_value : ''}*/}
            <ChangePasswordDialog
                methode={user.settings ? (user.settings[0].setting_value?user.settings[0].setting_value:'') : ''}
                                  showDialog={changePasswordDialog} closeDialog={closeChangepasswordDialog}
                                  handleGoogle={handleShowGoogleAuthen}/>
            <GoogleAuthen closeDialog={closeGoogleAuthen} showDialog={showGoogleAuthen} secretKet={getSecretKey}
                          qrImage={getQR}/>
            <div className='accountInfoDashboard'>
                <div className='insideAccountInfoDashboard'>
                    <div className='d-flex'>
                        <NavLink to='/account/profile' className='linkAccountInfo '>{getStatus(user.status)}</NavLink>
                        {/*<img alt='' src={imgDanger}/>*/}

                    </div>
                    <p>{user.email}</p>
                </div>
            </div>

            <div className='containerDivs'>
                <div className='shareDiv divPassword'>
                    <img src={passwordImage} className='imgInDivs'/>
                    <p className='pEnter'>ورود با</p>
                    <p className='authenticationGoogle'>{user.settings ? typeOfLoggin(user.settings[0].setting_value) : ''}</p>
                    {/*<p className='authenticationGoogle'>*/}
                    {/*    بعدا باید تنظیمات ورود باشد*/}
                    {/*</p>*/}
                    <button onClick={openChangepasswordDialog} className='btnSettingPassword'>تنظیمات</button>
                </div>
                <div className='shareDiv divFriends'>
                    <img src={friendsdImage} className='imgInDivs'/>

                    <div className='divInsideFriend'>
                        <div className='divLeftInside'>
                            <p className='ffpp'>مجموع سود من</p>
                            <p className='onethValue'><span>{inviteData.length > 0 ? inviteData.commission : 0}</span>تومان
                            </p>
                        </div>
                        <div className='divRightInside'>
                            <p className='ffpp'>تعداد دوستان من</p>
                            <p className='twothValue'><span>{inviteData.length > 0 ? inviteData.count : 0}</span>نفر</p>
                        </div>
                    </div>
                    <button className='btnInvite' onClick={() => {
                        history.push('/account/invite')
                    }}>دعوت دوستان
                    </button>
                </div>
                <div className='shareDiv divWallet'>
                    <img src={walletImage} className='imgInDivs'/>
                    <p className='firstPWallet'>کیف پول تومان</p>
                    {/*<p className='MojodiWallet'>{numberWithCommas(183725000)}</p>*/}
                    <p className='MojodiWallet'>{user.net_assets ? numberWithCommas(floorNumber(user.net_assets)) : 0}</p>
                    <div className='containerBtnWallet'>
                        <button className='btnInWalletDiv btnInWalletDiv1' onClick={() => openWithdrawDialog()}>برداشت
                        </button>
                        <button className='btnInWalletDiv' onClick={() => openDepositDialog()}>واریز</button>
                    </div>
                </div>
            </div>
            {/* end 3 div */}
            <ContainerTables/>
        </div>
    );
}

export default withRouter(Dashbord);