import React, { useRef } from 'react';
import Carousel from 'react-elastic-carousel'
import PostItem from '../PostItem/PostItem';

import imgLeft from './../../assets/img/slider/leftArrow.svg'
import imgRight from './../../assets/img/slider/rightArrow.svg'

import './post.scss'

const Post = () => {

    const refCarousel = useRef()
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        // { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    ]
    return (
        <section className='post'>
            <div className="insidePost">
                <Carousel
                    ref={refCarousel}
                    showArrows={false}
                    pagination={false}
                    breakPoints={breakPoints}
                    itemPadding={[20, 5]}>
                    <PostItem />
                    <PostItem />
                    <PostItem />
                    <PostItem />
                    <PostItem />
                    <PostItem />
                </Carousel>
                <img onClick={() => refCarousel.current.slidePrev()} src={imgLeft} className='arrowLeft' />
                <img onClick={() => refCarousel.current.slideNext()} src={imgRight} className='arrowRight' />
            </div>
        </section>
    );
}

export default Post;