import React, {useRef, useEffect, useState} from 'react';
import {setIdCoin} from './../../redux/actions/selectCoinId';
import {useDispatch} from 'react-redux';

// images
import imgCloseCrypto from './closeCrypto.svg'
import searchIcon from './searchIcon.svg'

import './cryptoMenu.scss'
import {setCoin} from "../../redux/actions/marketCoinsActions";
import {setMarketId} from "../../redux/actions/marketIdAction";

const CryptoMenu = ({listItems, showMeState, closeMe}) => {

    const [searchKey, setSearchKey] = useState('')
    const inputSearchRef = useRef()
    const cryptoMenuRef = useRef()
    const dispatch = useDispatch()

    // close menu when user press key echap
    const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            closeMe(false)
        }
    }
    const selectCoins = id => {
        setSearchKey('')
        dispatch(setIdCoin(id))
        dispatch(setCoin(id))
        dispatch(setMarketId(id))
        closeMe(false)
    }

    // focus to input search when run menu
    useEffect(() => {
        inputSearchRef.current.focus()
    })

    // close menu when click outside menu
    useEffect(() => {
        const checkCryptoMenu = e => {
            if (showMeState && cryptoMenuRef.current && !cryptoMenuRef.current.contains(e.target)) {
                closeMe(false)
            }
        }
        document.addEventListener('click', checkCryptoMenu)
        return () => document.removeEventListener('click', checkCryptoMenu)
    }, [showMeState])

    return (
        <div ref={cryptoMenuRef} className='cryptoMenu'>
            <div className='sectionSearchCryptoMenu'>
                <div className='insideSearchCryptoMenu'>
                    <img src={imgCloseCrypto} onClick={() => closeMe(false)} className='imgCloseCrypto'/>
                    <div className='d-flex'>
                        <input
                            type="text"
                            ref={inputSearchRef}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => setSearchKey(e.target.value)}
                            placeholder='جستجو بر اساس نام یا نماد اختصاری'/>
                        <img src={searchIcon} alt=""/>
                    </div>
                </div>
            </div>
            <div className="containerItemsCrypto">
                {listItems.filter(item => {
                    if (searchKey === '') {
                        return item;
                    } else if (item.name.includes(searchKey)) {
                        return item;
                    }
                }).map(coin => (
                    <div className='cryptoItem' onClick={() => selectCoins(coin.id)}>
                        <img src={coin.img} alt=""/>
                        <p>{coin.name}</p>
                    </div>
                ))}

            </div>
        </div>
    );
}

export default CryptoMenu;