export const exchangeReducer = (state = [
    { id: 0, coinName: 'بیت کوین', buyMode: true, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'در حال ارسال', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 1, coinName: 'بیت کوین', buyMode: false, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'در حال بررسی', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 2, coinName: 'بیت کوین', buyMode: true, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'در حال بررسی', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 3, coinName: 'بیت کوین', buyMode: true, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'در حال بررسی', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 4, coinName: 'بیت کوین', buyMode: false, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'انجام شده', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 5, coinName: 'بیت کوین', buyMode: true, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'انجام شده', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 6, coinName: 'بیت کوین', buyMode: true, price: 13234565656, value: 1325, total: 54644546545, net: 'TRC20', carNet: '0.004BTC', status: 'انجام شده', time: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
], action) => {
    switch (action.type) {
        default: return state
    }
}