import React from "react";
import dangerIcon from "../../assets/img/dangerIcon.svg";

const TextWithdrawDialog = () => {
    return (
        <>
            <div className='sectionDangerDialog'>
                <p> درخواست‌های تسویه ریالی در روزهای تعطیل به دلیل تعطیلی بانک‌ها و عدم فعالیت
                    سیستم پایا و
                    ساتنا انجام نمی شود .</p>
                <img src={dangerIcon} alt=""/>
            </div>
            <div className='sectionDangerDialog dddd'>
                <p>درخواست‌های تسویه بالای 100 میلیون تومان به دلیل محدودیت‌های بانکی حداکثر تا یک
                    روز آینده
                    کاری از زمان ثبت درخواست انجام خواهد شد .</p>
                <img src={dangerIcon} alt=""/>
            </div>
        </>
    )
}
export default TextWithdrawDialog