import React from 'react';
import postImage from './../../assets/img/slider/postImage.png'
import './postItem.scss'

const PostItem = () => {

    return (
        <div className='postItem'>
            <div className='insidePostItem'>
                <div className='containerImage'>
                    <img src={postImage} className='postImage' />
                </div>
                <p className='justify w-100 pInPostCard'>اولین روز ضد تورمی اتریوم، تاثیر کاهش اتریوم بر بازار چیست؟</p>
                <div className='rateInPost'>
                    <p>1400/06/23</p>
                    <p>57 بازدید</p>
                </div>
            </div>
        </div>
    );
}

export default PostItem;