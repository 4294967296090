import React, {useState} from 'react';
import {DialogOverlay, DialogContent} from '@reach/dialog';
import parse from 'html-react-parser'
import imgCopy from './../../assets/img/copy.svg'
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import {Formik} from 'formik';
import * as Yup from 'yup';

import './googleAuthen.scss'
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import {Spinner} from "reactstrap";
import {verifyGoogle2af} from "../../services/google2afService";
import {toast} from "react-toastify";
import CustomInput from "../../components/CustomInput/CustomInput";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../redux/actions/userActions";

const validationSchema = Yup.object().shape({
    code: Yup.string().required('پر کردن این فیلد الزامی می باشد').length(6, 'باید شیش کاراکتر باشد.')
})
const GoogleAuthen = ({showDialog, closeDialog, secretKet, qrImage}) => {

    const [isDisable, setIsDisable] = useState(false)
    const dispatch = useDispatch()

    const copyTextToClipboard = (text) => {
        if ('clipboard' in navigator) {
            toast.success('کد مورد نظر کپی شد.',{position:'top-right',closeOnClick:true})
            return navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handle = async (values) => {
        setIsDisable(true)
        console.log(values)
        try {
            const {data} = await verifyGoogle2af(values)
            if (data.type === 'success') {
                setIsDisable(false)
                dispatch(setUserInfo())
                toast(`${data.message}`,{position:'top-right',closeOnClick:true})
                closeDialog()
            }
            setIsDisable(false)
        } catch (ex) {
            setIsDisable(false)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
        }
        // closeDialog()
    }


    return (
        <DialogOverlay isOpen={showDialog} onDismiss={closeDialog} style={{background: "hsla(0,100%,100%,0.9)"}}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <div className='insideChangeDialog'>
                    <p className='titleGoogleModal'>لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن
                        نمایید.</p>
                    <div className="w-100 text-center">
                        {parse(qrImage)}
                    </div>
                    <Formik initialValues={{code: ''}} validationSchema={validationSchema}
                            onSubmit={(values) => handle(values)}>
                        {({handleSubmit, handleChange, values, errors,touched,setFieldTouched}) => (
                            <>
                                <div className='sectionSecretCode'>
                                    <div className='insideItemInvite '>
                                        <p>{secretKet}</p>
                                        <div>
                                            <p>کد امنیتی :</p>
                                            <img alt='' src={imgCopy} onClick={() => copyTextToClipboard(secretKet)}
                                                 className='imgCopyInvite'/>
                                        </div>
                                    </div>
                                </div>

                                <CustomInput inputType='tel' value={values.code}
                                             handleBlur={() => setFieldTouched('code')} marginBottom='18px'
                                             textAlignLeft={true}
                                             placeholder='کد شیش رقمی ارسال شده'
                                             handleChange={handleChange('code')}/>
                                <ErrorMessage error={errors.code} visible={touched.code}/>
                                <ButtonForm isDis={isDisable} funcClick={handleSubmit} marginBottom='16px'>
                                    {isDisable === false ? 'ثبت' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                 variant="secondary"/>
                                    }
                                </ButtonForm>
                            </>
                        )}
                    </Formik>

                </div>
            </DialogContent>
        </DialogOverlay>
    );
}

export default GoogleAuthen;