import React from 'react'
import {GoogleLogin} from 'react-google-login'
import ButtomGoogle from "../ButtomGoogle/ButtomGoogle";
import {sendCodeGoogle} from "../../services/googleService";
import {toast} from "react-toastify";
import axios from "axios";
import {changeRegisterMode} from "../../redux/actions/registerModeAction";
import {userLogin} from "../../redux/actions/loginActions";
import {getInviteDataRedux} from "../../redux/actions/inviteDataActions";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../redux/actions/userActions";
import {withRouter} from 'react-router-dom'


const GoogleAuthenticated = ({history}) => {

    const dispatch = useDispatch()
    const clientId = "179232625925-cd7ci5tnlhi4nuhmp3rajurg98snbh0e.apps.googleusercontent.com"

    const responseGoogle = (response) => {
        console.log(response);
    }
    const responseGoogleTrue = async (response) => {
        try {
            const {data} = await sendCodeGoogle(response)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                localStorage.setItem("token", data.data.token)
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
                dispatch(changeRegisterMode(false))
                dispatch(userLogin())
                dispatch(getInviteDataRedux())
                dispatch(setUserInfo())
                history.replace('/account')
            }
            console.log(data)
        } catch (ex) {
            console.log(ex)
        }
    }

    return (
        <div>
            <GoogleLogin clientId={clientId}
                         buttonText="Login"
                         render={renderProps => (
                             <ButtomGoogle handleClick={renderProps.onClick} title='ادامه با گوگل'/>)}
                         onSuccess={responseGoogleTrue}
                         onFailure={responseGoogle}
                         cookiePolicy='single_host_origin'
                         isSignedIn={false}
                         responseType='code'
                         accessType='offline'
                         prompt="select_account"
                         redirectUri='https://arztrading.com'
                         // redirectUri='https://arztrading.com/auth/google'
            />
        </div>
    )
}
export default withRouter(GoogleAuthenticated)


