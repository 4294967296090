import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AddDebitCard from '../AddDebitCard/AddDebitCard';
import DebitCardItem from './../DebitCardItem/DebitCardItem';
import { getBankAccount } from '../../services/bankAccountService';
import { getAccounts } from '../../redux/actions/bankAccountsAction';

import './insideBankInformation.scss'

const InsideBankInformation = () => {

    const bankAccounts = useSelector(state => state.bankAccounts)
    const dispatch = useDispatch()
    const [lengthListAccount, setLengthListAccount] = useState(0)

    useEffect(() => {
        dispatch(getAccounts())
        const getAllAcounntBank = async () => {
            const { data } = await getBankAccount()
            // setListAccount(data.data)
            let leArray = data.data.length
            setLengthListAccount(leArray)
            // console.log(data.data.length);
        }
        getAllAcounntBank()

    }, [])

    return (
        <div className='insideBankInformation'>
            <AddDebitCard />
            <div className='containerCardsItem'>
                {bankAccounts.length < 1 ?
                    <p className='mb-1'>هنوز کارتی ثبت نشده است!</p> :
                    bankAccounts.map((item,index) => (
                        <DebitCardItem key={index} cardNumber={item.card_number} sheba={item.sheba} status={item.status} id={item.id} bankName={item.bank_label} />
                    ))
                }
            </div>
        </div>
    );
}

export default InsideBankInformation;