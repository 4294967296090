import React from 'react'
import './customInput.scss'

const CustomInput = ({
                         inputType = 'text',
                         placeholder = '',
                         value,
                         marginBottom,
                         handleChange,
                         handleBlur,
                         textAlignLeft, number,disabled
                     }) => {
    return (
        <div className='containerCustomInput'>
            <input pattern={number ? "[0-9]*" : null}
                   disabled={disabled}
                   value={value}
                   className={textAlignLeft && (value !== '') ? 'textAlignLeft' : null} onChange={handleChange}
                   type={inputType} onBlur={handleBlur} placeholder={placeholder} style={{marginBottom}}/>
            {value !== '' ? <span className='spanHolder'>{placeholder}</span> : null}
        </div>
    )
}

export default CustomInput