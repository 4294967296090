import config from './config.json'

import http from './httpService'

export const getNotifications = () => {
    return http.get(`${config.coinbaseapi}/notifications`)
}

export const setViewNotification = id => {
    return http.patch(`${config.coinbaseapi}/notifications/${id}`)
}