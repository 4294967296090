import config from './config.json'

import http from './httpService'

// export const getCountUsers = () =>{
//     return Promise.all([
//         http.get(`${config.coinbaseapi}/users/count`),
//         http.get(`${config.coinbaseapi}/orders/count`),
//         http.get(`${config.coinbaseapi}/orders/volume`),
//     ])
// }

export const getCountUsers = () => {
    return http.get(`${config.coinbaseapi}/users/count`)
}

export const getCountOrders = () => {
    return http.get(`${config.coinbaseapi}/orders/count`)
}

export const getOrdersVolume = () => {
    return http.get(`${config.coinbaseapi}/orders/volume`)
}