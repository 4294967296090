import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './markets.scss'
import { floorNumber, numberWithCommas } from './../../utils/Price';
import DialogBuySell from '../../dialogs/DialogBuySell/DialogBuySell';
import { sellMode, buyMode } from './../../redux/actions/sellBuyAcions';
import { setIdCoin } from './../../redux/actions/selectCoinId';
import ChartTest from './../../components/TableHome/ChartTest';
import { withRouter } from 'react-router-dom'
import { setCoin } from "../../redux/actions/marketCoinsActions";
import { setMarketId } from "../../redux/actions/marketIdAction";
import { toFixedDigit } from "../../utils/helperFunction";
import Pagination from "../../components/Pgination/Pagination";
import { paginate } from "../../utils/paginate";

const Markets = ({ history }) => {

    useEffect(() => {
        document.title = 'صرافی ارز تریدینگ  || رمزارزها'
    })

    const [dialogBuySell, setDialogBuySell] = useState(false)

    const login = useSelector(state => state.login)
    const user = useSelector(state => state.user)
    const { rank_correction_factor } = user
    const marketCoins = useSelector(state => state.marketCoins)
    const realData = useSelector(state => state.readData)
    const weeklyPrice = useSelector(state => state.weeklyPrice)
    const [mobileMode, setMobileMode] = useState(window.innerWidth < 576 ? true : false);
    const [perPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const archiveCourses = paginate(marketCoins, currentPage, perPage);
    const handlePageChange = page => {
        setCurrentPage(page)
    }

    const dispatch = useDispatch()

    const openDialog = (typeSell, idCoin) => {
        if (login) {
            dispatch(setIdCoin(idCoin))
            dispatch(setCoin(idCoin))
            dispatch(setMarketId(idCoin))
            const str = typeSell.toString()
            if (str === 'sell') {
                dispatch(sellMode())
            } else {
                dispatch(buyMode())
            }
            setDialogBuySell(true)
        } else {
            history.push('/login')
        }
    }

    const closeDialog = () => setDialogBuySell(false)

    const changeColor = str => {
        let str2 = str ? str.toString() : ''

        var result = str2.indexOf('-')
        if (result > -1) {
            return 'colorRed text-center'
        } else {
            return 'colorGreen text-center'
        }
    }

    const getPercentChange = str => {
        const res = realData.find(rd => rd.coin === str)
        return (res?.percentChange)
    }
    const dataChart = (str) => {
        const res = weeklyPrice.find(rd => rd.coin.code === str)
        return res?.prices;
    }


    const getPriceBuy = coinStr => {
        const res = realData.find(rd => rd.coin === coinStr)
        if (!login) {
            return (res?.tomanBuying)
        } else {
            const zarib = rank_correction_factor[coinStr]
            return (res?.tomanBuying * zarib[0])
        }
    }
    const getPriceSell = coinStr => {
        const res = realData.find(rd => rd.coin === coinStr)
        if (!login) {
            return (res?.tomanSelling)
        } else {
            const zarib = rank_correction_factor[coinStr]
            return (res?.tomanSelling * zarib[1])
        }
    }
    const handleWindowSizeChange = () => {
        if (window.innerWidth < 576) {
            setMobileMode(true)
        } else {
            setMobileMode(false)
        }
    }
    useEffect(() => {
        console.log(rank_correction_factor);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return (
        <section className='markets'>
            <DialogBuySell showDialog={dialogBuySell} closeDialog={closeDialog} />
            <p className='titleInMarkets'>رمزارزها</p>
            <div className='containerTableMarkets'>
                <table className='table table-borderless marketTable'>
                    <thead>
                        <tr className='trHeadMarket'>
                            <td className='noWrapText theadMarkets'>رمزارز</td>
                            <td className='noWrapText theadMarkets text-center'>تغییرات</td>
                            {mobileMode ? <td className='noWrapText theadMarkets text-center'>قیمت معامله</td> : null}
                            <td className='noWrapText theadMarkets text-center dontShowMobile'>قیمت خرید از ما <span
                                className='spanTiny'>(تومان)</span></td>
                            <td className='noWrapText theadMarkets text-center dontShowMobile'>قیمت فروش به ما <span
                                className='spanTiny'>(تومان)</span></td>
                            <td className='noWrapText theadMarkets text-center dontShowMobile'>نمودار</td>
                            <td className='noWrapText theadMarkets text-lefttable dontShowMobile'>عملیات</td>
                            {mobileMode ? <td className='theadMarkets text-center noWrapText '>عملیات</td> : null}
                        </tr>
                    </thead>
                    <tbody className='bodyMarket'>
                        <div className='lineIntoBody'></div>
                        {archiveCourses.map((coin, index) => (

                            <tr key={index} className='trIntoMarket'>
                                <td className='noWrapText valueBodyMarkets'>
                                    <div className='nameImagearket'>
                                        <img src={coin.img} alt="" />
                                        <span>{coin.name}</span>
                                    </div>
                                </td>
                                <td className={changeColor(getPercentChange(coin.coin))}>{floorNumber(getPercentChange(coin.coin))}</td>
                                {mobileMode ? <td className='noWrapText valueBodyMarkets text-center'>
                                    <div className='nameImagearket'>
                                        <p>خرید: {numberWithCommas(toFixedDigit(getPriceBuy(coin.coin)))}</p>
                                        <p>فروش: {numberWithCommas(toFixedDigit(getPriceSell(coin.coin)))}</p>
                                    </div>
                                </td> : null}
                                <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{numberWithCommas(toFixedDigit(getPriceBuy(coin.coin)))}</td>
                                <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{numberWithCommas(toFixedDigit(getPriceSell(coin.coin)))}</td>
                                <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{dataChart(coin.coin) &&
                                    <ChartTest objData={dataChart(coin.coin)} />}</td>
                                <td className='noWrapText dontShowMobile'>
                                    <div className='sellBuTable'>
                                        <div className="w-100">
                                            <div className='row'>
                                                <div className="w-50 col-6 buyInTable boxInTable"><p onClick={() => {
                                                    openDialog('sell', coin.id)
                                                }} className='pInSellBuyTable'>فروش</p></div>
                                                <div className="w-50 col-6 sellInTable boxInTable"><p onClick={() => {
                                                    openDialog('buy', coin.id)
                                                }} className='pInSellBuyTable'>خرید</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {mobileMode ? <td className='noWrapText valueBodyMarkets  text-center'>
                                    <button className='btn btn-sm btn-outline-primary'
                                        onClick={() => openDialog('buy', coin.id)}>معامله
                                    </button>
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination totalCourse={marketCoins.length} currentPage={currentPage} perPage={perPage} marginTop='-60px'
                onPageChange={handlePageChange} />
        </section>
    );
}

export default withRouter(Markets);