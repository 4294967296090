import React, {useEffect, useState} from 'react';

import Features from './../../components/Features/Features';
import Info from './../../components/Info/Info';
import parse from "html-react-parser";

import './aboutUs.scss'
import {getPageContent} from "../../services/pagesService";
import Loading from "../../components/Loading/Loading";

const AboutUs = () => {

    const [aboutUs, setAboutUs] = useState('')
    const [loading, setLoading] = useState(false)

    const getDataAboutUs = async () => {
        setLoading(true)
        try {
            const {data} = await getPageContent('about_us')
            setAboutUs(data.data[0].content)
            setLoading(false)
        } catch (ex) {
            console.log(ex)
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'صرافی ارز تریدینگ  || درباره ما'
        getDataAboutUs()
    },[])
    return (
        <section className='aboutUs'>
            <p className='titleHomePage'>درباره صرافی <span className='spanTitleAboutUs'>ویستا</span></p>
            <div className='insideAboutUs'>
                {loading ? <Loading/> : <p className='pTopAboutUs'>{parse(aboutUs)}</p>}
            </div>
            <Features/>
            <Info marginBottom='56px'/>
            {/*<div className='insideAboutUs'>*/}
            {/*    <p className='pBottomAboutUs'>*/}
            {/*    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.*/}
            {/*    </p>*/}
            {/*</div>*/}

        </section>
    );
}

export default AboutUs;