import React from 'react';
import SetterEmail from '../SetterEmail/SetterEmail';
import './rightTopSectionHome.scss'

const RightTopSectionHome = () => {
    return (
        <>
            <p className='mainTitleRightTopHoem'>صرافی ارز تریدینگ</p>
            <p className='subTitleRightTopHoem'>خرید و فروش پر معامله ترین ارزهای دیجیتال در بستری امن و آسان</p>
            <SetterEmail />
        </>
    );
}

export default RightTopSectionHome;