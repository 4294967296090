import React from 'react';
import './errorMessage.scss'

const ErrorMessage = ({ error, visible ,marginTop}) => {

    if (!visible || !error) return null
    return (
        <p className='errorMessage' style={{marginTop:marginTop}} >{error}</p>
    );
}

export default ErrorMessage;