import React from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import {useState} from 'react';
import {Spinner} from 'reactstrap';
import ButtonForm from './../../components/ButtomForm/ButtomForm';

import {Formik} from 'formik'
import * as Yup from 'yup'
import {editBankAccount} from '../../services/bankAccountService';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {getAccounts} from './../../redux/actions/bankAccountsAction';
import CustomInput from "../../components/CustomInput/CustomInput";
import InputCardNumber from "../../components/InputCardNumber/InputCardNumber";

const validationSchema = Yup.object().shape({
        sheba: Yup.string()
            .required('پر کردن این فیلد الزامی می باشد.')
            .matches(/^[0-9]+$/, "فقط باید شامل اعداد باشد.")
            .min(24, 'نباید کمتر از 24 کراکتر باشد')
            .max(24, 'نباید بیشتر از 24 کاراکتر باشد'),
    card_number: Yup.string()
        .required('پر کردن این فیلد الزامی می باشد.')
        .matches(/^[0-9]+$/, "فقط باید شامل اعداد باشد.")
        .min(16, 'نباید کمتر از 16 کراکتر باشد')
        .max(16, 'نباید بیشتر از 16 کاراکتر باشد')
})

const EditBankAccount = ({showDialog, closeDialog, id, sheba, card_number}) => {

    const [isDisable, setIsDisable] = useState(false)
    const dispatch = useDispatch()

    const handleEdit = async (values) => {
        setIsDisable(true)
        try {
            const {data} = await editBankAccount(id, values)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right'})
                dispatch(getAccounts())
                setIsDisable(false)
                closeDialog()
            }
        } catch (ex) {
            console.log(ex);
            setIsDisable(false)
        }
    }

    return (
        <DialogOverlay onDismiss={closeDialog} isOpen={showDialog}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <Formik
                    initialValues={{card_number: card_number, sheba: sheba}}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleEdit(values)}
                >
                    {({values, handleChange, handleSubmit, errors, setFieldTouched, touched}) => (
                        <div className='insideDeposit'>
                            <p className='text-center mb-3'>ویرایش کارت بانکی</p>
                            <div className='conInModal'>
                                <InputCardNumber caption={true} defaultlValue={values.card_number}  placeholder='شماره کارت' onChange={handleChange('card_number')}
                                             onBlur={() => setFieldTouched('card_number')} marginBottom='18px'/>
                                <ErrorMessage error={errors.card_number} visible={touched.card_number}/>
                            </div>
                            <div className='conInModal'>
                                <CustomInput number={true} value={values.sheba} textAlignLeft={true} inputType='text'
                                             placeholder='شماره شبا' handleChange={handleChange('sheba')}
                                             handleBlur={() => setFieldTouched('sheba')} marginBottom='18px'/>

                                <ErrorMessage error={errors.sheba} visible={touched.sheba}/>
                            </div>
                            <ButtonForm isDis={isDisable} funcClick={handleSubmit} marginBottom='24px'>
                                {isDisable === false ? 'ویرایش' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm>
                        </div>
                    )}
                </Formik>
            </DialogContent>
        </DialogOverlay>
    );
}
export default EditBankAccount;