import {combineReducers} from "redux";
import {sellBuyReducer} from './SellBuyReducer';
import {allCoinsReducer} from './allCoins';
import {selectCoinReducer} from './selectCoinReducer';
import {loginReducer} from './loginReducer';
import {entriesReducer} from './entriesReducer';
import {ordersReducer} from './ordersReducer';
import {bankingOperationsReducer} from './bankingOperationsReducer';
import {exchangeReducer} from './exchangeReducer';
import {cardsReducer} from './cardsReducer';
import {sixDigitReducer} from './sixDigit';
import {registerModeReducer} from './RegisterModeReducer';
import {userInfo} from './userReducer';
import {acceptedAccountsReducer, bankAccountsReducer} from './bankAccounts';
import {inviteDataReducer} from './inviteDataReducer';
import {optionsReducer} from "./optionsReducer";
import {transactionsCryptoReducer, transactionsReducer} from "./transactionsReducer";
import {coinsForOrdersReducer} from "./coinsForOrders";
import {coinsFilterOrderReducer} from "./coinsFilterOrder";
import {listCardNumnerReducer} from "./listCardNumnerReducer";
import {featuresReducer} from "./featuresReducer";
import {walletReducer} from "./walletReducer";
import {networkCoinReducer} from "./networkCoinReducer";
import {listNetworkReducer} from "./listNetworkReducer";
import {addressReducer} from "./addressReducer";
import {coinReducer, marketCoinsReducer} from "./marketCoinsReducer";
import {marketIdReducer} from "./marketIdReducer";
import {realDataReducer} from "./realDataReducer";
import {weeklyPriceReducer} from "./weeklyPriceReducer";
import {
    historyLogin,
    historyLoginReducer, historyOrdersReducer,
    historyTransactionCryptoReducer, historyTransactionTomanReducer,
    metaLoginReducer, metaOrdersReducer, metaTransactionCryptoReducer, metaTransactionTomanReducer
} from "./historiesReducers";


export const reducer = combineReducers({
    buy: sellBuyReducer,
    coins: allCoinsReducer,
    coinId: selectCoinReducer,
    login: loginReducer,
    entries: entriesReducer,
    orders: ordersReducer,
    bankingOperation: bankingOperationsReducer,
    exchanges: exchangeReducer,
    cards: cardsReducer,
    sixDigit: sixDigitReducer,
    registerMode: registerModeReducer,
    user: userInfo,
    bankAccounts: bankAccountsReducer,
    inviteData: inviteDataReducer,
    options: optionsReducer,
    transactions: transactionsReducer,
    coinsForOrders: coinsForOrdersReducer,
    listCoinsFilterOrder: coinsFilterOrderReducer,
    listCardNumber: listCardNumnerReducer,
    info: featuresReducer,
    wallets: walletReducer,
    networkCoin: networkCoinReducer,
    listNetwork: listNetworkReducer,
    address: addressReducer,
    marketCoins: marketCoinsReducer,
    coin: coinReducer,
    marketId: marketIdReducer,
    readData: realDataReducer,
    weeklyPrice: weeklyPriceReducer,
    acceptedAccounts: acceptedAccountsReducer,
    transactionsCrypto: transactionsCryptoReducer,
    metaLogin: metaLoginReducer,
    historyLogin: historyLoginReducer,
    historyTransactionCrypto: historyTransactionCryptoReducer,
    metaTransactionCrypto: metaTransactionCryptoReducer,
    historyTransactionToman: historyTransactionTomanReducer,
    metaTransactionToman: metaTransactionTomanReducer,
    historyOrders:historyOrdersReducer,
    metaOrders:metaOrdersReducer
})