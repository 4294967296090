import React from 'react';
import './cartTable.scss'

const CartTable = ({ children, title }) => {
    return (
        <div className='cartTable'>
            <div className="insideCardTable">
                <p>{title}</p>
                <div className='parentTable'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CartTable;