import React, {useEffect, useState} from 'react'
import './returnPage.scss'
import {getReportTransaction} from "../../services/transactionService";
import {withRouter} from 'react-router-dom'
import {toast} from "react-toastify";
import axios from "axios";
import {numberWithCommas} from "../../utils/Price";

const ReturnPage = ({match, history}) => {

    const [report, setReport] = useState({})

    const handle = async () => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

            const {data} = await getReportTransaction(match.params.id)
            // console.log(res)
            if (data.type === 'success') {
                console.log(data.data)
                setReport(data.data)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
            } else {
                toast.error(`${data.message}`, {position: "bottom-right", closeOnClick: true})
            }
        } catch (ex) {
            toast.error(`${ex.data.message}`, {position: "bottom-right", closeOnClick: true})
        }

    }
    useEffect(() => {
        handle()
    }, [])
    return (
        <div className='pageReturn'>
            <div className='insidePageReturn'>
                <p className='titleReturnPage'>گزارش پرداخت</p>

                <div className='divReport'>
                    <p className='valueReport'>{report.payment ? report.payment.token : '-'}</p>
                    <p className='keyReport'>شناسه تراکنش : </p>
                </div>
                <div className='divReport'>
                    <p className='valueReport'>{report.payment ? report.payment.ref_number : '-'}</p>
                    <p className='keyReport'>شماره ارجاع : </p>
                </div>
                <div className='divReport'>
                    <p className='valueReport'>{numberWithCommas(report.amount)}</p>
                    <p className='keyReport'>مبلغ : </p>
                </div>
                <div className='divReport'>
                    <p className='valueReport'>{report.status === "ACCEPTED" ? 'موفقیت آمیز' : 'ناموفق'}</p>
                    <p className='keyReport'>وضعیت پرداخت : </p>
                </div>

                <div className='divReport'>
                    <p className='valueReport'>{report.account ? report.account.bank_label : '-'}</p>
                    <p className='keyReport'>شماره کارت : </p>
                </div>
                <div className='divReport'>
                    <p className='valueReport'>{report.account ? report.account.card_number : '-'}</p>
                    <p className='keyReport'>بانک درخواست کننده : </p>
                </div>
                <div className='divReport'>
                    <p className='valueReport'>{report.account ? report.account.sheba : '-'}</p>
                    <p className='keyReport'>شماره شبا : </p>
                </div>
               <div className='buttonsReport'>
                   <div className='insideButtonsReport'>
                       <button className='btn btn-success' onClick={() => history.replace('/')}>صفحه اصلی</button>
                       <button className='btn btn-success' onClick={() => history.replace('/account')}>داشبورد</button>
                   </div>

               </div>
            </div>

        </div>
    )
}
export default withRouter(ReturnPage)