import React from 'react';
import './topHome.scss'
import RightTopSectionHome from './../RightTopSectionHome/RightTopSectionHome';
import SellBuy from '../SellBuy/SellBuy';

const TopHome = () => {
    return (
        <div className='topHome'>
            <div className="flexTop">
                    <div className='leftTopSectionHome'>
                        <SellBuy />
                    </div>
                    <div className='rightTopSectionHome'>
                        <RightTopSectionHome />
                    </div>
            </div>
        </div>
    );
}

export default TopHome;