import React from 'react';
import './profileItem.scss'

const ProfileItem = ({ title, children }) => {
    return (
        <div className='profileItem'>
            <div className='insideProfileItem'>
                <p className='titleProfileItem'>{title}</p>
                {children}
            </div>
        </div>
    );
}

export default ProfileItem;