import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { floorNumber, numberWithCommas } from './../../utils/Price';

import './tableHome.scss'
import ChartTest from './ChartTest';
import { buyMode, sellMode } from '../../redux/actions/sellBuyAcions';
import { setIdCoin } from '../../redux/actions/selectCoinId';
import DialogBuySell from '../../dialogs/DialogBuySell/DialogBuySell';
import { setCoin } from "../../redux/actions/marketCoinsActions";
import { setMarketId } from "../../redux/actions/marketIdAction";
import { withRouter } from 'react-router-dom'
import { toFixedDigit } from "../../utils/helperFunction";
import Pagination from "../Pgination/Pagination";
import { paginate } from "../../utils/paginate";

const TableHome = ({ history, inMarketCoins }) => {
    const [dialogBuySell, setDialogBuySell] = useState(false)
    const [mobileMode, setMobileMode] = useState(window.innerWidth < 576 ? true : false);
    const login = useSelector(state => state.login)
    const user = useSelector(state => state.user)
    const { rank_correction_factor } = user
    const marketCoins = useSelector(state => state.marketCoins)
    const realData = useSelector(state => state.readData)
    const weeklyPrice = useSelector(state => state.weeklyPrice)

    const [perPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const archiveCourses = paginate(marketCoins, currentPage, perPage);
    const dispatch = useDispatch()

    const handlePageChange = page => {
        setCurrentPage(page)
    }

    const dataChart = (str) => {
        const res = weeklyPrice.find(rd => rd.coin.code === str)
        return res?.prices;
    }

    const handleWindowSizeChange = () => {
        if (window.innerWidth < 576) {
            setMobileMode(true)
        } else {
            setMobileMode(false)
        }
    }

    const openDialog = (typeSell, idCoin) => {
        if (login) {
            dispatch(setIdCoin(idCoin))
            dispatch(setCoin(idCoin))
            dispatch(setMarketId(idCoin))
            const str = typeSell.toString()
            if (str === 'sell') {
                dispatch(sellMode())
            } else {
                dispatch(buyMode())
            }
            setDialogBuySell(true)
        } else {
            history.push('/login')
        }
    }

    const closeDialog = () => setDialogBuySell(false)

    const changeColor = str => {
        let str2 = str ? str.toString() : ''
        var result = str2.indexOf('-')
        if (result > -1) {
            return 'colorRed text-center'
        } else {
            return 'colorGreen text-center'
        }
    }

    const getPercentChange = str => {
        const res = realData.find(rd => rd.coin === str)
        return (res?.percentChange)
    }

    const getPriceBuy = coinStr => {
        const res = realData.find(rd => rd.coin === coinStr)
        // return (res?.tomanBuying)
        if (!login) {
            return (res?.tomanBuying)
        } else {
            const zarib = rank_correction_factor[coinStr]
            return (res?.tomanBuying * zarib[0])
        }
    }
    const getPriceSell = coinStr => {
        const res = realData.find(rd => rd.coin === coinStr)
        // return (res?.tomanSelling)
        if (!login) {
            return (res?.tomanSelling)
        } else {
            const zarib = rank_correction_factor[coinStr]
            return (res?.tomanSelling * zarib[1])
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <section className='tableHome'>
            <DialogBuySell showDialog={dialogBuySell} closeDialog={closeDialog} />
            <div className="insideTableHome">
                <div className='containerTableMarkets tblHome tttt'>
                    <table dir='rtl' className='table table-responsive table-borderless'>
                        {/*<table dir='rtl' className='table-borderless'>*/}
                        <thead>
                            <tr className='w-100 rowHeaderTable'>
                                <td className='noWrapText theadMarkets'>رمزارز</td>
                                <td className='noWrapText theadMarkets text-center'>تغییرات</td>
                                {/*{window.innerWidth<576?<td className='noWrapText theadMarkets text-center'>قیمت معامله</td>:null}*/}
                                {mobileMode ? <td className='noWrapText theadMarkets text-center'>قیمت معامله</td> : null}
                                <td className='noWrapText theadMarkets dontShowMobile text-center'>قیمت خرید از ما <span
                                    className='spanTiny'>(تومان)</span></td>
                                <td className='noWrapText theadMarkets dontShowMobile text-center'>قیمت فروش به ما <span
                                    className='spanTiny theadMarkets'>(تومان)</span></td>
                                <td className='noWrapText column dontShowMobile theadMarkets text-center'>نمودار</td>
                                <td className='noWrapText  theadMarkets text-center dontShowMobile'>عملیات</td>
                                {/*{window.innerWidth<576?<td className='theadMarkets text-center noWrapText '>عملیات</td>:null}*/}
                                {mobileMode ? <td className='theadMarkets text-center noWrapText '>عملیات</td> : null}

                            </tr>
                        </thead>
                        <tbody className='bodyMarket'>
                            <div className='lineIntoBody'></div>

                            {/*{marketCoins.map((coin,index) => (*/}
                            {/*{inMarketCoins.map((coin, index) => (*/}
                            {archiveCourses.map((coin, index) => (
                                <tr key={index} className='trIntoMarket w-100 '>
                                    <td className='noWrapText valueBodyMarkets'>
                                        <div className='nameImagearket'>
                                            <img src={coin.img} alt="" />
                                            <span>{coin.name}</span>
                                        </div>
                                    </td>
                                    <td className={changeColor(getPercentChange(coin.coin))}>{floorNumber(getPercentChange(coin.coin))}</td>
                                    {/*{window.innerWidth<576?<td className='noWrapText valueBodyMarkets text-center'>*/}
                                    {mobileMode ? <td className='noWrapText valueBodyMarkets text-center'>
                                        <div className='nameImagearket'>
                                            <p>خرید: {numberWithCommas(toFixedDigit(getPriceBuy(coin.coin)))}</p>
                                            <p>فروش: {numberWithCommas(toFixedDigit(getPriceSell(coin.coin)))}</p>
                                        </div>
                                    </td> : null}
                                    {/*<td className='noWrapText valueBodyMarkets showInMobile text-center'>معامله</td>*/}


                                    <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{numberWithCommas(toFixedDigit(getPriceBuy(coin.coin)))}</td>
                                    <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{numberWithCommas(toFixedDigit(getPriceSell(coin.coin)))}</td>
                                    <td className='noWrapText valueBodyMarkets dontShowMobile text-center'>{dataChart(coin.coin) &&
                                        <ChartTest objData={dataChart(coin.coin)} />}</td>

                                    <td className='noWrapText dontShowMobile'>

                                        <div className='sellBuTable'>
                                            <div className="w-100">
                                                <div className='row'>
                                                    <div className="w-50 col-6 buyInTable boxInTable"><p
                                                        onClick={() => {
                                                            openDialog('sell', coin.id)
                                                        }} className='pInSellBuyTable'>فروش</p></div>
                                                    <div className="w-50 col-6 sellInTable boxInTable"><p
                                                        onClick={() => {
                                                            openDialog('buy', coin.id)
                                                        }} className='pInSellBuyTable'>خرید</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {/*{window.innerWidth<576?<td className='noWrapText valueBodyMarkets  text-center'>*/}
                                    {mobileMode ? <td className='noWrapText valueBodyMarkets  text-center'>
                                        <button className='btn btn-sm btn-outline-primary'
                                            onClick={() => openDialog('buy', coin.id)}>معامله
                                        </button>
                                    </td> : null}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
            <Pagination totalCourse={marketCoins.length} marginTop='20px' currentPage={currentPage} perPage={perPage}
                onPageChange={handlePageChange} />
        </section>
    );
}

export default withRouter(TableHome);
