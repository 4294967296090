import React, {useEffect} from 'react';

import TopHome from '../../components/TopHome/TopHome';
import Info from './../../components/Info/Info';
import Features from '../../components/Features/Features';
import Post from '../../components/Post/Post';
import TableHome from '../../components/TableHome/TableHome';
import {setWeeklyPrice} from "../../redux/actions/weeklyPriceAction";
import {useDispatch} from "react-redux";
import {setCoin} from "../../redux/actions/marketCoinsActions";
import {setMarketId} from "../../redux/actions/marketIdAction";
import {setIdCoin} from "../../redux/actions/selectCoinId";
import './home.scss'

const Home = ({inMarketCoins}) => {

    const dispatch = useDispatch()

    const initial = () => {
        dispatch(setIdCoin(1))
        dispatch(setCoin(1))
        dispatch(setMarketId(1))
    }
    useEffect(() => {
        document.title = 'صرافی ارز تریدینگ  || صفحه اصلی'
        dispatch(setWeeklyPrice())
        initial()

    }, [])
    return (
        <div className='home'>
            <TopHome/>
            <TableHome inMarketCoins={inMarketCoins}/>
            <Features/>
            <Info/>
            <Post/>
        </div>);
}
export default Home;