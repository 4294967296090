export const historyLoginReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_HISTORY_LOGIN':
            return [...action.payload]
        default:
            return state
    }
}

export const metaLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INIT_META_LOGIN':
            return {...action.payload}
        default:
            return state
    }
}

export const historyTransactionCryptoReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_HISTORY_TRANSACTION_CRYPTO':
            return [...action.payload]
        default:
            return state
    }
}
export const metaTransactionCryptoReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INIT_META_TRANSACTION_CRYPTO':
            return {...action.payload}
        default:
            return state
    }
}

export const historyTransactionTomanReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_HISTORY_TRANSACTION_TOMAN':
            return [...action.payload]
        default:
            return state
    }
}
export const metaTransactionTomanReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INIT_META_TRANSACTION_TOMAN':
            return {...action.payload}
        default:
            return state
    }
}

export const historyOrdersReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_HISTORY_ORDERS':
            return [...action.payload]
        default:
            return state
    }
}
export const metaOrdersReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INIT_META_ORDERS':
            return {...action.payload}
        default:
            return state
    }
}