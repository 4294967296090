import React, {useEffect,useState} from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import {Spinner} from 'reactstrap';
import ButtonForm from './../../components/ButtomForm/ButtomForm';

import {Formik} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import CustomInput from "../../components/CustomInput/CustomInput";
import {confirmCodeFinoTech, sendCodeFinoTech} from "../../services/finoTechService";
import {setUserInfo} from "../../redux/actions/userActions";
import ShowTimer from "../../components/ShowTimer/ShowTimer";

const validationSchema = Yup.object().shape({
    code: Yup.string().required('پر کردن این فیلد الزامی می باشد.').length(5, 'کد باید شامل 5 کاراکتر باشد')
})

const FinoTechDialog = ({showDialog, closeDialog, national_code, mobile}) => {

    const [isDisable, setIsDisable] = useState(false)
    const [sendAgain, setSendAgain] = useState(false)
    const dispatch = useDispatch()

    const handleSendAgain = async () => {
        setIsDisable(true)
        try {
            const {data} = await sendCodeFinoTech()
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setSendAgain(false)
                setIsDisable(false)
            }
        } catch (ex) {
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            console.log(ex);
            setIsDisable(false)
        }
    }

    const handleCodeConfirm = async values => {
        try {
            setIsDisable(true)
            const {data} = await confirmCodeFinoTech(values)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                dispatch(setUserInfo())
                closeDialog()
                setIsDisable(false)
            }
        } catch (ex) {
            console.log(ex)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            setIsDisable(false)
        }
    }

    useEffect(() => {
        setSendAgain(false)
    }, [showDialog])

    return (
        <DialogOverlay onDismiss={closeDialog} isOpen={showDialog}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <Formik
                    initialValues={{code: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleCodeConfirm(values)}
                >
                    {({values, handleChange, handleSubmit, errors, setFieldTouched, touched}) => (
                        <div className='insideDeposit'>
                            <p className='text-center mb-3'>تاییدیه فینوتک</p>
                            <div className='conInModal'>
                                <CustomInput value={mobile} textAlignLeft={true} disabled={true}
                                             placeholder='شماره موبایل'
                                             marginBottom='18px'/>

                            </div>
                            <div className='conInModal'>
                                <CustomInput value={national_code} textAlignLeft={true} disabled={true}
                                             placeholder='کد ملی'
                                             marginBottom='18px'/>
                            </div>
                            {/*<p className='pTime mt-1'><span>{getMinout}</span>:<span>{getSecond}</span></p>*/}
                            {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                            <div className='conInModal'>
                                <CustomInput number={true} value={values.code} textAlignLeft={true}
                                             placeholder='کد ارسال شده به شماره' handleChange={handleChange('code')}
                                             handleBlur={() => setFieldTouched('code')} marginBottom='18px'/>

                                <ErrorMessage error={errors.sheba} visible={touched.sheba}/>
                            </div>
                            {!sendAgain ? <ButtonForm isDis={isDisable} funcClick={handleSubmit}>
                                {isDisable === false ? 'بررسی کد' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm> : null}
                            {sendAgain ? <ButtonForm isDis={isDisable} funcClick={() => handleSendAgain()}>
                                {isDisable === false ? 'ارسال مجدد کد' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm> : null}
                        </div>
                    )}
                </Formik>
            </DialogContent>
        </DialogOverlay>
    );
}
export default FinoTechDialog;