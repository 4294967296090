import React from 'react';
import './notFound.scss'
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='notFound'>
            <p>صفحه ی مورد نظر پیدا نشد!!!</p>
            <div>
                <NavLink className='ntFoundLink' to='/'>رفتن به صفحه اصلی</NavLink>
            </div>
        </div>
    );
}

export default NotFound;