export const bankingOperationsReducer = (state = [
    { id: 0, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 1, operation: 'برداشت', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 2, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 3, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 4, operation: 'برداشت', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: false, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 5, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 6, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 7, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 8, operation: 'برداشت', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: false, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' },
    { id: 9, operation: 'واریز', value: '985252222', IR: 'IR-۳۴۶۲۸۷۶۴۳۹۴۸۲۰۳۴۸۹۳۸۹۸۴', status: true, tracking: '88574', date: '۱۴۰۰/۰۲/۰۶ - ۱۴:۲۵' }
], action) => {
    switch (action.type) {
        default: return state
    }
}