import React, {useState, useEffect} from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import {Formik} from 'formik'
import * as Yup from 'yup'

import ButtonForm from './../../components/ButtomForm/ButtomForm';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {loginUser} from '../../services/userService';
import {toast} from 'react-toastify';
import {Spinner} from 'reactstrap';
import LoginConfirm from './LoginConfirm';
import CustomInput from "../../components/CustomInput/CustomInput";
import './login.css'
import GoogleAuthen from "../../dialogs/GoogleAuthen/GoogleAuthen";
import GoogleAuthenticated from "../../components/GoogleAuthenticated/GoogleAuthenticated";


const validationSchema = Yup.object().shape({
    email: Yup.string().email('ایمیل وارد شده معتبر نمی باشد.').required('پر کردن این فیلد الزامی می باشد.'),
    password: Yup.string().required('پر کردن این فیلد الزامی می باشد.').matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "باید حداقل شامل 8 کاراکتر و حروف کوچک و بزرگ و اعداد باشد"
    ),
})

const Login = (props) => {

    const [isDisable, setIsDisable] = useState(false)
    const [loginMode, setLoginMode] = useState(true)
    const [method, setMethod] = useState('')

    const handleLogin = async values => {
        setIsDisable(true)
        try {
            const {data} = await loginUser(values)
            setMethod(data.data.method)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setIsDisable(false)
                setLoginMode(false)
            }
        } catch (ex) {
            toast.error(`${ex.data.message}`, {position: 'bottom-right'})
            setIsDisable(false)
        }
    }

    useEffect(() => {
        document.title = 'صرافی کوین بیس || ورود به سایت'
    })


    return (
        <Formik
            initialValues={{email: '', password: ''}}
            validationSchema={validationSchema}
            onSubmit={values => handleLogin(values)}>
            {({handleChange, values, handleSubmit, errors, setFieldTouched, touched}) => (
        <>
            {loginMode ?
                <div className='login'>
                    <div className="containerLogin">
                        <p className='titleInForm'>ورود</p>
                        <div className="insideForm">


                                    <>
                                        <CustomInput inputType='email' placeholder='آدرس ایمیل' marginBottom='18px'
                                                     textAlignLeft={true} handleChange={handleChange('email')}
                                                     handleBlur={() => setFieldTouched('email')}
                                                     value={values.email}/>
                                        <ErrorMessage error={errors.email} visible={touched.email}/>

                                        <CustomInput inputType='password' textAlignLeft={true} placeholder='رمز عبور'
                                                     handleChange={handleChange('password')} value={values.password}
                                                     handleBlur={() => setFieldTouched('password')} marginBottom='16px'
                                        />
                                        <ErrorMessage error={errors.password} visible={touched.password}/>
                                        <p onClick={() => props.history.push('/forgotpassword')}
                                           className='pForget'>فراموشی رمز عبور</p>
                                        <ButtonForm funcClick={handleSubmit}  marginBottom={37}>
                                            {isDisable === false ? 'ثبت' :
                                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                         variant="secondary"/>
                                            }
                                        </ButtonForm>
                                        <div className='insideFormHR'>
                                            <div className='hrForm'/>
                                            <span className='spanHR'>یا</span>
                                        </div>
                                        {/*<ButtomGoogle title='ادامه با گوگل'/>*/}
                                        <GoogleAuthenticated/>

                                    </>

                            <p className='questionRegister'>حساب کاربری ندارید؟ <NavLink to='/register'>ثبت نام
                                کنید.</NavLink></p>
                        </div>
                    </div>
                </div> :
                <LoginConfirm userEmail={values.email} method={method} loginMode={() => setLoginMode(true)}/>}
        </>
            )}
</Formik>

);
}

export default withRouter(Login);