import config from './config.json'
import http from './httpService'
import axios from "axios";

export const getOrdersFromDB = (str = '') => {
    return http.get(`${config.coinbaseapi}/orders?size=7`)
}

export const getOrdersFromDBCustomie = (filterString = "") => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

    return http.get(`${config.coinbaseapi}/orders${filterString}`)
}

export const orderBuy = obj => {
    return http.post(`${config.coinbaseapi}/orders`, obj)
}