import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {findByModeOrder} from './../../utils/Price';
import ProfileItem from './../../components/ProfileItem/ProfileItem';
import './history.scss'
import ComponentMenu from "../../components/ComponentMenu/ComponentMenu";
import {getOrdersFromDBCustomie} from "../../services/ordersService";

import { getTransactionsCrypto, getTtransaction} from "../../services/transactionService";
import {setCoinsFilterOrders, setCoinsForOrders} from "../../redux/actions/coinsForOrdersAction";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import {getDataLoginFromDB} from "../../services/historyService";
import HistoryLogin from "../../components/HistoryLogin/HistoryLogin";
import HistoryTransactionCrpto from "../../components/HistoryTransactionCrpto/HistoryTransactionCrpto";
import HistoryTransactionToman from "../../components/HistoryTransactionToman/HistoryTransactionToman";
import HistoryOrders from "../../components/HistoryOrders/HistoryOrders";


const History = () => {

    const listCoinsFilterOrder = useSelector(state => state.listCoinsFilterOrder)

    const listTable = [
        {id: 0, title: "معاملات"},
        {id: 1, title: "ورودها"},
        {id: 2, title: "واریز و برداشت ریالی"},
        {id: 3, title: "واریز و برداشت ارزی"},
    ];
    const listOperation = [
        {id: 0, title: "تمام عملیات"},
        {id: 1, title: "واریز"},
        {id: 2, title: "برداشت"},
    ];

    const listBuyMode = [
        {id: 0, title: "خرید و فروش"},
        {id: 1, title: "خرید"},
        {id: 2, title: "فروش"},
    ];

    const [tableMode, setTableMode] = useState(0)
    const [operation, setOperation] = useState(0)
    const [operationCrypto, setOperationCrypto] = useState(0)
    const [buyMode, setBuyMode] = useState(0)
    const [indexCoinOrder, setIndexCoinOreder] = useState(0)
    const [filterStringOrder, setFilterStringOrder] = useState('')
    const [filterStringTransaction, setFilterStringTransaction] = useState('')
    const [filterStringTransactionCrypto, setFilterStringTransactionCrypto] = useState('')
    const [isDisable, setIsDisable] = useState(false)

    const dispatch = useDispatch()

    const setOrdersCus = async () => {
        setIsDisable(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getOrdersFromDBCustomie(filterStringOrder)
            if (data.type === 'success') {
                dispatch({type: 'INIT_META_ORDERS', payload: {...data.meta}})
                dispatch({type: 'INIT_HISTORY_ORDERS', payload: [ ...data.data]})
                setIsDisable(false)
            }
        } catch (ex) {
            setIsDisable(false)
            console.log(ex)
        }
    }

    const setTransactionCus = async () => {
        setIsDisable(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getTtransaction(filterStringTransaction)
            dispatch({type: 'INIT_META_TRANSACTION_TOMAN', payload: {...data.meta}})
            dispatch({type: 'INIT_HISTORY_TRANSACTION_TOMAN', payload: [...data.data]})
            setIsDisable(false)
        } catch (ex) {
            setIsDisable(false)
            console.log(ex)
        }
    }

    useEffect(() => {
        document.title = 'پنل کاربری || تاریخچه'
        setOrdersCus()
        dispatch(setCoinsForOrders())
        dispatch(setCoinsFilterOrders())
    }, [])

    const findCionOrder = (index) => {
        switch (index) {
            case 0:
                return ''
            default:
                return (buyMode === 0 ? `?coin=${listCoinsFilterOrder[indexCoinOrder].value}` : `&coin=${listCoinsFilterOrder[indexCoinOrder].value}`)
        }
    }

    const getDataTransactionCrypto = async (filter) => {
        setIsDisable(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getTransactionsCrypto(filter)
            if (data.type === 'success') {
                dispatch({type: 'INIT_META_TRANSACTION_CRYPTO', payload: {...data.meta}})
                dispatch({type: 'INIT_HISTORY_TRANSACTION_CRYPTO', payload: [...data.data]})
                setIsDisable(false)
            }
        } catch (ex) {
            setIsDisable(false)
            console.log(ex)
        }
    }
    useEffect(()=>{
        getDataTransactionCrypto(filterStringTransactionCrypto)
    },[filterStringTransactionCrypto])
    const setLoginHistory=async ()=>{
        setIsDisable(true)
        try {
            const {data} = await getDataLoginFromDB()
            if (data.type === 'success') {
                dispatch({type: 'INIT_META_LOGIN', payload: {...data.meta}})
                dispatch({type: 'INIT_HISTORY_LOGIN', payload: [...data.data]})
                setIsDisable(false)
            }
        } catch (ex) {
            setIsDisable(false)
            console.log(ex)
        }
    }


    useEffect(() => {
        setFilterStringOrder(findByModeOrder(buyMode) + findCionOrder(indexCoinOrder))
        // setOrdersCus()
    }, [buyMode, indexCoinOrder])

    useEffect(() => {
        switch (operation) {
            case 0:
                return setFilterStringTransaction('')
            case 1:
                return setFilterStringTransaction('&type=DEPOSIT')
            case 2:
                return setFilterStringTransaction('&type=WITHDRAW')
        }
    }, [operation])
    useEffect(() => {
        switch (operationCrypto) {
            case 0:
                return setFilterStringTransactionCrypto('')
            case 1:
                return setFilterStringTransactionCrypto('&type=DEPOSIT')
            case 2:
                return setFilterStringTransactionCrypto('&type=WITHDRAW')
        }
    }, [operationCrypto])

    useEffect(() => {
        switch (tableMode) {
            case 0:
                return setOrdersCus()
            case 1:
                return setLoginHistory()
            case 2:
                return setTransactionCus()
            case 3:
                return getDataTransactionCrypto()
            default:
                return setOrdersCus()
        }
    }, [tableMode])

    useEffect(() => {
        setTransactionCus(filterStringTransaction)
    }, [filterStringTransaction])

    useEffect(() => {
        setOrdersCus()
    }, [filterStringOrder])


    return (
        <ProfileItem title='تاریخچه'>
            <div className='containerMenusHistory'>
                <div className='conSubMenus'>
                    <ComponentMenu list={listTable} setID={setTableMode} placeholder={listTable[tableMode].title}/>
                </div>
                {tableMode === 2 ?
                    <div className='conSubMenus'>
                        <ComponentMenu list={listOperation} setID={setOperation}
                                       placeholder={listOperation[operation].title}/>
                    </div> : null}
                {tableMode === 0 ?
                    <div className='conSubMenus'>
                        <ComponentMenu list={listBuyMode} setID={setBuyMode}
                                       placeholder={listBuyMode[buyMode].title}/>
                    </div> : null}
                {tableMode === 0 ?
                    <div className='conSubMenus'>
                        <ComponentMenu list={listCoinsFilterOrder} setID={setIndexCoinOreder}
                                       placeholder={listCoinsFilterOrder.length > 0 ? listCoinsFilterOrder[indexCoinOrder].title : ''}/>
                    </div> : null}
                {tableMode === 3 ?
                    <div className='conSubMenus'>
                        <ComponentMenu list={listOperation} setID={setOperationCrypto}
                                       placeholder={listOperation.length > 0 ? listOperation[operationCrypto].title : ''}/>
                    </div> : null}
                {/*:null}*/}
            </div>
            {tableMode === 1 ?
                <div className='containerTableLogin'>
                    {isDisable ? <Loading/> : <HistoryLogin /> }
                </div> : null}
            {tableMode === 2 ?
                <div className='containerTableLogin'>
                    {isDisable ? <Loading/> :<HistoryTransactionToman filterString={filterStringTransaction}/>}
                </div> : null}
            {tableMode === 3 ?
                <div className='containerTableLogin'>
                    {isDisable ? <Loading/> :<HistoryTransactionCrpto filterString={filterStringTransactionCrypto}/>}
                </div>
                : null}
            {tableMode === 0 ?
                <div className='containerTableLogin'>
                    {isDisable ? <Loading/> :<HistoryOrders filterString={filterStringOrder}/>}
                </div> : null}
        </ProfileItem>
    );
}

export default History;
