import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App.jsx';
import { Provider } from 'react-redux'
import { store } from './redux/store/store';

import './assets/sass/dialogs.scss'
import './assets/css/bootstrap.min.css'
import './assets/css/variable.css'
import './assets/css/style.css'
import './assets/css/ReactToastify.css'
import './assets/css/dialog.css'

ReactDOM.render( <
    Provider store = { store } > < App /></Provider>,document.getElementById('root'));