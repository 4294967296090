import React, {useState, useEffect} from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import {floorNumber, numberWithCommas} from './../../utils/Price';
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import {useSelector} from "react-redux";
import {Formik} from 'formik';
import * as Yup from 'yup';
import './withdrawDialog.scss'
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ComponentMenu from "../../components/ComponentMenu/ComponentMenu";
import TextWithdrawDialog from "../../components/TextWithdrawDialog/TextWithdrawDialog";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {optWithdraw, postWithdraw} from "../../services/depositWithdrawService";
import CustomInput from "../../components/CustomInput/CustomInput";
import {withRouter} from 'react-router-dom'
import InputPrice from "../../components/InputPrice/InputPrice";
import ShowTimer from "../../components/ShowTimer/ShowTimer";

const validationSchema = Yup.object().shape({
    amount: Yup.number().required('پر کردن این فیلد الزامی می باشد.').min(1000, 'نباید کمتر از 1000 تومان باشد').max(50000000,'سقف مجاز کمتر از 50 میلیون است')
})

const WithdrawDialog = ({showDialog, closeDialog, history}) => {

    const acceptedAccounts = useSelector(state => state.acceptedAccounts)

    const user = useSelector(state => state.user)
    const {credits} = user

    const findToman = () => {
        const toman = credits.find(item => item.code === 'TOMAN')
       return  toman? toman.net:0
    }

    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])
    let codeNumber = ''
    const numOfFields = 6;
    const [getIdDebit, setIdDebit] = useState(-1)
    const [loadOpt, setLoadOpt] = useState(false)
    const [loadSendAgain, setLoadSendAgain] = useState(false)
    const [step2, setStep2] = useState(false)
    const [sendAgain, setSendAgain] = useState(false)
    const [otp, setOtp] = useState(true)

    const handleSendAgain = async () => {
        setLoadSendAgain(true)
        try {
            const {data} = await optWithdraw()
            if (data.type === 'success') {
                setLoadSendAgain(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setSendAgain(false)
            }
        } catch (ex) {
            setLoadSendAgain(false)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
        }
    }

    const handleOpt = async () => {
        setLoadOpt(true)
        try {
            const {data} = await optWithdraw()
            if (data.type === 'success') {
                setLoadOpt(false)
                setStep2(true)
                setOtp(false)
                setSendAgain(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
            }
        } catch (ex) {
            setLoadOpt(false)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
        }
    }
    const handleWithdraw = async (values) => {
        if (getIdDebit > -1) {
            if (step2) {
                setLoadOpt(true)
                const code = numberrr.join('')
                if (getIdDebit < 0) {
                    toast.warning('لطفا کارت بانکی خود را انتخاب کنید', {position: 'top-right', closeOnClick: true})
                    setLoadOpt(false)
                } else {
                    if (code.length > 5) {
                        const obj = {
                            amount: values.amount,
                            coin_id: 1,
                            code: code,
                            account_id: acceptedAccounts[getIdDebit].value
                        }
                        try {
                            const {data} = await postWithdraw(obj)
                            if (data.type === 'success') {
                                setLoadOpt(false)
                                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                                closeDialog()
                            }
                        } catch (ex) {
                            console.log(ex)
                            setLoadOpt(false)
                            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                        }
                    } else {
                        toast.error('لطفا کد را کامل وارد کنید', {position: 'bottom-right', closeOnClick: true})
                        setLoadOpt(false)
                    }
                }

            } else {
                handleOpt()
            }

        } else {
            toast.warning('لطفا کارت بانکی خود را انتخاب کنید', {position: 'top-right', closeOnClick: true})

        }

    }


    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        codeNumber = temp

        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < numOfFields) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
            }
        }
    }


    const handleGoProfile = () => {
        history.push('/account/profile')
        closeDialog()
    }

    useEffect(() => {
        setIdDebit(-1)
        setIdDebit(-1)
        setStep2(false)
        setSendAgain(false)
        setLoadOpt(false)
        setLoadSendAgain(false)
        // return () =>
    }, [showDialog])

    return (
        <DialogOverlay onDismiss={closeDialog} isOpen={showDialog}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <div className='insideDeposit'>
                    <p className='titleDeposit'>برداشت ریالی</p>
                    <Formik initialValues={{amount: ''}}
                            onSubmit={(values) => handleWithdraw(values)}
                            validationSchema={validationSchema}
                    >
                        {({handleSubmit, setFieldTouched, touched, handleChange, values, errors}) => (
                            <>
                                <InputPrice caption={true} placeholder='مبلغ برداشتی'
                                            marginBottom='18px'
                                            onChange={handleChange('amount')}
                                            onBlur={() => setFieldTouched('amount')}/>
                                <ErrorMessage error={errors.amount} visible={touched.amount}/>
                                <div className='flexInDialogWithdraw'>
                                    {/*<p>{`تومان ${user.credits.length>0 ? numberWithCommas(user.credits[0].net ? user.credits[0].net : 0) : 0}`}</p>*/}
                                    <p>{`تومان ${user.credits.length>0 ? numberWithCommas(findToman()) : 0}`}</p>
                                    <p>موجودی :</p>
                                </div>
                                <ComponentMenu enableSpan={true} title='انتخاب کارت بانکی' setID={setIdDebit}
                                               list={acceptedAccounts}
                                               placeholder={getIdDebit < 0 ? 'انتخاب کارت بانکی' : acceptedAccounts[getIdDebit].title}
                                               marginBottom='16px'/>
                                <p className='pAddCard' onClick={() => handleGoProfile()}>افزودن کارت بانکی</p>
                                <TextWithdrawDialog/>
                                {step2 ?
                                    <>
                                        <p className='p6Digit'>کد شش رقمی ارسال شده به ایمیل خود را وارد کنید.</p>
                                        <div className='inputDigit6'>
                                            <div className='containerInputCodeOPT'>
                                                {numberrr.map((_, index) =>
                                                    <input key={index} maxLength="1"
                                                           onChange={(e) => handleTextChange(e, index)}
                                                           name={`ssn-${index + 1}`} className='inputCodeOPt'
                                                           id='myInput'
                                                           type="number"/>
                                                )
                                                }
                                            </div>
                                        </div>
                                        {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                                    </>
                                    : null}
                                {otp ?
                                    // <ButtonForm isDis={loadOpt} funcClick={handleOpt}>
                                    <ButtonForm isDis={loadOpt} funcClick={handleSubmit}>
                                        {loadOpt === false? 'ثبت' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                     variant="secondary"/>
                                        }
                                    </ButtonForm> : null
                                }
                                {!sendAgain && !otp ?
                                    <ButtonForm isDis={loadOpt} funcClick={handleSubmit} marginBottom='24px'>
                                        {/*{loadOpt === false && !sendAgain === false ? 'برداشت' :*/}
                                        {loadOpt === false ? 'برداشت' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                     variant="secondary"/>
                                        }
                                    </ButtonForm> : null
                                }
                                {sendAgain ?
                                    <ButtonForm isDis={loadSendAgain} funcClick={handleSendAgain} marginBottom='24px'>
                                        {!loadSendAgain ? 'ارسال مجدد کد' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                     variant="secondary"/>
                                        }
                                    </ButtonForm>
                                    : null}

                            </>
                        )}
                    </Formik>

                </div>
            </DialogContent>
        </DialogOverlay>
    );
}

export default withRouter(WithdrawDialog);