import config from './config.json'
import http from './httpService'
import axios from "axios";

export const getTtransaction = (strFilter = null) => {
    return http.get(`${config.coinbaseapi}/transactions?coinType=toman${strFilter}`)
}

export const getReportTransaction = id => {
    return http.get(`${config.coinbaseapi}/transactions/${id}`)
}

export const getTransactionsCrypto = (strFilter = '') => {
    return http.get(`${config.coinbaseapi}/transactions?coinType=crypto${strFilter}`)
}
export const getTransactionsToman = (strFilter = '') => {
    return http.get(`${config.coinbaseapi}/transactions?coinType=toman${strFilter}`)
}

export const getStateTransaction = id => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return http.patch(`${config.coinbaseapi}/transactions/${id}/check-deposit`)
}
export const tx_id = (id,obj)=>{
    return http.patch(`${config.coinbaseapi}/transactions/${id}/tx-id`,obj)
}