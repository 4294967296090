import {getDataLoginFromDB} from "../../services/historyService";
import {getTransactionsCrypto, getTtransaction} from "../../services/transactionService";
import {getOrdersFromDBCustomie} from "../../services/ordersService";

export const getDataLoginHistory = (page) => {
    return async (dispatch, getState) => {

        const oldData = [...getState().historyLogin]
        try {
            const {data} = await getDataLoginFromDB(page)
            if (data.type === 'success') {
                await dispatch({type: 'INIT_META_LOGIN', payload: {...data.meta}})
                await dispatch({type: 'INIT_HISTORY_LOGIN', payload: [...oldData, ...data.data]})
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}

export const getDataLoginHistoryInit = () => {
    return async (dispatch) => {
        try {
            const {data} = await getDataLoginFromDB()
            if (data.type === 'success') {
                await dispatch({type: 'INIT_META_LOGIN', payload: {...data.meta}})
                await dispatch({type: 'INIT_HISTORY_LOGIN', payload: [...data.data]})
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}

export const getDataTrnsactionCryptoHistory = (page) => {
    return async (dispatch, getState) => {
        const oldData = [...getState().historyTransactionCrypto]
        try {
            const {data} = await getTransactionsCrypto(page)
            if (data.type === 'success') {
                await dispatch({type: 'INIT_META_TRANSACTION_CRYPTO', payload: {...data.meta}})
                await dispatch({type: 'INIT_HISTORY_TRANSACTION_CRYPTO', payload: [...oldData, ...data.data]})
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}

export const getDataTrnsactionTomanHistory = (page) => {
    return async (dispatch, getState) => {
        const oldData = [...getState().historyTransactionToman]

        try {
            const {data} = await getTtransaction(page)
            if (data.type === 'success') {
                await dispatch({type: 'INIT_META_TRANSACTION_TOMAN', payload: {...data.meta}})
                await dispatch({type: 'INIT_HISTORY_TRANSACTION_TOMAN', payload: [...oldData, ...data.data]})
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}

export const getDataOrdersHistory = (page) => {
    return async (dispatch, getState) => {
        const oldData = [...getState().historyOrders]
        try {
            const {data} = await getOrdersFromDBCustomie(page)
            if (data.type === 'success') {
                await dispatch({type: 'INIT_META_ORDERS', payload: {...data.meta}})
                await dispatch({type: 'INIT_HISTORY_ORDERS', payload: [...oldData, ...data.data]})
            }
        } catch (ex) {
            console.log(ex)
        }
    }
}