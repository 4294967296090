import React, {useState, useEffect} from 'react'
import './inputCardNumber.scss'

const InputCardNumber = ({
                             placeholder = '',
                             onChange,
                             defaultValue,
                             marginBottom,
                             onBlur,
                             disabled,
                             defaultlValue,
                             classNames = '',
                             caption = false
                         }) => {

    const [getCardNumber, setCardNumber] = useState(defaultlValue)

    const insertDash = (num) => {
        const str = num ? num.toString() : '';
        const result = [str[0]];
        for (let x = 1; x < str.length; x++) {
            if (x % 4 === 0) {
                result.push('-', str[x]);
            } else {
                result.push(str[x]);
            }
        }
        return result.join('')

    }

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    // useEffect(() => {
    //     if (defaultlValue) {
    //         onChange(defaultlValue)
    //     }
    // }, [])
    useEffect(() => {
        setCardNumber(defaultlValue)
        onChange(defaultlValue)
    }, [defaultlValue])
    return (
        <div className='containerInputCardNumber'>
            <input pattern="[0-9]*"
                   disabled={disabled}
                   onKeyPress={(e) => validate(e)}
                   value={insertDash(getCardNumber)}
                   className={classNames}
                   maxLength={19}
                   onChange={(e) => {
                       const str = e.target.value
                       setCardNumber(str.replaceAll('-', ''))
                       if (onChange) {
                           onChange(str.replaceAll('-', ''))
                       }
                   }}
                   onBlur={onBlur} placeholder={placeholder} style={{marginBottom}}/>
            {getCardNumber !== '' && caption ? <span className='spanHolder'>{placeholder}</span> : null}

        </div>
    )
}
export default InputCardNumber