import React, {useEffect, useState} from "react";
import ProfileItem from "../../components/ProfileItem/ProfileItem";

import './viewWallets.scss'
import {useDispatch, useSelector} from "react-redux";
import {setWallet} from "../../redux/actions/walletAction";
import WalletItem from "../../components/WalletItem/WalletItem";
import {initMarketCoins} from "../../redux/actions/marketCoinsActions";
import {getWallets} from "../../services/walletService";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import {getActiveDebit} from "../../redux/actions/bankAccountsAction";
import login from "../Login/Login";


const ViewWallets = () => {

    const dispatch = useDispatch()
    const wallets = useSelector(state => state.wallets)
    const [loading, setLoading] = useState(false)

    const setWalletFromBackend = async () => {
        setLoading(true)
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
        const {data} = await getWallets()
        if (data.type === 'success') {
            dispatch({type: 'INIT-WALLETS', payload: [...data.data]})
            setLoading(false)
        }
    }

    useEffect(() => {
        dispatch(getActiveDebit())
        setWalletFromBackend()
        dispatch(initMarketCoins())
    }, [])

    return (
        <ProfileItem title='کیف پولها'>
            {loading?<Loading/>:(
                wallets.length > 0 ?
                    wallets.map(w => (
                        <WalletItem logo={w.logo} coin_id={w.coin_id} name={w.name}
                                    credit={w.credit === null ? 0 : w.credit} label={w.label} code={w.code}/>

                    ))
                    :
                    <p>در حال بارگذاری اطلاعت</p>
            )}

        </ProfileItem>
    )
}
export default ViewWallets

// return (
//     <ProfileItem title='کیف پولها'>
//         {loading?<Loading/>:()}
//         {wallets.length > 0 ?
//             wallets.map(w => (
//                 <WalletItem logo={w.logo} coin_id={w.coin_id} name={w.name}
//                             credit={w.credit === null ? 0 : w.credit} label={w.label} code={w.code}/>
//             ))
//             :
//             <p>در حال بارگذاری اطلاعت</p>}
//     </ProfileItem>
// )