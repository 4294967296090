import config from './config.json'

import http from './httpService'
import axios from "axios";

export const setPersonalInfo = user => {
    return http.post(`${config.coinbaseapi}/authentication`, user)
}

export const editPersonalInfo = user => {
    return http.put(`${config.coinbaseapi}/authentication`, user)
}

export const getPersonalInfoLogin = () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return http.get(`${config.coinbaseapi}/users/init`)
        // return http.get(`${config.coinbaseapi}/authentication`)

}

export const uploadImage = obj => {
    return http.post(`${config.coinbaseapi}/documents`, obj)
}
export const downloadImage = () => {
    return http.get(`${config.coinbaseapi}/documents`)
}


export const getHistoryLoggin = () => {
    return http.get(`${config.coinbaseapi}/logins?size=7`)
}

// export const changeNewPassword = obj=>{
//     console.log(obj)
//     return http.post(`${config.coinbaseapi}/settings/password`,obj)
// }