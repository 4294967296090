import React from 'react';
import './inputItemPersonalInfo.scss'

const InputItemPersonalInfo = ( {children}) => {

    return (
        <div className='col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center topInputItem'>
            <div className='containerInputPersonalInfo '>
                {children}
            </div>
        </div>
    );
}

export default InputItemPersonalInfo;