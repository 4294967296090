import bitcoinImage from './../../assets/img/coins/bitCoin.svg'
import atriomImage from './../../assets/img/coins/atriom.svg'
import bitCoinCatchImage from './../../assets/img/coins/bitCoinCatch.svg'
import binNesCoinImage from './../../assets/img/coins/binNesCoin.svg'
import dashImage from './../../assets/img/coins/dash.svg'
import dojImage from './../../assets/img/coins/doj.svg'
import atriomClassicImage from './../../assets/img/coins/atriomClassic.svg'
import lightCoinImage from './../../assets/img/coins/lightCoin.svg'
import repilImage from './../../assets/img/coins/repil.svg'
import tetherImage from './../../assets/img/coins/tether.svg'


export const allCoinsReducer = (state = [
    { id: 0, img: bitcoinImage, name: 'بیت کوین', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 1, img: atriomImage, name: 'اتریوم', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 2, img: bitCoinCatchImage, name: 'بیت کوین کش', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 3, img: binNesCoinImage, name: 'بایننس کوین', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 4, img: dashImage, name: 'دش', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 5, img: dojImage, name: 'دوج', price: 45726, change: '- 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 6, img: atriomClassicImage, name: 'اتریوم کلاسیک', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 7, img: lightCoinImage, name: 'لایت کوین', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 8, img: repilImage, name: 'رپیل', price: 45726, change: '- 6.1%', priceSell: 132415168, priceBuy: 132415168 },
    { id: 9, img: tetherImage, name: 'تتر', price: 45726, change: '+ 6.1%', priceSell: 132415168, priceBuy: 132415168 }
], action) => {
    switch (action.type) {
        default: return state
    }
}