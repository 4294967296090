import React, {useState, useEffect} from 'react';
import {NavLink, withRouter} from 'react-router-dom'
import {Formik} from 'formik';
import * as Yup from 'yup'
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {Spinner} from 'reactstrap'
import ButtonForm from '../../components/ButtomForm/ButtomForm';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import RegisterConfirm from './RegisterConfirm';

import {changeRegisterMode} from '../../redux/actions/registerModeAction';
import {registerUser} from './../../services/userService';

import './register.css'
import {setUserInfo} from './../../redux/actions/userActions';
import CustomInput from "../../components/CustomInput/CustomInput";
import GoogleAuthenticated from "../../components/GoogleAuthenticated/GoogleAuthenticated";

const validationSchema = Yup.object().shape({
    email: Yup.string().required('پر کردن این فیلد الزامی می باشد.').email('ایمیل وارد شده نامعتبر است.'),
    password: Yup.string().required('پر کردن این فیلد الزامی می باشد.').matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "باید حداقل شامل 8 کاراکتر و حروف کوچک و بزرگ و اعداد باشد"
    ),
})

const Register = ({match}) => {

    const registerMode = useSelector(state => state.registerMode)
    const [isDisable, setIsDisable] = useState(false)
    const dispatch = useDispatch()

    const handleSub = async values => {
        setIsDisable(true)
        try {
            const {data} = await registerUser(values)
            if (data.type === "success") {
                toast.success(`${data.message}`, {position: 'top-right'})
                setIsDisable(false)
                dispatch(setUserInfo(data.data))
                dispatch(changeRegisterMode(false))
            }

        } catch (ex) {
            toast.error(`${ex.data.errors.email[0]}`, {position: 'bottom-right'})
            setIsDisable(false)
        }
    }

    useEffect(() => {
        document.title = 'صرافی کوین بیس || ثبت نام'

    })
    return (
        <Formik
            initialValues={{password: '', email: (match.params.email ? match.params.email : ''), rule: true,code:''}}
            validationSchema={validationSchema}
            onSubmit={values => handleSub(values)}>
            {({handleChange, values, handleSubmit, errors, setFieldTouched, touched}) => (
                <>
                    <>
                        {registerMode ?
                            <div className='register'>
                                <div className='containerLogin'>
                                    <p className='titleInForm'>ثبت نام</p>
                                    <div className='insideForm'>
                                        <CustomInput inputType='email' placeholder='آدرس ایمیل' marginBottom='18px'
                                                     textAlignLeft={true} handleChange={handleChange('email')}
                                                     handleBlur={() => setFieldTouched('email')}
                                                     value={values.email}/>
                                        <ErrorMessage error={errors.email} visible={touched.email}/>
                                        <CustomInput inputType='password' textAlignLeft={true} placeholder='رمز عبور'
                                                     handleChange={handleChange('password')} value={values.password}
                                                     handleBlur={() => setFieldTouched('password')} marginBottom='16px'
                                        />
                                        <ErrorMessage error={errors.password} visible={touched.password}/>
                                        <CustomInput  marginBottom='16px' placeholder='کد دعوت( اختیاری)'
                                                      handleChange={handleChange('code')} value={values.code}
                                                      handleBlur={() => setFieldTouched('code')}
                                                     textAlignLeft={true}/>
                                        <p className='pInRegisterForm'>با ثبت نام در بامبو شما شرایط و قوانین استفاده از
                                            سرویس‌های سایت را می‌پذیرید</p>
                                        <ButtonForm type='submit' isDis={isDisable} funcClick={handleSubmit}
                                                    marginBottom={37}>
                                            {isDisable === false ? 'ثبت' :
                                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                         variant="secondary"/>
                                            }
                                        </ButtonForm>

                                        <div className='insideFormHR'>
                                            <div className='hrForm'/>
                                            <span className='spanHR'>یا</span>
                                        </div>
                                        <GoogleAuthenticated/>
                                        <p className='questionRegister'>حساب کاربری دارید؟ <NavLink to='/login'>وارد
                                            شوید.</NavLink></p>
                                    </div>
                                </div>
                            </div>
                            : <RegisterConfirm userEmail={values.email}/>}
                    </>
                </>
            )}
        </Formik>
    );

}

export default withRouter(Register);