import React, { useState } from 'react';

import ButtomDialogs from './../ButtomDialogs/ButtomDialogs';
import FirstSell from '../FirstSell/FirstSell';
import SecondSell from '../SecondSell/SecondSell';

import './SellModeModal.scss'

const SellModeModal = ({ closeFunction }) => {

    const [getFirst, setFirst] = useState(false)

    const handleSell = () => {
        // if (getFirst) {
        //     closeFunction()
        // } else {
        //     setFirst(true)
        // }
        closeFunction()
    }

    return (
        <>
            <FirstSell closeFunction={closeFunction} />
            {/*{getFirst ? <SecondSell /> : <FirstSell />}*/}
            {/*<ButtomDialogs runFunction={handleSell} title={getFirst ? 'ثبت درخواست' : 'فروش رمز ارز'} />*/}
        </>
    );
}

export default SellModeModal;