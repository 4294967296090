import config from './config.json'
import http from './httpService'

export const getCoinsMarket = () =>{
    return http.get(`${config.coinbaseapi}/markets?quote=TOMAN&status=ACTIVATED`)
}

export const getMarketCoins=()=>{
    return http.get(`${config.coinbaseapi}/markets?quote=TOMAN&size=500&status=ACTIVATED`)
}

export const weeklyPrice=()=>{
    return http.get(`${config.coinbaseapi}/weekly-prices`)
}