export const bankAccountsReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_BANK_ACCOUNTS':
            return [...action.payload]
        default:
            return state
    }
}

export const acceptedAccountsReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_BANK_ACCOUNTS_ACTIVE':
            return [...action.payload]
        default:
            return state
    }
}