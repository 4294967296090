import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCard } from '../../redux/actions/cardsActions';
import { deleteBankAccount } from '../../services/bankAccountService';
import './debitCardItem.scss'
import { toast } from 'react-toastify';
import { getAccounts } from './../../redux/actions/bankAccountsAction';
import EditBankAccount from '../../dialogs/EditBankAccount/EditBankAccount';
import {Spinner} from "reactstrap";
import {numberWithDash} from "../../utils/Price";
import {getAccountStatus} from "../../utils/helperFunction";

const DebitCardItem = ({ id, cardNumber,sheba, status, img, bankName }) => {

    const dispatch = useDispatch()
    const [editDialog, setEditDialog] = useState(false)
    const [isDisableDelete,setIsDisableDelete] = useState(false)
    const closeDialog = () => setEditDialog(false)
    const openDialog = () => setEditDialog(true)
    const handleDeleteCard = async (id) => {
        try {
            setIsDisableDelete(true)
            const { data } = await deleteBankAccount(id)
            if (data.type === 'success') {
                toast.success(`${data.message}`, { position: 'top-right' })
                setIsDisableDelete(false)
                dispatch(getAccounts())
            }
        } catch (ex) {
            setIsDisableDelete(false)
            toast.error(`${ex.data.message}`, { position: 'bottom-right', closeOnClick: true })
            // console.log(ex);
        }

    }

    return (
        <div className='debitCardItem'>
            <div className='insideDebitCardItem'>
                <div className='leftCardItem'>
                    <span  className='deleteCard' onClick={() => handleDeleteCard(id)}>
                         {isDisableDelete === false ? 'حذف' :
                             <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                      variant="secondary"/>
                         }
                    </span> / <span className='deleteCard' onClick={() => openDialog()}>ویرایش</span>
                    <img src={img} alt="" />
                </div>
                <div className='rightCardItem'>
                    <div className='subItemCard'>
                        <p className='titleInCardItem'>بانک: </p>
                        <p className='bankName'>{bankName}</p>
                    </div>
                    <div className='subItemCard'>
                        <p className='titleInCardItem'>شماره کارت: </p>
                        <p className='bankName'>{numberWithDash(cardNumber)}</p>
                    </div>
                    <div className='subItemCard'>
                        <p className='titleInCardItem'>وضعیت : </p>
                        <p className='bankName'>{getAccountStatus(status)}</p>
                    </div>
                </div>
            </div>
             <EditBankAccount showDialog={editDialog} closeDialog={closeDialog} id={id} sheba={sheba} card_number={cardNumber} />
        </div>
    );
}

export default DebitCardItem;