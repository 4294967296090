import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CartTable from '../CartTable/CartTable';
import {convertDateTime, numberWithCommas, typeOfStatusOrder} from './../../utils/Price';
import './containerTables.scss'
import {setEntiredTop10} from "../../redux/actions/entriesActions";
import {persianNameDevices} from "../../utils/helperFunction";
import {setOrdersTop10} from "../../redux/actions/ordersAction";
import axios from "axios";
import {getHistoryLoggin} from "../../services/personService";
import {getOrdersFromDB} from "../../services/ordersService";
import Loading from "../Loading/Loading";

const ContainerTables = () => {

    const entries = useSelector(state => state.entries)
    const orders = useSelector(state => state.orders)
    const dispatch = useDispatch()

    const [loadinOrder, setLoadingOrder] = useState(false)
    const [loadinLogin, setLoadingLogin] = useState(false)

    const loadDataLogin = async () => {
        setLoadingLogin(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getHistoryLoggin('?size=7')
            dispatch({type: 'INIT_ENTIRE', payload: data.data})
            setLoadingLogin(false)
        } catch (ex) {
            console.log(ex)
            setLoadingLogin(false)
        }
    }
    const loadDataOrder = async () => {
        setLoadingOrder(true)
        try {
            const {data} = await getOrdersFromDB('?size=7')
            dispatch({type: 'INIT_ORDERS', payload: [...data.data]})
            setLoadingOrder(false)
        } catch (ex) {
            console.log(ex)
            setLoadingOrder(false)
        }
    }

    useEffect(() => {
        loadDataLogin()
        loadDataOrder()
        // dispatch(setEntiredTop10())
        // dispatch(setOrdersTop10())
    }, []);


    return (
        <div className='ttttttt'>
            <CartTable title='ورودها'>
                {loadinLogin ? <Loading/> : <table dir='rtl' className='table table-borderless'>
                    <thead>
                    <tr>
                        <td className='titleTableEntires text-right'>زمان</td>
                        <td className='titleTableEntires text-center'>آی پی</td>
                        <td className='titleTableEntires text-center'><span className='noWrap'>نوع دستگاه</span></td>
                        <td className='titleTableEntires text-center'>مرورگر</td>
                        <td className='titleTableEntires text-left'>وضعیت</td>
                    </tr>
                    </thead>
                    <tbody>
                    {entries.map((item, index) => (
                        <tr key={index}>
                            <td className=' dateTimeInFAQ text-right tdTextRight'><span
                                className='valueTableEntires text-right'>{convertDateTime(item.created_at)}</span></td>
                            {/*<td className='text-right dateTimeInFAQ'><span className='valueTableEntires'>{convertDateTime(item.created_at)}</span></td>*/}
                            <td className='text-center'><span className='valueTableEntires'>{item.ip}</span></td>
                            {/*<td className='text-center'><span className='valueTableEntires'>{persianNameDevices(item.device)}</span>*/}
                            <td className='text-center'><span
                                className='valueTableEntires'>{persianNameDevices(item.device)}</span>
                            </td>
                            <td className='text-center'><span className='valueTableEntires'>{item.browser}</span></td>
                            <td className='text-left'><span
                                className={item.status === 'SUCCESS' ? 'valueTableEntires statusTrue' : "valueTableEntires statusFalse"}>{item.status === 'SUCCESS' ? 'موفق' : 'ناموفق'}</span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>}
            </CartTable>


            <CartTable title='آخرین سفارشات'>
                {loadinOrder ? <Loading/> : <table dir='rtl' className='table table-borderless'>
                    <thead>
                    <tr>
                        <td className='titleTableEntires text-right'>زمان</td>
                        <td className='titleTableEntires text-center'>مقدار</td>
                        <td className='titleTableEntires text-center'>قیمت</td>
                        <td className='titleTableEntires text-center'>مجموع</td>
                        <td className='titleTableEntires text-left fLeft'>عملیات</td>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.length > 0 ?
                        orders.map((item, index) => (
                            <tr key={index}>
                                <td className='text-right'><span
                                    className={item.type === 'BUY' ? 'valueTableEntires statusTrue' : 'valueTableEntires statusFalse'}>{item.market.coin.label}</span>
                                </td>
                                <td className='text-center'><span
                                    className={item.type === 'BUY' ? 'valueTableEntires statusTrue' : 'valueTableEntires statusFalse'}>{item.amount}</span>
                                </td>
                                <td className='text-center'><span
                                    className={item.type === 'BUY' ? 'valueTableEntires statusTrue' : 'valueTableEntires statusFalse'}>{numberWithCommas(item.price)}</span>
                                </td>
                                <td className='text-center'><span
                                    className={item.type === 'BUY' ? 'valueTableEntires statusTrue' : 'valueTableEntires statusFalse'}>{numberWithCommas(item.total)}</span>
                                </td>
                                <td className='text-end'><span
                                    className='valueTableEntires fLeft'>{typeOfStatusOrder(item.status)}</span>
                                </td>
                            </tr>
                        ))
                        : <p>هنوز سفارشی ثبت نشده است.</p>}
                    </tbody>
                </table>}
            </CartTable>
        </div>
    );
}

export default ContainerTables;