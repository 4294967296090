import React from 'react';
import closeBtn from './../../assets/img/closeDialog.svg'
import './closeDialog.scss'

const CloseDialog = ({ funcIn }) => {
    return (
        <img src={closeBtn} onClick={funcIn} alt="" className='closeDialogBtn' />
    );
}

export default CloseDialog;