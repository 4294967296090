import React, {useState} from 'react'
import ButtonForm from "../ButtomForm/ButtomForm";

import {Formik} from "formik";
import * as Yup from 'yup'
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {changeNewPassword} from "../../services/settingService";
import {toast} from "react-toastify";
import {Spinner} from "reactstrap";
import CustomInput from "../CustomInput/CustomInput";

const validationSchema = Yup.object().shape({
    password_old: Yup.string().required('پر کردن این فیلد الزامی می باشد.'),
    password: Yup.string().required('پر کردن این فیلد الزامی می باشد.').matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "باید حداقل شامل 8 کاراکتر و حروف کوچک و بزرگ و اعداد باشد"
    ),
    password_confirmation: Yup.string().required('پر کردن این فیلد الزامی می باشد.').matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "باید حداقل شامل 8 کاراکتر و حروف کوچک و بزرگ و اعداد باشد"
    ).oneOf([Yup.ref('password'), null], 'عدم تطابق گذرواژه ها')
})

const ChangePasswordDi = ({closeDialog}) => {

    const [isLoading, setLoading] = useState(false)

    const handleChangePassword = async (values) => {
        console.log(values)
        try {
            setLoading(true)
            const {data} = await changeNewPassword(values)
            if (data.type === 'success') {
                setLoading(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: 'true'})
                closeDialog()
            }
        } catch (ex) {
            setLoading(false)
            toast.error(`${ex.data.errors.password_old[0]}`, {position: 'bottom-right', closeOnClick: true})
        }

    }
    return (
        <Formik initialValues={{password_old: '', password: '', password_confirmation: ''}}
                validationSchema={validationSchema} onSubmit={(values) => handleChangePassword(values)}>
            {({handleChange, handleSubmit, errors, values, touched, setFieldTouched}) => (
                <>
                    <p className='pInchangepassword'>برای تغییر رمز عبور، ابتدا رمز عبور فعلی خود را وارد کنید.</p>

                    <CustomInput inputType='text' placeholder='رمز عبور فعلی' marginBottom='18px'
                                 value={values.password_old} handleChange={handleChange('password_old')}
                                 textAlignLeft={true} handleBlur={() => setFieldTouched('password_old')}/>
                    <ErrorMessage error={errors.password_old} visible={touched.password_old}/>

                    <CustomInput inputType='text' placeholder='رمز عبور جدید' marginBottom='18px'
                                 value={values.password} handleChange={handleChange('password')} textAlignLeft={true}
                                 handleBlur={() => setFieldTouched('password')}/>

                    <ErrorMessage error={errors.password} visible={touched.password}/>

                    <CustomInput inputType='text' placeholder='تکرار رمز عبور جدید' marginBottom='18px'
                                 value={values.password_confirmation}
                                 handleChange={handleChange('password_confirmation')} textAlignLeft={true}
                                 handleBlur={() => setFieldTouched('password_confirmation')}/>
                    <ErrorMessage error={errors.password_confirmation} visible={touched.password_confirmation}/>
                    <ButtonForm isDis={isLoading} funcClick={() => handleSubmit()}>
                        {isLoading === false ? 'ثبت' :
                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                     variant="secondary"/>
                        }
                    </ButtonForm>
                </>
            )}
        </Formik>
    )
}
export default ChangePasswordDi