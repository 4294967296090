import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/Price';
import Echo from 'laravel-echo';

// images
import arrow from './../../assets/img/ArrowRecive.svg'
import dashLine from './../../assets/img/lineStyleDash.svg'

import './step1Buy.scss'
import CryptoMenu from '../CryptoMenu/CryptoMenu';
import ButtonForm from "../ButtomForm/ButtomForm";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { orderBuy } from "../../services/ordersService";
import { setReadData } from "../../redux/actions/realDataAction";
import { toFixedDigit } from "../../utils/helperFunction";

const Step1Buy = ({ closeFunction }) => {

    const [showCryptoMenu, setShowCryptoMenu] = useState(false)
    const [amount, setAmount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [priceBuy, setPriceBuy] = useState(0)
    const [isDisable, setIsDisable] = useState(false)
    const [credit, setCredit] = useState({})
    const [realCoin, setRealCoin] = useState(0)
    const [typeCal, setTypeCal] = useState('amount')

    const refCrypto = useRef()
    const dispatch = useDispatch()

    const marketCoins = useSelector(state => state.marketCoins)
    const coin = useSelector(state => state.coin)
    const marketId = useSelector(state => state.marketId)
    const realData = useSelector(state => state.readData)
    const login = useSelector(state => state.login)
    const user = useSelector(state => state.user)
    const { rank_correction_factor } = user
    const { credits } = user

    const findCredits = code => {
        // console.log(code)
        const obj = credits.find(item => item.code === code)
        return (obj ? obj.credit : '0')
    }
    const [mojodi, setMojodi] = useState('')
    const floorPrice = (num) => {
        let price = Number.parseFloat(num);
        if (price >= 1) {
            return price.toFixed(0);
        }
        return parseFloat(price.toPrecision(4));
    }
    const handleGetCredit = async () => {
        const cre = await user.credits.find(item => item.label === coin.name)
        // console.log(cre?cre:'nist')
        setMojodi(cre ? cre.credit : '0')
        setCredit(cre)

    }
    const handleChangeAmount = (val) => {
        setAmount(val)
    }

    const handleOrderBuy = async () => {
        if (amount > 0) {
            setIsDisable(true)
            const obj = {
                market_id: marketId,
                type: 'BUY',
                amount
            }
            console.log(obj)
            try {
                const { data } = await orderBuy(obj)
                if (data.type === 'success') {
                    toast.success(`${data.message}`, { position: 'top-right', closeOnClick: true })
                    setIsDisable(false)
                    closeFunction()
                }
            } catch (ex) {
                setIsDisable(false)
                console.log(ex)
                if (ex.data.errors) {
                    toast.error(`${ex.data.errors.amount[0]}`, { position: 'bottom-right', closeOnClick: true })
                } else {
                    toast.error(`${ex.data.message}`, { position: 'bottom-right', closeOnClick: true })
                }
                // toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            }

        } else {
            toast.error('لطفا مقدار را وارد کنید.', { position: 'bottom-right', closeOnClick: true })
        }
    }

    const getRealPrice = () => {
        const { tomanBuying } = realData.find(rd => rd.coin === coin.coin)
        if (!login) {
            return (tomanBuying)
        } else {
            const zarib = rank_correction_factor[coin.coin]
            return (tomanBuying * zarib[0])
        }
        // return tomanBuying
    }

    useEffect(() => {
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
        // console.log(priceBuy)
    }, [amount, coin.buyingPrice])
    useEffect(() => {
        const result = amount * toFixedDigit(getRealPrice())
        setPriceBuy(result)
    }, [amount])
    useEffect(() => {
        let result = totalPrice / toFixedDigit(getRealPrice())
        setAmount(result)
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
    }, [totalPrice])

    useEffect(() => {
        handleGetCredit()
        // console.log(coin)
    }, [marketId])
    useEffect(() => {
        // dispatch(setCoin(1))
        handleGetCredit()
        const pusher = require('pusher-js');

        // let echo = new Echo({
        //     broadcaster: 'pusher',
        //     key: 'local',
        //     wsHost: '185.110.189.39',
        //     wsPort: 6001,
        //     forceTLS: false,
        //     cluster: 'mt1',
        //     disableStats: true,
        // });

        // echo.channel(`api-realtime-price`)
        //     .listen('ApiRealTimePriceEvent', (e) => {
        //         const { prices } = e
        //         dispatch(setReadData(prices))
        //     });

    }, [])
    return (
        <div className='step1Buy'>
            <div className='coinPrice'>
                <p className='pPriceSellMode colorGreen'>تومان <span
                    className='spanpPriceSellMode colorGreen'>{numberWithCommas(toFixedDigit(getRealPrice()))}</span></p>
                <p className='countBit'>{`1 ${coin.name}`}</p>
            </div>

            <div className='boxImgCoinValue'>
                <span>مقدار</span>
                <div className='insideBoxImgCoinValue'>
                    <input
                        type="number"
                        placeholder={`مقدار ${coin.name}`}
                        onChange={
                            (e) => {
                                setTypeCal('amount')
                                handleChangeAmount(e.target.value)
                            }
                        }
                        value={typeCal === 'amount' ? amount : totalPrice / getRealPrice()}
                    />
                    <div ref={refCrypto} onClick={() => setShowCryptoMenu(!showCryptoMenu)}>
                        <img src={arrow} alt="" />
                        <p>{coin.name}</p>
                        <img className='imgCoinSelected' src={coin.img} alt="" />
                    </div>
                </div>
                {showCryptoMenu ? <CryptoMenu showMeState={showCryptoMenu} closeMe={setShowCryptoMenu}
                    listItems={marketCoins} /> : null}
            </div>
            {/* start OR */}
            <div className='containerOr'>
                <div></div>
                <p>یا</p>
            </div>
            {/* end OR */}

            <div className='totaPriceToman'>
                <span>مبلغ کل به تومان</span>
                <input
                    pattern="[0-9]*"
                    // type="number"
                    onChange={(e) => {
                        setTypeCal('toman')
                        var str = e.target.value
                        setTotalPrice(str.replaceAll(',', ''))
                    }}
                    value={typeCal === 'amount' ?
                        numberWithCommas(amount * toFixedDigit(getRealPrice())) :
                        numberWithCommas(totalPrice)
                    }

                    placeholder='مبلغ کل به تومان' />
            </div>
            {/* Mojodi */}
            <div className='mojodi'>
                <p>{`${mojodi}`}</p>
                <p>موجودی :</p>
            </div>
            {/* End Mojodi */}
            <img src={dashLine} alt="" />
            {/* amount payable */}
            <div className='amountPayable'>
                <p>{`تومان ${numberWithCommas(amount * toFixedDigit(getRealPrice()))}`}</p>
                <p>مبلغ قابل پرداخت</p>
            </div>
            {/*end amount payable */}
            <ButtonForm funcClick={() => handleOrderBuy()} >
                {isDisable === false ? 'خرید رمز ارز' :
                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border" variant="secondary" />
                }
            </ButtonForm>
        </div>
    );
}

export default Step1Buy;