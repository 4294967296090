export const realDataReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_REAL_DATA':
            return [...action.payload]
        default:
            return state
    }
}

export const readCoinSelect = (state = {}, action) => {
    switch (action.type) {
        case 'SET_REAL_COIN':
            return {...action.payload}
        default:
            return state
    }
}