import React, {useEffect, useState} from 'react';
import ProfileItem from '../../components/ProfileItem/ProfileItem';
import {numberWithCommas} from './../../utils/Price';

import imgCopy from './../../assets/img/CopyInvite.svg'
import './invite.scss'
import {useSelector} from 'react-redux';
import {toast} from "react-toastify";
import {getInviteData} from "../../services/inviteService";
import Loading from "../../components/Loading/Loading";
import axios from "axios";


const Invite = () => {

    const inviteData = useSelector(state => state.inviteData)
    const [loading, setLoading] = useState(false);

    const copyTextToClipboard = (text, type = '') => {
        if ('clipboard' in navigator) {
            toast.success(`${type === 'link' ? 'لینک' : 'کد'} دعوت کپی شد`, {position: 'top-right', closeOnClick: true})
            return navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const getInviteDataFromBackend = async () => {
        setLoading(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getInviteData()
            if (data.type === 'success') {
                setLoading(false)
                return {type: 'SET_INVITE_DATA', payload: {...data.data}}
            }
        } catch (ex) {
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'پنل کاربری || دعوت از دوستان'
        getInviteDataFromBackend()
    }, [])

    return (
        <div className='invitePage'>
            <ProfileItem title='دعوت دوستان'>
                <p className='mainPInvite'>
                    لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح، دقیقا
                    مانند تصویر نمونه، ارسال نماید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید
                    هنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی بصورت برعکس و آینه ای ثبت نشود.
                </p>
                {loading ? <Loading/> :
                    <div className="row">
                        <div className='col-md-6 col-sm-12 leftInvite'>
                            <p className='captionInvite'>مجموع سود من</p>
                            <div className='contianerValueCaption'>
                                <p className='valueForCaptionInvite'> تومان</p>
                                <span>{numberWithCommas(inviteData.length > 0 ? inviteData.commission : 0)}</span>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 rightInvite'>
                            <p className='captionInvite'>تعداد دوستان من</p>
                            <div className='contianerValueCaption'>
                                <p className='valueForCaptionInvite'> نفر</p>
                                <span>{inviteData.length > 0 ? inviteData.count : 0}</span>
                            </div>
                        </div>
                    </div>
                }
            </ProfileItem>
            {!loading?
                <div className='bottomInvite'>
                    <div className='itemInvite'>
                        <div className='insideItemInvite'>
                            <p>{inviteData.link}</p>
                            <div>
                                <p>لینک دعوت :</p>
                                <img alt='' src={imgCopy} onClick={() => copyTextToClipboard(inviteData.link, 'link')}
                                     className='imgCopyInvite'/>
                            </div>
                        </div>
                    </div>
                    <div className='itemInvite item2'>
                        <div className='insideItemInvite '>
                            <p>{inviteData.code}</p>
                            <div>
                                <p>کد دعوت :</p>
                                <img alt='' src={imgCopy} onClick={() => copyTextToClipboard(inviteData.code, 'code')}
                                     className='imgCopyInvite'/>
                            </div>
                        </div>
                    </div>
                </div>
            :null
            }
        </div>
    );
}

export default Invite;