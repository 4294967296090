import {getAccountsActice, getBankAccount} from "../../services/bankAccountService"

export const getAccounts = () => {
    return async (dispatch, getState) => {
        const {data} = await getBankAccount()
        await dispatch({type: 'SET_BANK_ACCOUNTS', payload: [...data.data]})
    }
}

export const getActiveDebit = () => {
    return async (dispatch) => {
        const {data} = await getAccountsActice()
        const cards = [...data.data]
        // console.log("card list",cards)
        var activeCards = []
        let idNum=0
        cards.map(item=>{

                const obj = {
                    id:idNum,
                    value: item.id,
                    title: item.card_number
                }
                activeCards.push(obj)
                idNum++
        })
        // console.log("active cards :",activeCards)
        await dispatch({type:'SET_BANK_ACCOUNTS_ACTIVE',payload:[...activeCards]})
    }
}
