import config from './config.json'

import http from './httpService'

// Start Register
export const registerUser = user => {
    return http.post(`${config.coinbaseapi}/signup`, user)
}

export const registerConfirm = user => {
    return http.post(`${config.coinbaseapi}/confirm-email`, user)
}

export const resendCodeRegister = email => {
    return http.post(`${config.coinbaseapi}/send-code`, email)
}

// End Register

// Start Login

export const loginUser = user => {
    return http.post(`${config.coinbaseapi}/signin`, user)
}

export const loginConfirm = user => {
    return http.post(`${config.coinbaseapi}/check-otp`, user)
}

export const resendCodeLogin = email => {
    return http.post(`${config.coinbaseapi}/resend-otp`, email)
}

// End Login

// Start Forgot Password
export const forgotPassword = email => {
    return http.post(`${config.coinbaseapi}/forget-password`, email)
}

export const forgotPasswordConfirm = obj => {
    return http.post(`${config.coinbaseapi}/forget-password-confirm`, obj)
}

export const setPassword = obj => {
    return http.post(`${config.coinbaseapi}/change-password`, obj)
}

// End Forgot Password

// User Logout
export const logoutUser = () => {
    return http.delete(`${config.coinbaseapi}/users/logout`)
}