import React, {useState} from "react";
import './walletItem.scss'
import {numberWithCommas} from "../../utils/Price";
import DepositDialog from "../../dialogs/DepositDialog/DepositDialog";
import WithdrawDialog from "../../dialogs/WithdrawDialog/WithdrawDialog";
import WalletDepoditModal from "../../dialogs/WalletDepoditModal/WalletDepoditModal";
import WalletWiithdrawModal from "../../dialogs/WalletWiithdrawModal/WalletWiithdrawModal";
import {initNetworks} from "../../redux/actions/networkActions";
import {useDispatch, useSelector} from "react-redux";
import {getAccounts} from "../../redux/actions/bankAccountsAction";
import {setCoinWallet} from "../../redux/actions/marketCoinsActions";
import {setMarketId} from "../../redux/actions/marketIdAction";
import DialogBuySell from "../../dialogs/DialogBuySell/DialogBuySell";
import {toFixedDigit} from "../../utils/helperFunction";
import {toast} from "react-toastify";

const WalletItem = ({credit = 0, name, label, coin_id, logo, code}) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    const acceptedAccounts = useSelector(state => state.acceptedAccounts)

    const {credits} = user
    // console.log(user)
    const [showDialogDepositToman, setDialogDepositToman] = useState(false)
    const openDepositDialogToman = () => {
        if (acceptedAccounts.length > 0) {
            setDialogDepositToman(true)
        } else {
            toast.error('هنوز کارت بانکی تایید شده ندارید', {position: 'bottom-right', closeOnClick: true})
        }
    }
    const closeDepositDialogToman = () => setDialogDepositToman(false)

    const [showDialogWithdrawToman, setDialogWithdrawToman] = useState(false)
    const openWithdrawDialogToman = () => {
        if (acceptedAccounts.length > 0) {
            setDialogWithdrawToman(true)
        } else {
            toast.error('هنوز کارت بانکی تایید شده ندارید', {position: 'bottom-right', closeOnClick: true})
        }
    }


    const closeWithdrawDialogToman = () => setDialogWithdrawToman(false)

    const [showDepositWallet, setDepositWallet] = useState(false)
    const openDepositWallet = () => setDepositWallet(true)
    const closeDepositWallet = () => setDepositWallet(false)

    const [showWithdrawWallet, setWithdrawWallet] = useState(false)
    const openWithdrawWallet = () => {
        const obj = credits.find(item => item.code === code)
        console.log(obj)
        setWithdrawWallet(true)
    }
    const closeWithdrawWallet = () => setWithdrawWallet(false)

    const [dialogSellBuy, setDialogSellBuy] = useState(false)
    const openSellBuy = () => setDialogSellBuy(true)
    const closeSellBuy = () => setDialogSellBuy(false)



    const handleShowSellBuy = () => {

        dispatch(setCoinWallet(coin_id))
        dispatch(setMarketId(coin_id))
        openSellBuy()
    }

    const handleDeposit = () => {
        if (code === 'TOMAN') {
            openDepositDialogToman()
            dispatch(getAccounts())

        } else {
            dispatch(initNetworks(coin_id))
            // dispatch(getNetworks())
            openDepositWallet()
        }
    }
    const handleWithdraw = () => {
        if (code === 'TOMAN') {
            openWithdrawDialogToman()
            dispatch(getAccounts())

        } else {
            dispatch(initNetworks(coin_id))

            openWithdrawWallet()
        }
    }

    return (
        <section className='walletItem'>
            <div className='leftWalletItem'>
                {code !== 'TOMAN' ? <p onClick={() => handleShowSellBuy()}>معامله</p> : null}
                <p onClick={() => handleWithdraw()}>برداشت</p>
                <p onClick={() => handleDeposit()}>واریز</p>
            </div>
            <p className='credit'> دارایی : {code} {numberWithCommas(toFixedDigit(credit))}</p>
            <div className='rightWalletItem'>
                <img src={logo} alt='' className='logoWallet'/>
                <p className='walletName'>{label} - {name}</p>
            </div>
            {/*Modals*/}
            <DepositDialog showDialog={showDialogDepositToman} closeDialog={closeDepositDialogToman}/>
            <WithdrawDialog showDialog={showDialogWithdrawToman} closeDialog={closeWithdrawDialogToman}/>
            <WalletDepoditModal coinId={coin_id}  label={label} showDialog={showDepositWallet} closeDialog={closeDepositWallet}/>
            <WalletWiithdrawModal label={label} credit={credit} code={code} coinId={coin_id}
                                  showDialog={showWithdrawWallet} closeDialog={closeWithdrawWallet}/>
            <DialogBuySell id={coin_id} fromNav={true} showDialog={dialogSellBuy} closeDialog={closeSellBuy}/>

            {/*end Modals*/}
        </section>
    )
}
export default WalletItem