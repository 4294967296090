import React, {useState} from 'react'
import './inputPrice.scss'

const InputPrice = ({
                             placeholder = '',
                             onChange,
                             defaultValue,
                             marginBottom,
                             onBlur,
                             disabled,
                             symbol,
                             classNames = '',
                             caption = false
                         }) => {

    const [getPrice, setPrice] = useState(defaultValue)

    const numberPrice = x => {
        let str2 = x ? x : ''
        return str2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${symbol ? symbol : ','}`);
    }

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    return (
        <div className='containerInputPrice'>
            <input pattern="[0-9]*"
                   disabled={disabled}
                   onKeyPress={(e) => validate(e)}
                   value={numberPrice(getPrice)}
                   className={classNames}
                   onChange={(e) => {
                       const str = e.target.value
                       setPrice(str.replaceAll(',', ''))
                       onChange(str.replaceAll(',', ''))
                   }}
                   onBlur={onBlur} placeholder={placeholder} style={{marginBottom}}/>
            {getPrice !== '' && caption ? <span className='spanHolder'>{placeholder}</span> : null}

        </div>
    )
}

export default InputPrice