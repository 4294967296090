import React, {useState, useEffect} from "react";
import CloseDialog from "./../../components/CloseDialog/CloseDialog";

import ComponentMenu from "./../../components/ComponentMenu/ComponentMenu";
import {DialogContent, DialogOverlay} from "@reach/dialog";
import warningImage from "./../../assets/img/warning.svg";
import './walletWiithdrawModal.scss';
import {useSelector} from "react-redux";
import InputForms from "../../components/InputForms/InputForms";
import ButtonForm from "../../components/ButtomForm/ButtomForm"
import {Formik} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {optWithdraw, otpForWithdrawCrypto, withdrawCrypto} from "../../services/depositWithdrawService";
import CustomInput from "../../components/CustomInput/CustomInput";
import {getAccountStatus} from "../../utils/helperFunction";
import {typeOfLoggin} from "../../utils/Price";
import {setDigit} from "../../redux/actions/digitActions";
import ShowTimer from "../../components/ShowTimer/ShowTimer";

// import WithdrawMobileConfirm from "../WithdrawMobileConfirm/WithdrawMobileConfirm";

const validationSchema = Yup.object().shape({
    amount: Yup.number().required('پر کردن این فیلد الزامی می باشد.'),
    address: Yup.string().required('پر کردن این فیلد الزامی می باشد.')
})

const WalletWiithdrawModal = ({net = -1, showDialog, closeDialog, title, coinId, label, credit, code}) => {

    let codeNumber = ''
    const numOfFields = 6;
    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])

    const [network, setNetwork] = useState(-1);
    const [isLoadingOtp, setIsLoadingOtp] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [loadSendAgain, setLoadSendAgain] = useState(false)
    const [sendAgain, setSendAgain] = useState(false)
    const [step, setStep] = useState(1)
    const listNetwork = useSelector((state) => state.listNetwork);
    const user = useSelector(state => state.user)


    const handleFunc = async () => {
        if (network > -1) {
            setIsLoadingOtp(true)
            try {
                const {data} = await otpForWithdrawCrypto()
                if (data.type === 'success') {
                    toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                    setIsLoadingOtp(false)
                    setShowConfirm(true)
                    setStep(2)
                }
            } catch (ex) {
                setIsLoadingOtp(false)

                toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            }
        } else {

            toast.error('لطفا شبکه مورد نظر را انتخاب کنید.', {position: 'bottom-right', closeOnClick: true})
        }
    }

    const handleStep2 = async (values) => {
        const code = numberrr.join('')
        if (code.length > 5) {
            setIsLoadingOtp(true)
            const obj = {
                payment_method: 'CRYPTO',
                amount: values.amount,
                address: values.address,
                crypto_network_id: listNetwork[network].value,
                coin_id: coinId,
                code
            }
            try {

                const {data} = await withdrawCrypto(obj)
                if (data.type === 'success') {
                    toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                    closeDialog()
                }
            } catch (ex) {
                setIsLoadingOtp(false)
                toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            }
        } else {
            toast.error('لطفا کد را کامل وارد کنید', {position: 'bottom-right', closeOnClick: true})
            setIsLoadingOtp(false)
        }
    }

    const handleTransaction = (values) => {
        if (step === 2) {
            handleStep2(values)
        } else if(step ===1) {
            handleFunc()
        }

    }

    const handleSendAgain = async () => {
        setLoadSendAgain(true)
        try {
            const {data} = await optWithdraw()
            if (data.type === 'success') {
                setLoadSendAgain(false)
                setSendAgain(false)
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
            }
        } catch (ex) {
            setLoadSendAgain(false)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
        }
    }

    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        codeNumber = temp
        // dispatch(setDigit(numberrr.join().replaceAll(',', '')))
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < numOfFields) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
                return
            }
        }

    }

    useEffect(() => {
        setNetwork(-1)
        setShowConfirm(false)
        setSendAgain(false)
        setStep(1)
    }, [showDialog]);

    return (
        <DialogOverlay isOpen={showDialog} onDismiss={closeDialog} style={{background: "var(--bg-modal)"}}>
            <DialogContent className="walletDepoditModal">
                <div className="insideModal">
                    <CloseDialog funcIn={closeDialog}/>
                    <p className="titleInWalletDeposit">{`برداشت از کیف  ${label}`}</p>
                    <ComponentMenu
                        marginBottom="16px"
                        setID={setNetwork}
                        list={listNetwork}
                        placeholder={
                            network < 0 ? "انتخاب شبکه" : listNetwork[network].title
                        }
                    />
                    <Formik initialValues={{amount: '', address: ''}}
                            validationSchema={validationSchema}
                            onSubmit={((values) => handleTransaction(values))}>
                        {({handleSubmit, handleChange, values, errors, setFieldTouched, touched}) => (
                            <>
                                {/*<InputForms value={values.amount} ltr={true} changeValue={handleChange('amount')}*/}
                                {/*            typeInput='number' placeholder='مقدار' marginBottom='16px'/>*/}
                                <CustomInput number={true} textAlignLeft={true} value={values.amount}
                                             handleChange={handleChange('amount')} marginBottom='18px'
                                             inputType='number'
                                             handleBlur={() => setFieldTouched('amount')} placeholder='مقدار'/>
                                <ErrorMessage error={errors.amount} visible={touched.amount}/>

                                <div className="divInWithdraw">
                                    <p>{code} {credit}</p>
                                    <p>موجودی قابل برداشت شما</p>
                                </div>
                                <InputForms value={values.address} ltr={true} placeholder='آدرس کیف پول'
                                            handleBlur={() => setFieldTouched('address')}
                                            changeValue={handleChange('address')} marginBottom='16px'/>
                                <ErrorMessage error={errors.address} visible={touched.address}/>
                                <div className="divInWithdraw">
                                    {network > -1 ?
                                        <p>{code} {listNetwork.length > 0 ? listNetwork[network].fee : 0}</p> : null}
                                    <p>کارمزد انتقال در شبکه</p>
                                </div>
                                <div className="divWarning">
                                    <p>{`در وارد نمودن آدرس کیف پول خود دقت فرمایید و از آدرس کیف پول معتبر ${label} استفاده کنید. مسئولیت و عواقب آدرس کیف پول نامعتبر به عهده خریدار میباشد.`}</p>
                                    <img src={warningImage} alt=""/>
                                </div>

                                {showConfirm ?
                                    <>
                                        <p className='p6Digit'>کد شش رقمی ارسال شده
                                            به {user.settings ? typeOfLoggin(user.settings[0].setting_value) : ''} خود
                                            را وارد کنید.</p>
                                        <div className='inputDigit6'>
                                            <div className='containerInputCodeOPT'>
                                                {numberrr.map((_, index) =>
                                                    <input key={index} maxLength="1"
                                                           onChange={(e) => handleTextChange(e, index)}
                                                           name={`ssn-${index + 1}`} className='inputCodeOPt'
                                                           type="number"/>
                                                )
                                                }
                                            </div>
                                        </div>
                                        {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                                    </>
                                    : null}
                                {!showConfirm ? <ButtonForm isDis={isLoadingOtp} funcClick={ handleSubmit}>
                                    {isLoadingOtp === false ? 'ثبت' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                 variant="secondary"/>
                                    }
                                </ButtonForm> : null}
                                {showConfirm && !sendAgain ? <ButtonForm isDis={isLoadingOtp} funcClick={handleSubmit}>
                                    {isLoadingOtp === false ? 'ثبت درخواست برداشت' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                 variant="secondary"/>
                                    }
                                </ButtonForm> : null}
                                {sendAgain ?
                                    <ButtonForm isDis={loadSendAgain} funcClick={handleSendAgain} marginBottom='24px'>
                                        {loadSendAgain === false ? 'ارسال مجدد کد' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                     variant="secondary"/>
                                        }
                                    </ButtonForm>
                                    : null}
                            </>
                        )}
                    </Formik>

                </div>
            </DialogContent>
        </DialogOverlay>
    );
};

export default WalletWiithdrawModal;
