import React, {useState} from 'react';
import {withRouter} from 'react-router-dom'
import {useSelector} from "react-redux";
import './setterEmail.css'


const SetterEmail = ({history}) => {

    const [email, setEmail] = useState('')
    const login = useSelector(state => state.login)

    if (login) return null
    return (
        <div className='setterEmail'>
            <div className='btnSetterEmail'>
                <p className='pInSetterEmail' onClick={() => history.push(`/register/${email}`)}>ثبت نام</p>
            </div>
            <input
                type="email"
                className='inputSetterEmail'
                placeholder='ایمیل خود را وارد نماید ...'
                onChange={(e) => setEmail(e.target.value)}
            />
        </div>
    );
}

export default withRouter(SetterEmail);