import React from 'react'
import './loading.scss'
import { Default, Ring } from 'react-awesome-spinners'

const Loading = ({ message = 'در حال بارگذاری اطلاعات ...', fullScrean = false }) => {
    return (
        <div className={fullScrean ? 'containerLoading fullScrean' : 'containerLoading'}>
            <Default />
            <p>{message}</p>
        </div>
    )
}
export default Loading