import {weeklyPrice} from "../../services/marketService";

export const setWeeklyPrice=()=>{
    return async (dispatch)=>{
        try {
            const {data} = await weeklyPrice()
            await dispatch({type:'INIT_WEEKLY_PRICE',payload:[...data.data]})
        }catch (ex){
            console.log(ex)
        }
    }
}