import axios from "axios";
import { toast } from "react-toastify";
//    "coinbaseapi": "https://dashboard.arztrading.com/api",
// "coinbaseapi": "http://coinbase.6.vistadev.ir/api",


axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!expectedError) {
        toast.error(error.response, {
            position: 'top-right',
            closeOnClick: true
        })
    }
    // if (error.response.status == 422) {
        // console.log(error.response.data.errors.email[0]);
        // toast.error(`${error.response.data.errors.email[0]}`)
        // error.response.data.errors[key][0]
    // }
    return Promise.reject(error.response)
})

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch
}