import React, {useEffect, useState} from 'react';
import {numberWithCommas} from './../../utils/Price';
import {useSelector} from 'react-redux';

import CryptoMenu from '../CryptoMenu/CryptoMenu';

// images
import arrow from './../../assets/img/ArrowRecive.svg'
import dangerIcon from './../../assets/img/dangerIcon.svg'
import dashLine from './../../assets/img/lineStyleDash.svg'
import ButtonForm from "../ButtomForm/ButtomForm";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {orderBuy} from "../../services/ordersService";
import {toFixedDigit} from "../../utils/helperFunction";

const FirstSell = ({closeFunction}) => {

    const [showCryptoMenu, setShowCryptoMenu] = useState(false)
    // const [valueCoin, setValueCoin] = useState('')

    const coins = useSelector(state => state.coins)
    const coinId = useSelector(state => state.coinId)
    const realData = useSelector(state => state.readData)



    const [amount, setAmount] = useState('')
    const [totalPrice, setTotalPrice] = useState('')
    const [priceBuy, setPriceBuy] = useState(0)
    const [isDisable, setIsDisable] = useState(false)
    const marketCoins = useSelector(state => state.marketCoins)
    const coin = useSelector(state => state.coin)
    const marketId = useSelector(state => state.marketId)
    const login = useSelector(state => state.login)
    const user = useSelector(state => state.user)
    const { rank_correction_factor } = user


    const floorPrice = (num) => {
        let price = Number.parseFloat(num);
        if (price >= 1) {
            return price.toFixed(0);
        }
        return parseFloat(price.toPrecision(4));
    }

    const handleOrderSell =async () => {
        if (amount != 0) {
            setIsDisable(true)
            const obj = {
                market_id: marketId,
                type: 'SELL',
                amount
            }
            console.log(obj)
            try {
                const {data} = await orderBuy(obj)
                if (data.type === 'success') {
                    toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                    setIsDisable(false)
                    closeFunction()
                }
            } catch (ex) {
                setIsDisable(false)
                console.log(ex)
                if(ex.data.errors){
                    toast.error(`${ex.data.errors.amount[0]}`, {position: 'bottom-right', closeOnClick: true})
                }else{
                    toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                }
            }
        } else {
            toast.error('لطفا مقدار را وارد کنید.', {position: 'bottom-right', closeOnClick: true})
        }
    }

    const getRealPrice = () => {
        const {tomanSelling} = realData.find(rd => rd.coin === coin.coin)
        if (!login) {
            return (tomanSelling)
        } else {
            const zarib = rank_correction_factor[coin.coin]
            return (tomanSelling * zarib[1])
        }
        // setRealCoin(tomanBuying)
        // return tomanSelling
    }

    useEffect(() => {
        let priceBuy = floorPrice(coin.buyingPrice)
        const result = amount * toFixedDigit(getRealPrice())
        setPriceBuy(result)
        setTotalPrice(result)
    }, [amount])

    return (
        <>
            <div className='coinPrice'>
                <p className='pPriceSellMode'>تومان <span
                    className='spanpPriceSellMode'>{numberWithCommas(toFixedDigit(getRealPrice()))}</span></p>
                <p className='countBit'>1 {coin.name}</p>
            </div>

            <div className='boxImgCoinValue'>
                <div className='insideBoxImgCoinValue'>
                    <input className='inputTopBox'
                           type="number"
                           placeholder={`مقدار ${coin.name}`}
                           onChange={
                               (e) => {
                                   const str = e.target.value
                                   // setValueCoin(str.replaceAll(',', ''))
                                   setAmount(e.target.value)
                               }
                           }
                           value={amount}
                    />
                    <div onClick={() => setShowCryptoMenu(!showCryptoMenu)} className='rightTopBox'>
                        <img src={arrow} alt=""/>
                        <p>{coin.name}</p>
                        <img className='imgCoinSelected' src={coin.img} alt=""/>
                    </div>
                </div>
                {showCryptoMenu ? <CryptoMenu showMeState={showCryptoMenu} closeMe={setShowCryptoMenu}
                                              listItems={marketCoins}/> : null}
            </div>
            <div className='containerDangerMesssage'>
                <p>
                    چنانچه ارز ارسالی شما با بیش از 30 دقیقه اختلاف زمانی به ما برسد، مبلغ پرداختی در زمان دریافت ارز
                    محاسبه خواهد شد.
                </p>
                <img src={dangerIcon} alt=""/>
            </div>
            <div className='containerDangerMesssage'>
                <p>
                    در صورتی که مقدار دریافتی ارزجو طی ۰۳ دقیقه آینده دقیقا ۳۶۰.۰ بیت کوین نباشد، فروش شما به صورت دستی
                    باید بررسی و تایید شود.
                </p>
                <img src={dangerIcon} alt=""/>
            </div>
            <img src={dashLine} className='lineInSellMode'/>
            <div className='totalPrice'>
                <p>{`تومان ${numberWithCommas(amount * toFixedDigit(getRealPrice()))}`}</p>
                <p>مبلغ کل:</p>
            </div>
            <ButtonForm funcClick={() => handleOrderSell()} >
                {isDisable === false ? 'فروش رمز ارز' :
                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border" variant="secondary"/>
                }
            </ButtonForm>
        </>
    );
}

export default FirstSell;